import {IEventLoadMore, IEventsStudentLeft, IEventStudent, IUpdateEventStudent} from "../models/events";
import api from "./api";

export const EventService = {
    async getListEvents (id: number, page: number) {
        const {data} = await api.get<IEventsStudentLeft>(`/api/Event/events/${id}/${page}`)
        return data
    },

    async loadMoreListEvents (dataSubmit: IEventLoadMore) {
        const {data} = await api.post<IEventsStudentLeft>(`/api/Event/eventsLoadMore`, dataSubmit)
        return data
    },

    async updateEvent(dataSubmit: IUpdateEventStudent) {
        const {data} = await api.post<IEventStudent>(`/api/Event/events/update/`, dataSubmit)
        return data
    },

    async getUnreadEvent(id: number) {
        const {data} = await api.get<number>(`/api/Event/events/unread/${id}`)
        return data
    }
}