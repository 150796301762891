import ScrollContainer from "../scroll-container/ScrollContainer";
import TestsQuestionsItem
    from "./tests-questions-item/TestsQuestionsItem";
import React, {useEffect, useState} from "react";
import {IQuestion, IQuestionDetail, IQuestionOption, ITestDetail} from "../../../models/tests";
import {useFormContext} from "react-hook-form";

const ListOptionTestEdit = () => {

    const {
        setValue,
        register,
        formState: {errors},
        watch
    } = useFormContext<ITestDetail>()

    const testQuestionsWatch = watch('testQuestions')

    const [questions, setQuestions] = useState<IQuestionDetail[]>([])
    const [preview, setPreview] = useState<string>('')

    const onPreview = () => {
        const arQuestions = preview.split("~~")
        arQuestions.shift()
        let questions: IQuestion[] = []
        arQuestions.forEach((element) => {
            let arOptions = element.split("\n")
            arOptions = arOptions.filter(el => el != "")
            let questionOptions: IQuestionOption[] = []
            arOptions.forEach((option) => {
                option = option.trim()
                if (option.startsWith("++") || option.startsWith("--")) {
                    questionOptions.push({
                        name: option.slice(2),
                        isCorrectAnswer: option.slice(0, 2) === "++"
                    })
                }
            })
            questions.push({name: arOptions[0], options: questionOptions})
        })
        setQuestions(questions);
        setValue("testQuestions", questions, {shouldValidate: true})
    }

    useEffect(() => {
        // console.log(testQuestionsWatch)
        if (testQuestionsWatch && testQuestionsWatch.length === 0) {
            setPreview("")
            setQuestions([])
        }
        if (testQuestionsWatch && testQuestionsWatch.length > 0) {
            let previewQuestionsTest = ""
            testQuestionsWatch.forEach(element => {
                previewQuestionsTest += "~~" + element.name + "\n"
                element.options.forEach(option => {
                    previewQuestionsTest += (option.isCorrectAnswer ? "++" : "--") + option.name + "\n"
                })
                previewQuestionsTest += "\n\n"
            })
            setPreview(previewQuestionsTest)
            // setQuestions([])
        }
    }, [testQuestionsWatch]);

    // useEffect(() => {
    //     console.log(testQuestionsWatch)
    // }, [preview]);

    // useEffect(() => {
    //     console.log(123)
    //     if(getValues("testQuestions")) {
    //         const testQuestions: IQuestion[] = getValues("testQuestions")
    //         let previewQuestionsTest: string = ""
    //         testQuestions.forEach(element => {
    //             previewQuestionsTest += "~~" + element.name + "\n"
    //             element.options.forEach(option => {
    //                 previewQuestionsTest += (option.isCorrectAnswer ? "++" : "--") + option.name + "\n"
    //             })
    //             previewQuestionsTest += "\n\n"
    //         })
    //         setPreview(previewQuestionsTest)
    //     }
    // }, []);

    return (
        <>
            <div
                className="bg-white rounded-[20px] p-[36px] flex flex-col gap-[20px] max-tablet:p-[24px] w-full">
                <input type="hidden" {...register("testQuestions", {required: true})}/>
                {errors.testQuestions && errors.testQuestions.type === "required" && (
                    <p className="text-red-600">Вопросы обязательны</p>
                )}
                <div className="text-[#292B2C] text-[20px] font-[500]">Вопросы</div>
                <div className="flex gap-[20px] max-tablet:flex-col">
                    <div className="w-[calc(100%-10px)] flex flex-col gap-[12px] max-tablet:w-full">
                        <div className="text-[#292B2C] text-[18x] font-[500]">Исходный текст</div>
                        <textarea
                            value={preview}
                            onChange={(e) => setPreview(e.target.value)}
                            placeholder="Введите вопросы"
                            className="border border-[#BDBDBD] rounded-[12px] focus:outline-none p-[16px] w-full h-[200px]"></textarea>
                        <div
                            className="cursor-pointer text-center py-[16px] px-[20px] rounded-[12px] text-[#17AB57] text-[16px] font-[500] leading-[120%] border border-[#17AB57]"
                            onClick={onPreview}>Предосмотр
                        </div>
                    </div>
                    <div className="w-[calc(100%-10px)] flex flex-col gap-[12px] max-tablet:w-full">
                        <div className="text-[#292B2C] text-[18x] font-[500]">Вопросы</div>
                        <div
                            className="border border-[#BDBDBD] rounded-[12px] p-[16px] h-full max-tablet:h-[200px]">
                            <ScrollContainer>
                                {questions && questions.map((question, index) =>
                                    <TestsQuestionsItem question={question} key={index}/>
                                )}
                            </ScrollContainer>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListOptionTestEdit