import React, {useState} from "react";
import Loader from "../../../../ui/loader/Loader";
import {Link, useNavigate, useParams} from "react-router-dom";
import {TestsService} from "../../../../../services/tests.service";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {ITestDetail} from "../../../../../models/tests";
import ErrorMessage from "../../../../ui/error/ErrorMessage";
import ScrollContainer from "../../../../ui/scroll-container/ScrollContainer";
import {ReactComponent as ArrowBackIcon} from "../../../../../assets/images/global/arrow-back-icon.svg";
import ResetSubmit from "../../../../ui/reset-submit/ResetSubmit";
import ListOptionTestEdit from "../../../../ui/list-option-test-edit/ListOptionTestEdit";

const TestsDetail = () => {

    const {id} = useParams()
    const queryClient = useQueryClient()

    const [show, setShow] = useState<boolean>(false)
    const [error, setError] = useState<number | null>(null)

    const methods = useForm<ITestDetail>()
    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue
    } = methods

    const {data: test, isLoading, isError} = useQuery(
        ["test", Number(id)],
        () => TestsService.getById(Number(id)),
        {
            enabled: !!id,
            onSuccess: (response) => {
                setValue("name", response.name)
                setValue("testQuestions", response.testQuestions)
            }
        }
    )

    const updateTest = useMutation(
        (data: ITestDetail) => TestsService.update(data, Number(id)),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["test", Number(id)])
                queryClient.invalidateQueries("tests")
                setShow(false)
            }
        }
    )

    const onSubmit: SubmitHandler<ITestDetail> = async (data) => {
        setShow(true)
        updateTest.mutate(data)
        console.log(data)
    }

    if (isLoading) return <Loader/>
    if (isError) return <ErrorMessage numberError={400}/>
    if (!test) return <ErrorMessage numberError={400}/>

    const handleReset = () => {
        setValue("name", test.name)
        setValue("testQuestions", test.testQuestions)
    }

    return (
        <>
            {show && <Loader/>}
            {error && <ErrorMessage numberError={400}/>}
            <ScrollContainer>
                <FormProvider {...methods}>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="flex flex-col gap-[32px] h-full max-laptop:gap-[24px] max-tablet:gap-[28px]">
                        <div className="flex flex-col gap-[16px] text-[#292B2C] max-tablet:gap-[24px]">
                            <div className="inline-flex">
                                <Link to={`/tests`}
                                      className="flex gap-[10px] items-center px-[16px] py-[12px] text-[18px] max-tablet:p-0 cursor-pointer">
                                    <ArrowBackIcon className="flex-shrink-0"/>
                                    <span className="max-tablet:hidden">Назад</span>
                                    <span
                                        className="tablet:hidden text-[24px] font-[700] leading-[120%]">Редактирование</span>
                                </Link>
                            </div>
                            <div className="flex justify-between items-start max-tablet:w-full">
                                <div className="font-[700] text-[36px] leading-[120%] max-laptop:text-[28px] max-tablet:hidden">
                                    Редактирование тестирования
                                </div>
                                <div
                                    className="flex gap-[12px] text-[16px] max-tablet:flex-col max-tablet:w-full max-tablet:gap-[24px]">
                                    <ResetSubmit actionReset={handleReset}/>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-[20px]">
                            <div
                                className="bg-white rounded-[20px] p-[36px] flex flex-col gap-[20px] max-tablet:p-[24px] w-full">
                                <div className="text-[#292B2C] text-[20px] font-[500]">Основная информация</div>
                                <div className="flex flex-col gap-[4px]">
                                    <div className="text-[16px] text-[#828282]">Название теста</div>
                                    <input
                                        type="text"
                                        className="p-[16px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[12px] h-[54px]"
                                        placeholder="Введите название"
                                        {...register("name", {required: true})}/>
                                    {errors.name && errors.name.type === "required" && (
                                        <p className="text-red-600">Название обязательно</p>
                                    )}
                                </div>
                            </div>
                            <ListOptionTestEdit/>
                        </div>
                    </form>
                </FormProvider>
            </ScrollContainer>
        </>
    )
}

export default TestsDetail