import {
    IChat, IChatMessages,
    ICompanion, ICountUnreadMessage, ICreateChat,
    IDeleteMessage,
    IListChatByMethodist,
    IUpdateViewMessage
} from "../models/chat";
import api from "./api";


export const ChatService = {

    async getListByMethodist(id: number) {
        const {data} = await api.get<IListChatByMethodist[]>(`/api/Chat/getListChatByMethodist/${id}`)
        return data
    },

    async getById(id: number | null, currentUser: number) {
        const {data} = await api.get<IChatMessages>(`/api/Chat/getChatById/${id}/${currentUser}`)
        return data
    },

    async getCompanionInChat(id: number | null, currentUser: number) {
        const {data} = await api.get<ICompanion>(`/api/Chat/getCompanionInChat/${id}/${currentUser}`)
        return data
    },

    async sendMessage(dataSubmit: FormData) {
        const {data} = await api.post<IChat>(`/api/Chat/sendMessage`, dataSubmit, {
            headers: {'Content-Type': 'multipart/form-data'}
        })
        return data
    },

    async sendFiles(dataSubmit: FormData) {
        const {data} = await api.post<IChat>(`/api/Chat/sendFiles`, dataSubmit, {
            headers: {'Content-Type': 'multipart/form-data'}
        })
        return data
    },

    async updateView(dataSubmit: IUpdateViewMessage) {
        const {data} = await api.post<boolean>(`/api/Chat/updateViewMessage/`, dataSubmit)
        return data
    },

    async deleteMessage(dataSubmit: IDeleteMessage) {
        const {data} = await api.post(`/api/Chat/deleteMessage/`, dataSubmit)
        return data
    },

    async updateMessageText(dataSubmit: FormData) {
        const {data} = await api.post(`/api/Chat/updateMessageText/`, dataSubmit, {
            headers: {'Content-Type': 'multipart/form-data'}
        })
        return data
    },

    async getCountUnreadMessage(id: number) {
        const {data} = await api.get<ICountUnreadMessage>(`/api/Chat/getCountUnreadMessage/${id}`)
        return data
    },

    async clearChat(id: number | undefined) {
        const {data} = await api.post<boolean>(`/api/Chat/clearChat/${id}/`, {})
        return data
    },

    async createChat(dataSubmit: ICreateChat) {
        const {data} = await api.post<number>(`/api/Chat/createChat/`, dataSubmit)
        return data
    },

    async checkExistChat(id: number) {
        const {data} = await api.get<number>(`/api/Chat/checkExistChat/${id}`)
        return data
    }
}