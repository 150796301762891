import React, {createContext, ReactNode, useState} from "react";

interface Props {
    children?: ReactNode
}

interface MobileMenuContextType {
    isMenuOpen: boolean
    toggleMenu: () => void
}

const initialValue = {
    isMenuOpen: false,
    toggleMenu: () => {
    }
}

const MobileMenuContext = createContext<MobileMenuContextType>(initialValue)

const MobileMenuProvider = ({children}: Props) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen)

    return (
        <MobileMenuContext.Provider value={{isMenuOpen, toggleMenu}}>
            {children}
        </MobileMenuContext.Provider>
    )
}

export {MobileMenuContext, MobileMenuProvider}