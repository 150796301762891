import {IListChatByMethodist} from "../../../../models/chat";
import {Constants} from "../../../../constans/constants";
import {ReactComponent as ReadMessage} from "../../../../assets/images/chat/readMessage.svg";
import {useEffect} from "react";

interface IChatsItem {
    chat: IListChatByMethodist
    setActiveChat: (id: number) => void
    activeChatId: number|null
}

const ChatsItem = (
    {
        chat,
        setActiveChat,
        activeChatId
    }: IChatsItem
) => {

    useEffect(() => {
        if(chat.lastMessage) {
            chat.lastMessage = chat.lastMessage.replaceAll(/\r?\n|\r/g, '')
                .replaceAll('<br>', '')
                .replaceAll('<p>', '')
                .replaceAll('</p>', '')
                .replaceAll('</p><p>', '')
                .trim();
        }
    }, [chat]);

    return (
        <div
            onClick={() => setActiveChat(chat.id)}
            className={`flex flex-row py-[12px] px-[16px] gap-[16px] items-center w-full cursor-pointer max-tablet:bg-white ` + (activeChatId === chat.id ? "bg-[#E8F7EF] rounded-[16px]" : "hover:bg-[#F2F2F2] rounded-[16px]")}>
            {chat.avatar ? (
                <img className="w-[64px] h-[64px] flex-shrink-0 rounded-[49px]" src={chat.avatar} alt=""/>
            ) : (
                <div
                    className={`w-[64px] h-[64px] flex-shrink-0 flex justify-center items-center rounded-[49px] ${Constants.LIST_COLORS[Number(chat.color)]}`}>
                    <span
                        className={`text-[24px] font-[500] text-white`}>
                        {chat.lastName.trim()[0]}{chat.firstName.trim()[0]}
                    </span>
                </div>
            )}
            <div className={`flex flex-col leading-[120%] text-[16px] gap-[4px] w-[calc(100%-80px)]`}>
                <div className="flex justify-between items-center">
                    <div
                        className={`text-[20px] font-[700] flex items-center gap-[6px] ${activeChatId === chat.id ? "text-[#17AB57]" : "text-[#292B2C]"}`}>
                        <span>{chat.lastName} {chat.firstName}</span>
                        {chat.isOnline && (
                            <div className="w-[8px] h-[8px] rounded-[45px] bg-[#17AB57]"></div>
                        )}
                    </div>
                    <div className="flex gap-[4px] items-center">
                        {chat.isViewed && (<ReadMessage className={`flex-shrink-0 ${activeChatId === chat.id ? "fill-[#17AB57]" : "fill-[#BDBDBD]"}`}/>)}
                        <div className={`${activeChatId === chat.id ? "text-[#828282]" : "text-[#BDBDBD]"}`}>{chat.timeMessage}</div>
                    </div>
                </div>
                <div className="flex items-center gap-[10px] w-full">
                    <div className={`w-full truncate ${activeChatId === chat.id ? "text-[#828282]" : "text-[#BDBDBD]"}`} dangerouslySetInnerHTML={{__html: chat.lastMessage ? chat.lastMessage : "" }}/>
                    {chat.countMessageUnread !== undefined && chat.countMessageUnread > 0 && activeChatId !== chat.id && (
                        <div
                            className="px-[4px] py-[2px] bg-[#17AB57] rounded-[100px] h-[32px] w-[32px] flex items-center justify-center font-[700] text-[12px] text-white flex-shrink-0">
                            {chat.countMessageUnread}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ChatsItem