import {Constants} from "../../../../../constans/constants";
import React, {useContext} from "react";
import {ReactComponent as Files} from "../../../../../assets/images/chat/files.svg";
import {ReactComponent as BackArrowIcon} from "../../../../../assets/images/global/arrow-back-icon.svg";
import {ReactComponent as InfoIcon} from "../../../../../assets/images/chat/info-icon.svg";
import {ICompanion} from "../../../../../models/chat";
import {AuthContext} from "../../../../../context/AuthContext";

interface ICompanionProps {
    companion: ICompanion;
    handleCloseChat: () => void
    setShowFiles: (flag: boolean) => void
    showFiles: boolean
}

const Companion = (
    {
        companion,
        handleCloseChat,
        setShowFiles,
        showFiles
    }: ICompanionProps
) => {

    const {currentRoleId} = useContext(AuthContext)

    return (
        <div className="flex flex-col w-full">
            <div
                className="bg-white px-[16px] py-[12px] border-solid border-b-[0.5px] border-[#BDBDBD] border-opacity-[0.3] flex w-full justify-between items-center">
                <div className="flex gap-[16px] items-center">
                    <BackArrowIcon onClick={handleCloseChat}
                                   className="w-[24px] h-[24px] fill-[#BDBDBD] hidden max-tablet:block"/>
                    <div className="flex items-center gap-[16px]">
                        {companion.avatar ? (
                            <img className="w-[48px] h-[48px] flex-shrink-0 rounded-[49px]"
                                 src={companion.avatar}
                                 alt=""/>
                        ) : (
                            <div
                                className={`w-[48px] h-[48px] flex-shrink-0 flex justify-center items-center rounded-[49px] ${Constants.LIST_COLORS[Number(companion.color)]}`}>
                                        <span className={`text-[20px] font-[500] text-white`}>
                                            {companion.lastName.trim()[0]}{companion.firstName.trim()[0]}
                                        </span>
                            </div>
                        )}
                        <div className="flex flex-col gap-[4px] max-tablet:flex-row">
                            <span
                                className="text-[#292B2C] font-[700]">{companion.firstName} {companion.fatherName} {companion.lastName}</span>
                            {companion.isOnline && (
                                <div className="text-[#BDBDBD] text-[16px] flex items-center gap-[4px]">
                                    <div className="w-[8px] h-[8px] rounded-[45px] bg-[#17AB57]"></div>
                                    <span className="max-tablet:hidden">Онлайн</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Files onClick={() => setShowFiles(!showFiles)}
                       className={`cursor-pointer hover:fill-[#2F80ED] ${!showFiles ? "block" : "hidden"}`}/>
            </div>
            {Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) === -1 && (
                <div className="flex w-full py-[12px] px-[24px] bg-[#17ab571a] justify-center gap-[8px] items-center">
                    <InfoIcon className="flex-shrink-0"/>
                    <div
                        className="text-[14px] text-[#292B2C] leading-[120%] font-[400] flex max-tablet:flex-col items-center">
                        <span>График работы обратной связи:&nbsp;</span>
                        <span>ПН-ПТ с 8:00 до 16:00 по МСК</span>
                    </div>
                </div>
            )}
        </div>

    )
}

export default Companion