import React from "react";
import {ReactComponent as LessonIcon} from "../../../../../../assets/images/courses/lesson-icon.svg";
import {ReactComponent as TestIcon} from "../../../../../../assets/images/courses/test-icon.svg";
import {ILessonsHoursForAttach} from "../../../../../../models/lessons";
import {Link} from "react-router-dom";

interface IUsersAttachLessonsItemInactive {
    lesson: ILessonsHoursForAttach
    toggleLessonActive: (lesson: number, e: React.ChangeEvent<HTMLInputElement>) => void
}

const UsersAttachLessonsItemInactive = (
    {
        lesson,
        toggleLessonActive,
    }: IUsersAttachLessonsItemInactive
) => {

    return (
        <div
            className="flex border-solid border-t-[0.5px] border-[#BDBDBD] border-opacity-[0.3] last:border-none items-center gap-[40px] py-[12px] mr-[10px] pl-[32px]
            max-tablet:m-0 max-tablet:py-[20px] max-tablet:first:pt-0 max-tablet:last:pb-0 max-tablet:first:border-none max-tablet:pl-0 max-tablet:gap-[8px]">
            <div className="w-full pr-[40px] flex items-center gap-[12px] max-laptop:p-0">
                {lesson.testId ?
                    <TestIcon className="flex-shrink-0"/> :
                    <LessonIcon className="flex-shrink-0"/>
                }
                <div className="flex flex-col gap-[4px] text-[16px] font-medium leading-[120%] text-[#BDBDBD]">
                    <div className="inline-flex">
                        <Link target="_blank" className="hover:underline leading-[120%] font-[500] max-tablet:break-all"
                              to={lesson.testId ?
                                  `/folders/preview-test/${lesson.lessonId}/${lesson.testId}?attach-lesson=true` : `/folders/preview-lesson/${lesson.lessonId}?attach-lesson=true`}>
                            {lesson.name}
                        </Link>
                        {/*<CopyIcon onClick={handeClick} className={`flex-shrink-0 cursor-pointer ` + (activeCopy ? 'fill-[#219653]' : 'fill-[#BDBDBD]' )}/>*/}
                    </div>
                    <div className="text-[14px] leading-[120%] font-[400]">{lesson.testId ? "Тест" : "Лекция"}</div>
                </div>
            </div>
            <label
                className="inline-flex items-center cursor-pointer gap-[12px]">
                <input
                    id={`checkbox-${lesson.lessonId}`}
                    name={`checkbox-${lesson.lessonId}`}
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    checked={false}
                    onChange={(event) => toggleLessonActive(lesson.lessonId, event)}/>
                <div
                    className="relative
                    w-[52px]
                    h-[28px]
                    bg-[#F2F2F2]
                    rounded-[50px]
                    peer
                    p-[2px]
                    peer-focus:outline-none
                    peer-checked:after:translate-x-full
                    rtl:peer-checked:after:-translate-x-full
                    after:content-['']
                    after:absolute
                    after:top-[2px]
                    after:start-[2px]
                    after:bg-[#BDBDBD]
                    peer-checked:after:bg-[#17AB57]
                    after:rounded-full
                    after:h-[24px]
                    after:w-[24px]
                    after:transition-all"/>
            </label>
        </div>
    )
}

export default UsersAttachLessonsItemInactive