import {IFolderSearchResult} from "../../../../../models/folders";
import {Link} from "react-router-dom";
import {ReactComponent as FolderIcon} from "../../../../../assets/images/courses/folder-icon.svg";
import React from "react";

interface ISearchFoldersItem {
    folder: IFolderSearchResult
    setShowSearchResult: (show: boolean) => void,
    onClearInput: () => void
    setSearchTerm: (name: string) => void
}

const SearchFoldersItem = (
    {
        folder,
        setShowSearchResult,
        onClearInput,
        setSearchTerm
    }: ISearchFoldersItem
) => {

    const handleClick = () => {
        setShowSearchResult(false)
        onClearInput()
        setSearchTerm("")
    }

    return (
        <Link to={`/folders/${folder.id}`}
              onClick={handleClick}
              className="px-[18px] py-[20px] hover:bg-[#17AB571a] flex text-[16px] text-[#292B2C] leading-[120%] font-medium">
            <div className="w-[50%] flex gap-[12px] items-center max-lg:w-full">
                <FolderIcon className="flex-shrink-0"/>
                {folder.name}
            </div>
            <div className="w-[50%] max-lg:hidden">{folder.path}</div>
        </Link>
    )
}

export default SearchFoldersItem