import {IUser, IUserDetailId} from "../../../../../models/users";
import React, {useState} from "react";
import {ReactComponent as UsersIcon} from "../../../../../assets/images/users/user.svg";
import {ReactComponent as EmailIcon} from "../../../../../assets/images/users/email.svg";
import {ReactComponent as EditIcon} from "../../../../../assets/images/global/edit-icon.svg";
import {ReactComponent as DeleteIcon} from "../../../../../assets/images/global/delete.svg";
import {ReactComponent as GroupIcon} from "../../../../../assets/images/menu/groups-icon.svg";
import {Link} from "react-router-dom";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu";
import {IDropdownMenu} from "../../../../../models/dropdownmenu";
import {useMutation, useQueryClient} from "react-query";
import {UsersService} from "../../../../../services/users.service";
import {AxiosError} from "axios";
import Loader from "../../../../ui/loader/Loader";

interface IUsersItem {
    user: IUser
    setError: (error: number | null) => void
}

const UsersItem = (
    {
        user,
        setError
    }: IUsersItem
) => {

    const queryClient = useQueryClient()
    const [showLoader, setShowLoader] = useState<boolean>(false)

    const deleteUser = useMutation(
        (data: IUserDetailId) => UsersService.delete(data),
        {
            onSuccess: () => {
                setShowLoader(false)
                queryClient.invalidateQueries(["users"])
                queryClient.invalidateQueries(["roles_count"])
            },
            onError: (errorDelete) => {
                setShowLoader(false)
                const e = errorDelete as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const onDelete = () => {
        let deleteSuccess = window.confirm("Вы действительно хотите удалить пользователя? Это действие необратимо")
        if (deleteSuccess) {
            setShowLoader(true)
            const data = {
                "id": user.id
            }
            deleteUser.mutate(data)
        }
    }

    const listDropdownMenu: IDropdownMenu[] = [
        {
            name: "Редактировать",
            link: `/users/${user.id}`,
            svg: <EditIcon/>
        },
        {
            name: "Удалить",
            svg: <DeleteIcon/>,
            color: "#EB5757",
            action: onDelete
        }
    ]

    return (
        <>
            {showLoader && <Loader/>}
            <div
                className="text-[#292B2C] items-center flex text-[16px] leading-[120%] py-[12px] px-[16px] font-[400] hover:bg-[#F2F2F2]
                max-tablet:mb-[12px] max-tablet:bg-white max-tablet:rounded-[12px] max-tablet:p-[24px] max-tablet:flex-col max-tablet:items-start max-tablet:hover:bg-white max-tablet:gap-[16px]">
                <div className="w-[calc(100%/3-12px)] max-tablet:flex max-tablet:w-full max-tablet:justify-between">
                    <Link to={`/users/${user.id}`}
                          className="font-[500] flex items-center gap-[8px] max-tablet:items-start">
                        <UsersIcon className="flex-shrink-0" fill="#17AB57"/>
                        {user.fullName}
                    </Link>
                    <div className="hidden max-tablet:flex">
                        <DropdownMenu list={listDropdownMenu} size={24}/>
                    </div>
                </div>
                <Link to={`/users/${user.id}`} className="w-[calc(100%/3)] max-tablet:w-full truncate text-ellipsis max-tablet:flex max-tablet:items-center max-tablet:gap-[8px]">
                    <EmailIcon className="flex-shrink-0 hidden max-tablet:block"/>
                    {user.email}
                </Link>
                <div className="max-tablet:flex max-tablet:gap-[8px] max-tablet:items-center w-[calc(100%/3)] max-tablet:w-full">
                    <GroupIcon className="flex-shrink-0 hidden max-tablet:block"/>
                    {user.group ? (
                        <Link to={`/groups/${user.group.id}`}
                              className="truncate text-ellipsis">{user.group.name}</Link>
                    ) : (
                        <div className="text-[#BDBDBD]">нет группы</div>
                    )}
                </div>
                <div className="w-[74px] flex-shrink-0 flex justify-end max-tablet:hidden">
                    <DropdownMenu list={listDropdownMenu} size={42}/>
                </div>
            </div>
        </>
    )
}

export default UsersItem