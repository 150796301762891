import {IFolder, IFolderDetail, IFolderDetailId, IFolderSearch, IFolderSearchResult} from "../models/folders";
import api from "./api";
import {GroupedOption, Option} from "../models/select";

export const FolderService = {
    async getById(id: string | undefined) {
        const response = await api.get<IFolder[]>(`/api/Folder/${id}`)
        return response.data
    },

    async getParentFolder(id: string) {
        const response = await api.get<IFolder>(`/api/Folder/parent/${id}`)
        return response.data
    },

    async create(data: IFolder) {
        return await api.post<IFolder>(`/api/Folder/add`, data)
    },

    async getList() {
        const {data} = await api.get<[IFolder[]]>(`/api/Folder/move`)
        return data
    },

    // async getListForSelect(id: number) {
    //     const {data} = await api.get<Option[]>(`/api/Folder/folderSelect/${id}`)
    //     // response.data.map((item) => (
    //     //
    //     // ))
    //     // let groupedOption: GroupedOption[] = []
    //     // response.data.forEach(element => {
    //     //     let first = element[0]
    //     //     let options: Option[] = []
    //     //     element.map(el => {
    //     //         options.push(
    //     //             {
    //     //                 value: Number(el.id),
    //     //                 label: el.name
    //     //             }
    //     //         )
    //     //     })
    //     //     groupedOption.push({
    //     //         label: first?.name,
    //     //         options: options,
    //     //     })
    //     // })
    //     return data
    //     // return groupedOption
    //     // return data
    // },

    async delete(data: IFolderDetailId) {
        return await api.post<boolean>(`/api/Folder/delete/`, data)
    },

    async update(id: string, data: IFolderDetail) {
        return await api.post<IFolder>(`/api/Folder/update/${id}`, data)
    },

    async search(dataSearch: IFolderSearch) {
        const {data} = await api.post<IFolderSearchResult[]>(`/api/Folder/search`, dataSearch)
        return data
    }
}