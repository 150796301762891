import {IGroup, IGroupDetailId} from "../../../../../models/groups";
import React, {useEffect, useState} from "react";
import {ReactComponent as EditIcon} from "../../../../../assets/images/global/edit-icon.svg";
import {ReactComponent as DeleteIcon} from "../../../../../assets/images/global/delete.svg";
import {ReactComponent as GroupItemIcon} from "../../../../../assets/images/group/group-item-icon.svg";
import useToggle from "../../../../../hooks/useToggle";
import ModalRenameGroup from "../../../../ui/modal/modal-rename-group/ModalRenameGroup";
import {useMutation} from "react-query";
import {GroupsService} from "../../../../../services/groups.service";
import {Link} from "react-router-dom";
import Loader from "../../../../ui/loader/Loader";
import {AxiosError} from "axios";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu";
import {IDropdownMenu} from "../../../../../models/dropdownmenu";
import {endWord} from "../../../../../helpers/WorkWithWord";

interface IGroupsItem {
    group: IGroup
    onDelete: (id: number) => void
    setError: (error: number|null) => void
}

const GroupsItem = (
    {
        group,
        onDelete,
        setError
    }: IGroupsItem
) => {

    const [show, setShow] = useState<boolean>(false)
    const [isModalUpdateGroup, openIsModalUpdateGroup, closeIsModalUpdateGroup] = useToggle()
    const [groupItem, setGroupItem] = useState<IGroup>(group)

    useEffect(() => {
        setGroupItem(group)
    }, [group])

    const deleteGroup = useMutation(
        (data: IGroupDetailId) => GroupsService.delete(data),
        {
            onSuccess: () => {
                setShow(false)
                onDelete(groupItem.id)
                setError(null)
            },
            onError: (error) => {
                setShow(false)
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const handleDelete = () => {
        let deleteSuccess = window.confirm("Вы действительно хотите удалить папку? Это действие необратимо")
        if(deleteSuccess) {
            setShow(true)
            const data: IGroupDetailId = {
                "id": groupItem.id
            }
            deleteGroup.mutate(data)
        }
    }

    const listDropdownMenu: IDropdownMenu[] = [
        {
            name: "Переименовать",
            action: () => openIsModalUpdateGroup(),
            svg: <EditIcon/>
        },
        {
            name: "Удалить",
            svg: <DeleteIcon/>,
            color: "#EB5757",
            action: handleDelete
        }
    ]

    return (
        <>
            {show && <Loader/>}
            {isModalUpdateGroup &&
                <ModalRenameGroup
                    detail={false}
                    setGroup={setGroupItem}
                    onClose={closeIsModalUpdateGroup}
                    group={group}/>}
            <div className="text-[#292B2C] items-center flex text-[16px] leading-[120%] py-[12px] px-[16px] font-[400] hover:bg-[#F2F2F2]
                max-tablet:mb-[12px] max-tablet:bg-white max-tablet:rounded-[12px] max-tablet:p-[24px] max-tablet:flex-col max-tablet:items-start max-tablet:hover:bg-white max-tablet:gap-[16px]">
                <div
                    className="w-[calc(100%/3*2-12px)] text-[16px] font-[500] text-[#292B2C] max-tablet:flex max-tablet:w-full max-tablet:justify-between max-tablet:items-start">
                    <Link to={`/groups/${groupItem.id}`} className="flex font-[500] items-center gap-[8px] max-tablet:items-start">
                        <GroupItemIcon className="flex-shrink-0"/>
                        {groupItem.name}
                    </Link>
                    <div className="hidden max-tablet:flex">
                        <DropdownMenu list={listDropdownMenu} size={24}/>
                    </div>
                </div>
                <div
                    className="w-[calc(100%/3)] text-[16px] font-[400] max-tablet:w-full text-[#828282]">
                    {groupItem.countUsers} {endWord(groupItem.countUsers ? groupItem.countUsers : 0, "пользователь", "пользователя", "пользователей")}
                </div>
                <div className="w-[74px] flex-shrink-0 flex justify-end max-tablet:hidden">
                    <DropdownMenu list={listDropdownMenu} size={42}/>
                </div>
            </div>
        </>
    )
}

export default GroupsItem