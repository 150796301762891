import {Link, useNavigate, useParams} from "react-router-dom";
import ScrollContainer from "../../../../ui/scroll-container/ScrollContainer";
import React, {useRef, useState} from "react";
import {ReactComponent as ArrowBack} from "../../../../../assets/images/global/arrow-back-icon.svg";
import {ReactComponent as RefreshIcon} from "../../../../../assets/images/global/refresh-icon.svg";
import {ReactComponent as EyeCloseIcon} from "../../../../../assets/images/global/eye-close.svg";
import {ReactComponent as EyeOpenIcon} from "../../../../../assets/images/global/eye-open.svg";
import {ReactComponent as DoubleCheckIcon} from "../../../../../assets/images/global/double-check-icon.svg";
import {ReactComponent as BaseCourseIcon} from "../../../../../assets/images/global/base-course-icon.svg";
import Loader from "../../../../ui/loader/Loader";
import {useMutation, useQuery} from "react-query";
import {LessonsService} from "../../../../../services/lessons.service";
import {AxiosError} from "axios";
import ErrorMessage from "../../../../ui/error/ErrorMessage";
import {
    ILessonsAttachWithoutUser,
    ILessonsAttachInUser, ILessonsHoursForAttach,
} from "../../../../../models/lessons";
import UsersAttachLessonsItemInactive from "./users-attach-lessons-item-inactive/UsersAttachLessonsItemInactive";
import UserAttachLessonsItemActive from "./users-attach-lessons-item-active/UsersAttachLessonsItemActive";
import {FieldArrayWithId, FormProvider, useFieldArray, useForm} from "react-hook-form";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {UsersService} from "../../../../../services/users.service";
import {CoursesService} from "../../../../../services/courses.service";
import {GroupsService} from "../../../../../services/groups.service";
import {IGroupAttachCourse, IGroupAttachCourseForUser} from "../../../../../models/groups";
import Search from "../../../../ui/search/Search";
import {AnimatePresence, motion} from "framer-motion";

const UsersAttachLessons = () => {

    const methods = useForm<ILessonsAttachInUser>()
    const {
        control,
        setValue,
        handleSubmit,
        register,
        formState: {errors}
    } = methods

    const {
        fields, append, remove
    } = useFieldArray({
        name: "lessons",
        control
    })

    const {id, courseId, groupId, userId} = useParams()
    const navigate = useNavigate()

    const refSubmitButton = useRef<HTMLButtonElement>(null)
    const [blurSearch, setBlurSearch] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState<string>("")
    const [inactiveListLesson, setInactiveListLesson] = useState<ILessonsHoursForAttach[]>([])
    const [showInactiveLessons, setShowInactiveLessons] = useState<boolean>(true)
    const [drag, setDrag] = useState<boolean>(false)
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [error, setError] = useState<number | null>(null)

    const {data: lessons, isLoading, isError} = useQuery(
        ["list_lessons", courseId],
        () => (id && window.location.host.indexOf("fedkurs") === -1) ?
            LessonsService.getListByCourseForAttach(Number(courseId), Number(id)) : LessonsService.getListByCourseForAddInFedkurs(Number(courseId)),
        {
            enabled: !!courseId,
            onSuccess: (response) => {
                if (window.location.host.indexOf("fedkurs") === -1) {
                    setValue("userId", Number(id))
                }
                setValue("courseId", Number(courseId))
                response.lessons.map((lesson) => {
                    if (lesson.isUser) {
                        append({
                            id: lesson.id,
                            lessonId: lesson.lessonId,
                            order: lesson.order,
                            hours: lesson.hours,
                            name: lesson.name,
                            testId: lesson.testId,
                            isUser: lesson.isUser,
                            isBase: lesson.isBase
                        })
                    } else {
                        setInactiveListLesson(prev => [...prev, lesson]);
                    }
                })
            }
        }
    )

    const attachUser = useMutation(
        (data: ILessonsAttachInUser) => UsersService.attachUserInCourseWithLessons(data),
        {
            onSuccess: (response) => {
                setShowLoader(false)
                navigate(`/users/${id}?event=true`)
            },
            onError: (error) => {
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
                setShowLoader(false)
            }
        }
    )

    const addCourseInFedkurs = useMutation(
        (data: ILessonsAttachWithoutUser) => CoursesService.addCourseInFedkurs(data),
        {
            onSuccess: (response) => {
                setShowLoader(false)
                navigate(`/folders/courses/${response.id}`)
            },
            onError: (error) => {
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
                setShowLoader(false)
            }
        }
    )

    const attachGroupToCourse = useMutation(
        (data: IGroupAttachCourse) => GroupsService.updateUserCourse(data),
        {
            onSuccess: (response) => {
                setShowLoader(false)
                navigate(`/groups/${response.id}`)
            },
            onError: (error) => {
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
                setShowLoader(false)
            }
        }
    )

    const attachCourseForUserInGroup = useMutation(
        (data: IGroupAttachCourseForUser) => GroupsService.attachCourseForUserInGroup(data),
        {
            onSuccess: (response) => {
                setShowLoader(false)
                navigate(`/groups/${groupId}`)
            },
            onError: (error) => {
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
                setShowLoader(false)
            }
        }
    )

    if (isLoading) return <Loader/>
    if (isError) return <ErrorMessage numberError={400}/>
    if (!lessons) return <ErrorMessage numberError={400}/>

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
    }

    const handleActiveAll = () => {
        inactiveListLesson.map((lesson, index) => {
            append({
                id: lesson.id,
                lessonId: lesson.lessonId,
                name: lesson.name,
                testId: lesson.testId,
                isUser: lesson.isUser,
                order: index + 1,
                isBase: lesson.isBase
            })
        })
        setInactiveListLesson([])
    }

    const handleBaseCourse = () => {
        if (lessons) {
            setInactiveListLesson([])
            remove()
            lessons.lessons
                .filter(lesson => lesson.isBase)
                .sort((a, b) => (a.order && b.order) ? (a.order < b.order ? -1 : 1) : 1)
                .map((lesson, index) => {
                    append({
                        id: lesson.id,
                        lessonId: lesson.lessonId,
                        name: lesson.name,
                        testId: lesson.testId,
                        isUser: lesson.isUser,
                        order: index + 1,
                        isBase: lesson.isBase
                    })
                })
            setInactiveListLesson(lessons.lessons.filter(lesson => !lesson.isBase))
        }
    }

    const handleRefresh = () => {
        setInactiveListLesson([])
        remove()
        lessons.lessons.map((lesson) => {
            setInactiveListLesson(prev => [...prev, lesson]);
        })
    }

    const toggleLessonActive = (lessonId: number, event: React.ChangeEvent<HTMLInputElement>, index?: number) => {
        if (lessons) {
            let lesson = lessons.lessons.filter(lesson => lesson.lessonId === lessonId)[0]
            if (lesson) {
                if (event.target.checked) {
                    append({
                        id: lesson.id,
                        lessonId: lesson.lessonId,
                        name: lesson.name,
                        testId: lesson.testId,
                        isUser: lesson.isUser,
                        order: fields.length + 1,
                        isBase: lesson.isBase
                    })
                    setInactiveListLesson(inactiveListLesson.filter(lessonInactive => lessonInactive !== lesson))
                } else {
                    setInactiveListLesson(prev => [lesson, ...prev])
                    remove(index)
                }
            }
        }
    }

    const onSubmit = (data: ILessonsAttachInUser) => {
        setShowLoader(true)
        if (groupId) {
            if (userId) {
                const dataSubmit: IGroupAttachCourseForUser = {
                    courseId: data.courseId,
                    groupId: Number(groupId),
                    userId: Number(userId),
                    lessons: data.lessons
                }
                attachCourseForUserInGroup.mutate(dataSubmit)
            } else {
                const dataSubmit: IGroupAttachCourse = {
                    courseId: data.courseId,
                    groupId: Number(groupId),
                    lessons: data.lessons
                }
                attachGroupToCourse.mutate(dataSubmit)
            }
        } else if (window.location.host.indexOf("fedkurs") === -1) {
            attachUser.mutate(data)
        } else {
            const dataSubmit: ILessonsAttachWithoutUser = {
                courseId: data.courseId,
                lessons: data.lessons
            }
            addCourseInFedkurs.mutate(dataSubmit)
        }
    };

    const handeSubmitRef = () => {
        if (refSubmitButton.current) {
            refSubmitButton.current.click()
        }
    }

    function onDragStart(result: any) {
        setDrag(true)
    }

    function onDragEnd(result: any) {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            fields,
            result.source.index,
            result.destination.index
        );

        remove()

        items.forEach((item, index) => {
            append({
                id: item.id,
                lessonId: item.lessonId,
                order: index + 1,
                hours: item.hours,
                name: item.name,
                testId: item.testId,
                isUser: item.isUser,
                isBase: item.isBase
            })
        })

        setDrag(false)
    }

    const reorder = (list: FieldArrayWithId<ILessonsAttachInUser, "lessons">[], startIndex: number, endIndex: number) => {
        const [removed] = list.splice(startIndex, 1);
        list.splice(endIndex, 0, removed);

        return list;
    };

    return (
        <>
            {error && (<ErrorMessage numberError={error}/>)}
            {showLoader && (<Loader/>)}
            <div className="flex flex-col gap-[32px] h-full max-laptop:gap-[24px] max-tablet:gap-[28px]">
                <div className="flex flex-col gap-[16px]">
                    <div className="inline-flex">
                        <Link
                            to={groupId ? `/groups/${groupId}` : window.location.host.indexOf("fedkurs") === -1 ? `/users/${id}?event=true` : `/folders`}
                            className="px-[16px] py-[12px] flex gap-[10px] items-center text-[18px]">
                            <ArrowBack className="flex-shrink-0"/>
                            {groupId ?
                                <span className="max-tablet:hidden">Назад к группе</span> :
                                window.location.host.indexOf("fedkurs") === -1 ?
                                    <span className="max-tablet:hidden">Назад к профилю пользователя</span> :
                                    <span className="max-tablet:hidden">Назад к курсам</span>}
                            <span
                                className="tablet:hidden font-[700] text-[24px] leading-[120%]">{lessons.course}</span>
                        </Link>
                    </div>
                    <div className="flex justify-between items-start">
                        <div className="text-[36px] font-[700] leading-[120%] max-tablet:hidden">{lessons.course}</div>
                        <button
                            onClick={handeSubmitRef}
                            className="py-[16px] px-[20px] bg-[#17AB57] rounded-[12px] text-white text-[16px] font-[500] whitespace-nowrap max-tablet:w-full">
                            Сохранить изменения
                        </button>
                    </div>
                </div>
                <Search setSearchTerm={setSearchTerm}/>
                <div className="bg-white p-[36px] rounded-[20px] flex flex-col gap-[32px] max-tablet:gap-[28px] h-full">
                    <div
                        className="flex items-center justify-between max-tablet:flex-col max-tablet:gap-[28px] max-tablet:items-start">
                        <div className="font-[500] text-[#292B2C] text-[20px] leading-[120%] max-tablet:text-[16px]">
                            Активно уроков: {fields.length} <span
                            className="text-[#BDBDBD]">(доступно {lessons.lessons.length})</span>
                        </div>
                        <div className="flex items-center gap-[16px] leading-[120%] text-[12px] font-[500] max-tablet:gap-[4px] max-tablet:w-full h-full">
                            <div className="px-[16px] py-[12px] rounded-[8px] bg-[#BDBDBD4D] cursor-pointer max-tablet:w-full max-tablet:justify-center max-tablet:items-start h-full"
                                 onClick={() => setShowInactiveLessons(!showInactiveLessons)}>
                                <AnimatePresence mode="wait">
                                    <motion.div
                                        key={showInactiveLessons ? 'first' : 'second'}
                                        initial={{opacity: 0, scale: 0.5}}
                                        animate={{opacity: 1, scale: 1}}
                                        exit={{opacity: 0, scale: 0.5}}
                                        transition={{duration: 0.1}}
                                    >
                                        {showInactiveLessons ?
                                            <div className="flex gap-[6px] items-center max-tablet:justify-center">
                                                <EyeCloseIcon/>
                                                <span className="max-tablet:hidden">Скрыть неактивные уроки</span>
                                            </div> :
                                            <div className="flex gap-[6px] items-center max-tablet:justify-center">
                                                <EyeOpenIcon/>
                                                <span className="max-tablet:hidden">Показать неактивные уроки</span>
                                            </div>
                                        }
                                    </motion.div>
                                </AnimatePresence>
                            </div>
                            <div
                                onClick={handleBaseCourse}
                                className="border-solid border-[1px] border-[#17AB57] h-full cursor-pointer flex gap-[6px] py-[11px] px-[16px] rounded-[8px] text-[#17AB57] items-center max-tablet:w-full max-tablet:justify-center">
                                <BaseCourseIcon className="flex-shrink-0"/>
                                <span className="max-tablet:hidden">Базовый курс</span>
                            </div>
                            <div
                                onClick={handleRefresh}
                                className="flex gap-[6px] items-center px-[16px] py-[12px] rounded-[8px] bg-[#BDBDBD4D] cursor-pointer max-tablet:w-full max-tablet:justify-center h-full">
                                <RefreshIcon className="flex-shrink-0"/>
                                <span className="max-tablet:hidden">Сбросить</span>
                            </div>
                            <div
                                onClick={handleActiveAll}
                                className="bg-[#17AB57] cursor-pointer flex items-center gap-[6px] px-[16px] py-[12px] rounded-[8px] text-white max-tablet:w-full max-tablet:justify-center h-full">
                                <DoubleCheckIcon className="flex-shrink-0"/>
                                <span className="max-tablet:hidden">Активировать все уроки</span>
                            </div>
                        </div>
                    </div>
                    <ScrollContainer>
                        <div className="h-full flex flex-col gap-[32px] max-tablet:gap-[28px]">
                            {searchTerm !== "" && fields.filter(field => field.name.toLowerCase().includes(searchTerm.toLowerCase())).length === 0 &&
                            inactiveListLesson.filter(lesson => lesson.name.toLowerCase().includes(searchTerm.toLowerCase())).length === 0 ? (
                                <div className="flex justify-center">
                                    По вашему запросу ничего не найдено
                                </div>
                            ) : (
                                <>
                                    <FormProvider {...methods}>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            {window.location.host.indexOf("fedkurs") === -1 && (
                                                <input type="hidden" {...register("userId", {required: true})}/>
                                            )}
                                            <input type="hidden" {...register("courseId", {required: true})}/>
                                            <div className={`h-full ` + (drag ? " mb-[90px]" : "")}>
                                                <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                                                    <Droppable droppableId="droppable">
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                                                {fields
                                                                    .filter(field => field.name.toLowerCase().includes(searchTerm.toLowerCase()))
                                                                    .map((item, index) => (
                                                                        <>
                                                                            <Draggable draggableId={String(index)}
                                                                                       key={item.id}
                                                                                       index={index}>
                                                                                {(provided) => (
                                                                                    <div
                                                                                        className="border-solid border-b-[0.5px] border-[#BDBDBD] border-opacity-[0.3] last:border-none
                                                                                        py-[12px] mr-[10px] max-tablet:m-0 max-tablet:py-[20px] max-tablet:first:pt-0 max-tablet:last:pb-0"
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                    >
                                                                                        <UserAttachLessonsItemActive
                                                                                            dragHandleProps={provided.dragHandleProps}
                                                                                            toggleLessonActive={toggleLessonActive}
                                                                                            item={item}
                                                                                            index={index}/>
                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                        </>
                                                                    ))}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </div>
                                            <button ref={refSubmitButton} type="submit" className="hidden"></button>
                                        </form>
                                    </FormProvider>
                                    {showInactiveLessons && (
                                        <div className="flex flex-col gap-[32px] max-laptop:gap-[24px] max-tablet:gap-[28px]">
                                            <div
                                                className="text-[#BDBDBD] font-[500] text-[20px] leading-[120%]">Неактивные
                                                уроки
                                            </div>
                                            <div className="flex flex-col">
                                                {inactiveListLesson
                                                    .filter(lesson => lesson.name.toLowerCase().includes(searchTerm.toLowerCase()))
                                                    .map((lesson, index) => (
                                                        <UsersAttachLessonsItemInactive
                                                            key={index}
                                                            lesson={lesson}
                                                            toggleLessonActive={toggleLessonActive}
                                                        />
                                                    ))}
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </ScrollContainer>
                </div>
            </div>
        </>
    )
}

export default UsersAttachLessons