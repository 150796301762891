import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {useContext, useEffect, useRef, useState} from "react";
import {UsersService} from "../../../../../services/users.service";
import {AxiosError} from "axios";
import {useMutation, useQuery, useQueryClient} from "react-query";
import ErrorMessage from "../../../../ui/error/ErrorMessage";
import Loader from "../../../../ui/loader/Loader";
import {Constants} from "../../../../../constans/constants";
import {ReactComponent as ArrowBackIcon} from "../../../../../assets/images/global/arrow-back-icon.svg";
import {AuthContext} from "../../../../../context/AuthContext";
import {ITab} from "../../../../../models/tabs";
import Tabs from "../../../../ui/tabs/Tabs";
import UserMainInformation from "./user-main-information/UserMainInformation";
import UserCourses from "./user-courses/UserCourses";
import UserStatistics from "./user-statistics/UserStatistics";
import ScrollContainer from "../../../../ui/scroll-container/ScrollContainer";
import {ICreateChat} from "../../../../../models/chat";
import {ChatService} from "../../../../../services/chat.service";

const UsersDetail = () => {

    const {id} = useParams()
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();

    const [error, setError] = useState<number | null>(null)
    const [show, setShow] = useState<boolean>(false)
    const [avatarUser, setAvatarUser] = useState<string>("")
    const [activeTab, setActiveTab] = useState<number>(0)
    const [listTab, setListTab] = useState<ITab[]>([])
    const {currentRoleId, currentUserId} = useContext(AuthContext)
    const refAvatar = useRef<HTMLInputElement>(null)

    const {data: user, isLoading: userLoading, isError: userError} = useQuery(
        ["user", id],
        () => UsersService.getById(id),
        {
            enabled: !!id,
            onSuccess: (response) => {
                setActiveTab(0)
                if (response.avatar) setAvatarUser(response.avatar)
                setListTab([])
                setListTab(prev => [...prev, {
                    id: 0,
                    name: "Информация",
                    content: <UserMainInformation user={response}/>
                }])
                if (Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1) {
                    setListTab(prev => [...prev, {
                        id: 1,
                        name: "Назначенные курсы",
                        content: <UserCourses/>
                    }])
                    setListTab(prev => [...prev, {
                        id: 2,
                        name: "Статистика",
                        content: <UserStatistics/>
                    }])
                }
            }
        }
    )

    const uploadAvatar = () => {
        if (refAvatar.current) {
            refAvatar.current.click()
        }
    }

    const changeAvatar = useMutation(
        (data: any) => UsersService.uploadAvatar(data),
        {
            onSuccess: (response) => {
                setShow(false)
                if (response.avatar) {
                    setAvatarUser(response.avatar)
                    queryClient.invalidateQueries(["current_user", id])
                }
            },
            onError: (errorChange) => {
                const e = errorChange as AxiosError
                setError(e.response ? e.response.status : 400)
                setShow(false)
            }
        }
    )

    const onChangeAvatar = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setShow(true)
            let obj = {
                "file": e.target.files[0],
                "userId": user?.id
            }
            changeAvatar.mutate(obj)
        }
    }

    const handleNavigateChat = () => {
        if(user) {
            if (Number(currentUserId) === user.id || user.methodist?.id !== Number(currentUserId)) {
                navigate('/messages')
            } else {
                if(user.chatId) {
                    navigate(`/messages/?active_id=${user.chatId}`)
                } else {
                    setShow(true)
                    const data: ICreateChat = {
                        studentId: user.id,
                        methodistId: Number(currentUserId)
                    }
                    createChat.mutate(data)
                }
            }
        }
    }

    const createChat = useMutation(
        (data: ICreateChat) => ChatService.createChat(data),
        {
            onSuccess: (response) => {
                setShow(false)
                navigate(`/messages/?active_id=${response}`)
            },
            onError: (error) => {
                setShow(false)
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    if (userLoading) return <Loader/>
    if (userError) return <ErrorMessage numberError={400}/>
    if (!user) return <ErrorMessage numberError={400}/>

    return (
        <>
            {show && <Loader/>}
            {error && <ErrorMessage numberError={error}/>}
            <ScrollContainer>
                <div className="flex flex-col gap-[32px] h-full max-laptop:gap-[24px] max-tablet:gap-[28px]">
                    {Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1 ? (
                        <>
                            <div className="flex flex-col gap-[16px] text-[#292B2C]">
                                <div className="inline-flex">
                                    <Link to={`/users`}
                                          className="flex gap-[10px] items-center px-[16px] py-[12px] text-[18px] max-tablet:p-0 cursor-pointer">
                                        <ArrowBackIcon className="flex-shrink-0"/>
                                        <span className="max-tablet:hidden">Назад</span>
                                        <span
                                            className="tablet:hidden text-[24px] font-[700] leading-[120%]">Пользователи</span>
                                    </Link>
                                </div>
                                <div
                                    className="max-tablet:hidden font-[700] text-[36px] leading-[120%] max-laptop:text-[28px]">
                                    Пользователи
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="text-[36px] font-[700] leading-[120%]">Профиль</div>
                        </>
                    )}
                    <div
                        className="w-full rounded-[20px] bg-white p-[36px] flex items-center justify-between max-tablet:flex-col max-tablet:gap-[18px] max-tablet:p-[24px]">
                        <div className="flex gap-[24px] items-center max-tablet:flex-col">
                            <div
                                className="w-[64px] h-[64px] rounded-[49px] overflow-hidden cursor-pointer group relative flex-shrink-0 max-tablet:w-[48px] max-tablet:h-[48px] ">
                                {avatarUser ? (
                                    <img src={avatarUser} className="w-full h-full object-cover" alt=""/>
                                ) : (
                                    <div
                                        className={`w-full h-full flex justify-center items-center text-white font-[500] text-[24px] ${Constants.LIST_COLORS[Number(user.color)]}`}>
                                        {user.lastName.trim()[0]}{user.firstName.trim()[0]}
                                    </div>
                                )}
                                <div onClick={uploadAvatar}
                                     className="absolute top-0 left-0 bg-[#ffffffe6] z-[10] w-full h-full text-[10px] flex group-hover:opacity-100 opacity-0 transition-all items-center justify-center text-center">
                                    Загрузить фото
                                </div>
                                <input accept="image/*" type="file" className="hidden" ref={refAvatar}
                                       onChange={onChangeAvatar}/>
                            </div>
                            <div className="flex flex-col gap-[8px] max-tablet:items-center">
                                <div
                                    className="text-[24px] text-[#292B2C] font-[500] max-tablet:text-[20px]">{user.lastName} {user.firstName} {user.fatherName}</div>
                                <div
                                    className="text-[16px] text-[#BDBDBD] max-tablet:text-[12px] max-tablet:truncate max-tablet:text-ellipsis">{user.role.name}</div>
                            </div>
                        </div>
                        {Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1 && (
                            <div
                                onClick={handleNavigateChat}
                                className="px-[36px] py-[16px] border-solid border-[1px] border-[#17AB57] rounded-[12px] text-[16px] font-[500] leading-[120%] text-[#17AB57] cursor-pointer">
                                Перейти в чат
                            </div>
                        )}
                    </div>
                    {listTab.length > 0 && (
                        <>
                            {Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1 && (
                                <Tabs setActiveTab={setActiveTab} list={listTab}/>
                            )}
                            {listTab[activeTab].content}
                        </>
                    )}
                </div>
            </ScrollContainer>
        </>
    )
}

export default UsersDetail