import React, {useContext, useEffect, useState} from "react";
import {IRole} from "../../../../models/role";
import {Controller, useFormContext} from "react-hook-form";
import {IUser, IUserDetail} from "../../../../models/users";
import {useQuery} from "react-query";
import {UsersService} from "../../../../services/users.service";
import {Constants, getCustomStyleReactSelect} from "../../../../constans/constants";
import {AuthContext} from "../../../../context/AuthContext";
import Loader from "../../loader/Loader";
import ErrorMessage from "../../error/ErrorMessage";
import Select from "react-select";

interface IRoleSelectProps {
    setRole?: (role: IRole) => void
    user?: IUser
}

const RoleSelect = (
    {
        setRole,
        user
    }: IRoleSelectProps
) => {

    const {
        register,
        setValue,
        control
    } = useFormContext<IUserDetail>()

    const {currentRoleId} = useContext(AuthContext)
    const [rolesShow, setRolesShow] = useState<IRole[]>([])

    const {data: roles, isLoading: rolesLoading, isError: rolesError} = useQuery(
        ["roles"],
        () => UsersService.getRoles(),
        {
            enabled: Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1,
            onSuccess: (response) => {
                if (Number(currentRoleId) === Constants.METHODIST_ROLE) {
                    response.map(role => {
                        if (Constants.LIST_ADMIN_ROLE.indexOf(role.id) < 0) {
                            setRolesShow(prev => [...prev, role])
                        }
                    })
                } else {
                    setRolesShow(response)
                }
            }
        }
    )

    useEffect(() => {
        if (rolesShow) {
            if(user) {
                setValue("role", user.role)
            } else {
                const defaultValue = rolesShow.find(r => r.id === Number(Constants.STUDENT_ROLE))
                if (defaultValue) {
                    setValue("role", defaultValue)
                    if (setRole) setRole(defaultValue)
                }
            }
        }
    }, [rolesShow, user]);

    if (rolesLoading) return <Loader/>
    if (rolesError) return <ErrorMessage numberError={400}/>
    if (!roles) return <ErrorMessage numberError={400}/>

    return (
        <>
            <div className="flex flex-col gap-[4px]">
                <div className="text-[16px] text-[#828282]">Роль</div>
                <Controller
                    control={control}
                    name={"role"}
                    rules={{
                        required: true
                    }}
                    render={({field: {onChange, value}, fieldState: {error}}) =>
                        <>
                            <Select
                                unstyled
                                styles={getCustomStyleReactSelect<IRole>()}
                                menuPortalTarget={document.body}
                                placeholder={"Выберите роль"}
                                onChange={(e) => {
                                    onChange(e)
                                }}
                                isMulti={false}
                                value={value}
                                menuPlacement="top"
                                options={rolesShow}
                                getOptionLabel={(role: IRole) => role.name}
                                getOptionValue={(role: IRole) => String(role.id)}
                            />
                            {error && (
                                <p className="text-red-600">Выберите роль</p>)}
                        </>
                    }
                />
            </div>
        </>
    )
}

export default RoleSelect