import Navigation from "../navigation/Navigation";
import React, {useContext, useState} from "react";
import {ReactComponent as LogoPicture} from "../../../assets/images/global/logo.svg"
import {ReactComponent as BurgerMenuIcon} from "../../../assets/images/menu/burger.svg"
import {ReactComponent as CloseIcon} from "../../../assets/images/global/close.svg"
import {ReactComponent as SearchIcon} from "../../../assets/images/global/search-icon.svg";
import {MobileMenuContext} from "../../../context/MobileMenuContext";
import {AnimatePresence, motion} from "framer-motion";
import Search from "../search/Search";

const Sidebar = () => {

    const {isMenuOpen,toggleMenu} = useContext(MobileMenuContext)
    // const [isFirstSvg, setIsFirstSvg] = useState<boolean>(true)
    const [isVisibleSearch, setIsVisibleSearch] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState<string>("")

    const toggleSvg = () => {
        toggleMenu()
        // setIsFirstSvg(!isFirstSvg)
    }

    const toggleSearch = () => {
        setIsVisibleSearch(!isVisibleSearch)
    }

    return (
            <div
                className="flex flex-col h-full max-w-[300px] w-full max-laptop:max-w-[115px] max-tablet:max-w-full max-tablet:max-h-[78px]">
                <div
                    className="relative py-[16px] pl-[36px] h-full border-solid border-b-[0.5px] border-[#BDBDBD] border-opacity-[0.3] flex justify-start items-center max-laptop:pl-[20px] tablet:max-h-[83px] max-tablet:min-h-[78px] max-laptop:justify-center max-tablet:justify-between max-tablet:px-[20px]">
                    <LogoPicture className="max-w-[140px]"/>
                    <div className="hidden max-tablet:flex gap-[16px]">
                        <SearchIcon className="w-[24px] h-[24px] fill-[#292B2C]" onClick={toggleSearch}/>
                        <AnimatePresence mode="wait">
                            <motion.div
                                key={isMenuOpen ? 'first' : 'second'}
                                initial={{opacity: 0, scale: 0.5}}
                                animate={{opacity: 1, scale: 1}}
                                exit={{opacity: 0, scale: 0.5}}
                                transition={{duration: 0.1}}
                            >
                                {isMenuOpen ? <CloseIcon onClick={toggleSvg} className="w-[24px] h-[24px] fill-[#292B2C]"/> :
                                    <BurgerMenuIcon onClick={toggleSvg}/>}
                            </motion.div>
                        </AnimatePresence>
                    </div>
                    <div className={`tablet:hidden fixed z-[999] top-0 right-0 w-full h-[78px] flex gap-[16px] bg-white px-[20px] py-[12px] items-center transform transition-transform duration-500 ${isVisibleSearch ? 'translate-x-0' : 'translate-x-full'}`}>
                        <Search setSearchTerm={setSearchTerm}/>
                        <CloseIcon className="w-[24px] h-[24px] fill-[#292B2C]" onClick={toggleSearch}/>
                    </div>
                </div>
                <Navigation/>
            </div>
    )
}

export default Sidebar