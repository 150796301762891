import FilesLesson from "../../../../../../../ui/files-lesson/FilesLesson";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {ITest} from "../../../../../../../../models/tests";
import React, {useContext, useEffect, useState} from "react";
import {ILessonsDetail, ILessonsEdit} from "../../../../../../../../models/lessons";
import {LessonsService} from "../../../../../../../../services/lessons.service";
import {TestsService} from "../../../../../../../../services/tests.service";
import {useParams} from "react-router-dom";
import {IFile} from "../../../../../../../../models/file";
import useToggle from "../../../../../../../../hooks/useToggle";
import ModalAddFileByLink from "../../../../../../../ui/modal/modal-add-file-by-link/ModalAddFileByLink";
import ReactQuill from "react-quill";
import ModalAttachLessonTest from "../../../../../../../ui/modal/modal-attach-lesson-test/ModalAttachLessonTest";
import {ReactComponent as AddTestIcon} from "../../../../../../../../assets/images/courses/add-test-icon.svg";
import {ReactComponent as TestsIcon} from "../../../../../../../../assets/images/global/tests-icon.svg";
import {ReactComponent as CloseIcon} from "../../../../../../../../assets/images/global/close.svg";
import Loader from "../../../../../../../ui/loader/Loader";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {Constants} from "../../../../../../../../constans/constants";
import {AxiosError} from "axios";
import ErrorMessage from "../../../../../../../ui/error/ErrorMessage";
import {LoaderContext} from "../../../../../../../../context/LoaderContext";
import {ReactComponent as ArrowBackIcon} from "../../../../../../../../assets/images/global/arrow-back-icon.svg";
import {ReactComponent as CheckboxUnchecked} from "../../../../../../../../assets/images/tests/checkbox-unchecked.svg";
import {ReactComponent as CheckboxChecked} from "../../../../../../../../assets/images/tests/checkbox-checked.svg";

interface ICourseLessonEdit {
    lessonId?: number
    setLessonEdit: (flag: boolean) => void
}

const CourseLessonEdit = (
    {
        lessonId,
        setLessonEdit
    }: ICourseLessonEdit
) => {

    const queryClient = useQueryClient()

    const methods = useForm<ILessonsDetail>()
    const {
        setValue,
        handleSubmit,
        register,
        unregister,
        watch,
        formState: {errors}
    } = methods

    const {show, setShow} = useContext(LoaderContext)
    const {courseId} = useParams()
    const [isModalOpenAddFile, setOnModalOpenAddFile, setOnModalCloseAddFile] = useToggle()
    const [fileModal, setFileModal] = useState<IFile>({} as IFile)
    const [isMainFile, setIsMainFile] = useState<boolean>(false)
    const [test, setTest] = useState<ITest>({} as ITest)
    const [isModalTest, setOnModalOpenTest, setOnModalCloseTest] = useToggle()
    const [save, setSave] = useState<boolean>(false)
    const [error, setError] = useState<number | null>(null)
    const [filesLesson, setFilesLesson] = useState<number[]>([])

    useEffect(() => {
        setValue('courseId', Number(courseId))
    }, [])

    const lesson = useQuery<ILessonsEdit>(
        ['lesson_course', lessonId],
        () => LessonsService.getById(lessonId),
        {
            retry: false,
            enabled: !!lessonId,
            onSuccess: (response) => {
                setValue("markFrom", response.markFrom)
                setValue("markUp", response.markUp)
                setValue('name', response.name)
                setValue('description', response.description)
                setValue("hours", response.hours)
                setValue("isBase", response.isBase)
            }
        }
    )

    const testId = lesson.data?.testId
    const testLesson = useQuery<ITest>(
        ["test", testId],
        () => TestsService.getById(testId),
        {
            enabled: !!testId,
            retry: false,
            onSuccess: (response) => {
                setValue("test", response)
                setTest(response)
            }
        }
    )

    const createLesson = useMutation(
        (createLesson: ILessonsDetail) => LessonsService.create(createLesson),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['list_lessons_course', Number(courseId)])
                queryClient.invalidateQueries(['course_detail', courseId])
                setShow(false)
                setLessonEdit(false)
            },
            onError: (errorCreate) => {
                setShow(false)
                const e = errorCreate as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const updateLesson = useMutation(
        (updateLesson: ILessonsDetail) => LessonsService.update(lessonId ? lessonId : 0, updateLesson),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['list_lessons_course', Number(courseId)])
                queryClient.invalidateQueries(['course_detail', courseId])
                setShow(false)
                setSave(true)
                // setLessonEdit(false)
            },
            onError: (errorUpdate) => {
                setShow(false)
                const e = errorUpdate as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const onSubmit: SubmitHandler<ILessonsDetail> = async (data) => {
        setShow(true)
        if(data.additionalFiles) {
            data.additionalFiles = JSON.parse(data.additionalFiles)
        }
        if (!(Object.keys(test).length > 0)) delete (data.test)
        if (!data.markFrom) delete (data.markFrom)
        if (!data.markUp) delete (data.markUp)
        if (lessonId) {
            updateLesson.mutate(data)
        } else {
            createLesson.mutate(data)
        }
    }

    useEffect(() => {
        setValue("test", test)
    }, [test])

    const watchTest = watch("test");
    useEffect(() => {
        if (watchTest && Object.keys(watchTest).length !== 0) {
            unregister("mainFile")
        }
    }, [watchTest])

    useEffect(() => {
        const timer = setTimeout(() => {
            setSave(false)
        }, 10000)
        return () => clearTimeout(timer)
    }, [save])

    useEffect(() => {
        register("description")
    }, [register])
    const onEditorStateChange = (editorState: string) => {
        setValue("description", editorState);
    };
    const editorContent = watch("description");

    const classNameFieldForm = "flex gap-[18px] py-[20px] border-b-[2px] border-solid border-[#BDBDBD] first:pt-0 last:pb-0 last:border-none max-lg:flex-col"

    const handleCancel = () => {
        setLessonEdit(false)
    }

    if (lesson.isLoading) return <Loader/>
    if (testLesson.isLoading) return <Loader/>

    return (
        <>
            {show && <Loader/>}
            {error && <ErrorMessage numberError={error}/>}
            <FormProvider {...methods}>
                <form className="flex flex-col gap-[18px] w-[50%] max-2xl:w-[75%] max-lg:w-full"
                      onSubmit={handleSubmit(onSubmit)}>
                    <h2 className="leading-[120%] text-[24px] font-bold">{lesson.data ? (<>Редактирование урока
                        "{lesson.data.name}"</>) : (<>Добавление урока</>)}</h2>
                    <div className="">
                        <div className={classNameFieldForm}>
                            <div className="w-[170px] flex-shrink-0">Базовый урок</div>
                            <label className="flex items-center gap-[6px] cursor-pointer w-full">
                                <input className="hidden peer" type="checkbox" {...register("isBase")}/>
                                <CheckboxUnchecked className="block peer-checked:hidden flex-shrink-0"/>
                                <CheckboxChecked className="hidden peer-checked:block flex-shrink-0"/>
                                Да
                            </label>
                        </div>
                        <div className={classNameFieldForm}>
                            <div className="w-[170px] flex-shrink-0">Название урока</div>
                            <div className="flex flex-col w-full">
                                <input
                                    className="py-[8px] pl-[12px] pr-[10px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[5px]"
                                    placeholder="Введите название урока"
                                    {...register("name", {required: true})}/>
                                {errors.name && errors.name.type === "required" && (
                                    <p className="text-red-600">Название урока обязательно</p>
                                )}
                            </div>
                            <input type="hidden" {...register("courseId", {required: true})}/>
                        </div>
                        <div className={classNameFieldForm}>
                            <div className="w-[170px] flex-shrink-0">Текст урока</div>
                            <ReactQuill className="h-full" theme="snow" modules={Constants.REACT_QUILL_MODULES}
                                        formats={Constants.REACT_QUILL_FORMATS}
                                        value={editorContent} onChange={onEditorStateChange}/>
                        </div>

                        {window.location.host.indexOf("fedkurs") !== -1 && (
                            <div className={classNameFieldForm}>
                                <div className="w-[170px] flex-shrink-0">Количество часов на урок</div>
                                <div className="flex flex-col w-full">
                                    <input
                                        type="number"
                                        className="py-[8px] pl-[12px] pr-[10px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[5px]"
                                        placeholder="Введите кол-во часов"
                                        {...register("hours", {required: true})}/>
                                    {errors.hours && errors.hours.type === "required" && (
                                        <p className="text-red-600">Количество часов обязательно</p>
                                    )}
                                </div>
                            </div>
                        )}

                        {watchTest && Object.keys(watchTest).length === 0 && (
                            <FilesLesson
                                setFilesLesson={setFilesLesson}
                                lessonId={lesson.data?.id}
                                files={lesson.data?.files}
                                fileModal={fileModal}
                                setOnModalOpenAddFile={setOnModalOpenAddFile}
                                setIsMainFile={setIsMainFile}
                                classNameFieldForm={classNameFieldForm}
                                test={test}
                            />
                        )}

                        {filesLesson.length === 0 && (
                            <>
                                <div className={classNameFieldForm + ` items-center max-lg:items-start`}>
                                    <div className="w-[170px] flex-shrink-0">Тестирование</div>
                                    {Object.keys(test).length > 0 ? (
                                        <div className="flex gap-[6px] items-center text-[16px] font-medium">
                                            <TestsIcon className="flex-shrink-0"/>
                                            {test.name} ({test.id})
                                            <CloseIcon className="cursor-pointer flex-shrink-0" width="18px"
                                                       height="18px"
                                                       onClick={() => setTest({} as ITest)}/>
                                        </div>
                                    ) : (
                                        <div
                                            onClick={setOnModalOpenTest}
                                            className="py-[8px] pr-[12px] pl-[16px] flex gap-[6px] items-center cursor-pointer border-[1px] border-solid border-[#17AB57] rounded-[5px]">
                                            <AddTestIcon className="flex-shrink-0"/>
                                            Привязать тест
                                        </div>
                                    )}
                                </div>

                                {Object.keys(test).length > 0 && (
                                    <div className={classNameFieldForm + ` items-center`}>
                                        <div className="w-[170px] flex-shrink-0">Баллы</div>
                                        <div className="flex gap-[18px] items-center">
                                            <div className="">
                                                <input
                                                    {...register("markFrom", {
                                                        required: true,
                                                        valueAsNumber: true,
                                                        value: 60
                                                    })}
                                                    placeholder="От"
                                                    type="number"
                                                    className="py-[8px] pl-[12px] pr-[10px] border-[1px] border-solid border-[#BDBDBD] rounded-[5px] w-[150px] max-lg:w-[100[x] focus:outline-none focus:border-[#17AB57]"/>
                                                {errors.markFrom && errors.markFrom.type === "required" && (
                                                    <p className="text-red-600">Заполните поле</p>
                                                )}
                                            </div>
                                            -
                                            <div className="">
                                                <input
                                                    {...register("markUp", {
                                                        required: true,
                                                        valueAsNumber: true,
                                                        value: 100
                                                    })}
                                                    placeholder="До"
                                                    type="number"
                                                    className="py-[8px] pl-[12px] pr-[10px] border-[1px] border-solid border-[#BDBDBD] rounded-[5px] w-[150px] max-lg:w-[100[x] focus:outline-none focus:border-[#17AB57]"/>
                                                {errors.markUp && errors.markUp.type === "required" && (
                                                    <p className="text-red-600">Заполните поле</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}

                    </div>
                    <div className="flex gap-[12px] justify-end">
                        <div
                            onClick={handleCancel}
                            className="flex gap-[6px] py-[8px] px-[12px] max-lg:hidden border-solid border-[1px] border-[#17AB57] rounded-[5px] cursor-pointer items-center">
                            <ArrowBackIcon/>
                            Назад
                        </div>
                        <div
                            className={`py-[8px] px-[16px] bg-green-500 rounded-[5px] text-white text-[16px] font-medium` + (save ? " block" : " hidden")}>Сохранено
                        </div>
                        <button
                            type="button"
                            onClick={handleCancel}
                            className="py-[8px] px-[16px] border-[#17AB57] border-solid border-[1px] rounded-[5px] text-[16px] font-medium">
                            Отмена
                        </button>
                        <button
                            type="submit"
                            className="py-[8px] px-[16px] bg-[#17AB57] rounded-[5px] text-white text-[16px] font-medium">
                            Сохранить
                        </button>
                    </div>
                </form>
            </FormProvider>
            {isModalOpenAddFile &&
                <ModalAddFileByLink
                    setIsMainFile={setIsMainFile}
                    isMainFile={isMainFile}
                    setFileModel={setFileModal}
                    onClose={setOnModalCloseAddFile}/>}
            {isModalTest &&
                <ModalAttachLessonTest
                    setTest={setTest}
                    onClose={setOnModalCloseTest}/>}
        </>
    )
}

export default CourseLessonEdit