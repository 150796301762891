import Loader from "../../../../ui/loader/Loader";
import ErrorMessage from "../../../../ui/error/ErrorMessage";
import ScrollContainer from "../../../../ui/scroll-container/ScrollContainer";
import {Link, useNavigate} from "react-router-dom";
import ResetSubmit from "../../../../ui/reset-submit/ResetSubmit";
import React, {useState} from "react";
import {useMutation} from "react-query";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {IQuestion, IQuestionDetail, IQuestionOption, ITestDetail} from "../../../../../models/tests";
import {TestsService} from "../../../../../services/tests.service";
import {AxiosError} from "axios";
import {ReactComponent as ArrowBackIcon} from "../../../../../assets/images/global/arrow-back-icon.svg";
import TestsQuestionsItem from "../../../../ui/list-option-test-edit/tests-questions-item/TestsQuestionsItem";
import ListOptionTestEdit from "../../../../ui/list-option-test-edit/ListOptionTestEdit";

const TestsAdd = () => {

    const navigate = useNavigate()

    const [show, setShow] = useState<boolean>(false)
    const [error, setError] = useState<number | null>(null)
    const [questions, setQuestions] = useState<IQuestionDetail[]>([])
    const [preview, setPreview] = useState<string>('')

    const methods = useForm<ITestDetail>()
    const {
        register,
        handleSubmit,
        formState: {errors},
        reset,
        setValue
    } = methods

    const addTest = useMutation(
        (data: ITestDetail) => TestsService.create(data),
        {
            onSuccess: (data) => {
                setShow(false)
                navigate(`/tests/${data.id}`)
            },
            onError: (errorAdd) => {
                setShow(false)
                const e = errorAdd as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const handleReset = () => {
        reset({
            name: "",
            testQuestions: []
        })
    }

    const onSubmit: SubmitHandler<ITestDetail> = async (data) => {
        // console.log(data)
        setShow(true)
        addTest.mutate(data)
    }

    return (
        <>
            {show && <Loader/>}
            {error && <ErrorMessage numberError={400}/>}
            <ScrollContainer>
                <FormProvider {...methods}>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="flex flex-col gap-[32px] h-full max-laptop:gap-[24px] max-tablet:gap-[28px]">
                        <div className="flex flex-col gap-[16px] text-[#292B2C]">
                            <div className="inline-flex">
                                <Link to={`/tests`}
                                      className="flex gap-[10px] items-center px-[16px] py-[12px] text-[18px] max-tablet:p-0 cursor-pointer">
                                    <ArrowBackIcon className="flex-shrink-0"/>
                                    <span className="max-tablet:hidden">Назад</span>
                                    <span
                                        className="tablet:hidden text-[24px] font-[700] leading-[120%]">Добавление тестирования</span>
                                </Link>
                            </div>
                            <div className="flex justify-between items-start max-tablet:hidden">
                                <div className="font-[700] text-[36px] leading-[120%] max-laptop:text-[28px]">
                                    Добавление тестирования
                                </div>
                                <div
                                    className="flex gap-[12px] text-[16px]">
                                    <ResetSubmit actionReset={handleReset}/>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-[20px]">
                            <div
                                className="bg-white rounded-[20px] p-[36px] flex flex-col gap-[20px] max-tablet:p-[24px] w-full">
                                <div className="text-[#292B2C] text-[20px] font-[500]">Основная информация</div>
                                <div className="flex flex-col gap-[4px]">
                                    <div className="text-[16px] text-[#828282]">Название теста</div>
                                    <input
                                        type="text"
                                        className="p-[16px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[12px] h-[54px]"
                                        placeholder="Введите название"
                                        {...register("name", {required: true})}/>
                                    {errors.name && errors.name.type === "required" && (
                                        <p className="text-red-600">Название обязательно</p>
                                    )}
                                </div>
                            </div>
                            <ListOptionTestEdit/>
                        </div>
                    </form>
                </FormProvider>
            </ScrollContainer>
        </>
    )
}

export default TestsAdd