import {
    ILessonByCourseForAttach, ILessonPreview,
    ILessons, ILessonsAttachInUser,
    ILessonsEdit,
    ILessonsUpdateHours, IListLessonsByCourse,
    IOrderLesson, ISearchLessonLeft, ISearchLessonPost
} from "../models/lessons";
import api from "./api";

export const LessonsService = {
    async getById(id: number | undefined) {
        const {data} = await api.get<ILessonsEdit>(`/api/Lesson/${id}`)
        return data
    },

    async preview(id: number | undefined) {
        const {data} = await api.get<ILessonPreview>(`/api/Lesson/preview/${id}`)
        return data
    },

    async getByCourse(id: number) {
        const {data} = await api.get<IListLessonsByCourse>(`/api/Lesson/course/${id}`)
        return data
    },

    async create(data: any) {
        const response = await api.post<ILessonsEdit>('/api/Lesson/add', data)
        return response.data.id
    },

    async update(id: number, data: any) {
        const response = await api.post<ILessonsEdit>(`/api/Lesson/update/${id}`, data)
        return response.data.id
    },

    async updateOrder(id: number, data: any) {
        return await api.post<ILessons>(`/api/Lesson/updateOrder/${id}`, data)
    },

    async updateOrderList(id: number, dataSubmit: IOrderLesson[]) {
        const {data} = await api.post<ILessons[]>(`/api/Lesson/updateOrderList/${id}`, dataSubmit)
        return data
    },

    async delete(id: number) {
        return await api.post<boolean>(`/api/Lesson/delete/${id}`, [])
    },

    async downloadFile(id: number) {
        const {data} = await api.post<string>(`/api/Lesson/downloadObject/${id}`, [])
        return data
    },

    async updateHours(id: number, dataSubmit: ILessonsUpdateHours[]) {
        const {data} = await api.post<string>(`/api/Lesson/updateHours/${id}`, dataSubmit)
        return data
    },

    async getListByCourseForAttach(courseId: number, userId: number) {
        const {data} = await api.get<ILessonsAttachInUser>(`/api/Lesson/getListByCourseForAttach/${courseId}/${userId}`)
        return data
    },

    async getListByCourseForAddInFedkurs(courseId: number) {
        const {data} = await api.get<ILessonsAttachInUser>(`/api/Lesson/getListByCourseForAddInFedkurs/${courseId}`)
        return data
    },

    async searchLessons(dataSubmit: ISearchLessonPost){
        const {data} = await api.post<ISearchLessonLeft>(`/api/Lesson/searchLessons/`, dataSubmit)
        return data
    }
}