import Cookies from "universal-cookie";
import generator from "generate-password-ts";

const cookies = new Cookies()

export const logout = () => {
    cookies.remove("token", {path: "/"})
    cookies.remove("role", {path: "/"})
    cookies.remove("user", {path: "/"})
    cookies.remove("refreshToken", {path: "/"})
}

export const generatePassword = () => {
    return generator.generate({
        length: 12,
        numbers: true
    })
}
