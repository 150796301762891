import React, {ReactNode, useContext, useEffect, useState} from "react";
import Search from "../search/Search";
import {AuthContext} from "../../../context/AuthContext";
import {useLocation} from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";

interface IContent {
    children: ReactNode
}

const Content = (
    {
        children
    }: IContent
) => {

    const location = useLocation()
    const {authenticated, setAuthenticated, currentRoleId, currentUserId} = useContext(AuthContext)
    const [searchTerm, setSearchTerm] = useState<string>("")

    useEffect(() => {
        const handleWheel = (event: WheelEvent) => {
            if (event.target && event.target instanceof HTMLInputElement && event.target.type === 'number') {
                event.preventDefault();
            }
        };

        document.addEventListener('wheel', handleWheel, { passive: false });

        return () => {
            document.removeEventListener('wheel', handleWheel);
        };
    }, []);

    return (
        <>
            {!authenticated ? (
                <>
                    {children}
                </>
            ) : (
                <>
                    <Sidebar/>
                    <div className="flex flex-col w-full h-full">
                        <div
                            className="tablet:max-h-[83px] h-full border-solid border-b-[0.5px] border-[#BDBDBD] border-opacity-[0.3] w-full px-[36px] py-[16px] max-laptop:px-[20px] max-tablet:hidden">
                            <Search setSearchTerm={setSearchTerm} isHeader={true}/>
                        </div>
                        <div className={`h-full ${!location.pathname.startsWith('/messages') ? "bg-[#F7F7F7] p-[36px] max-laptop:p-[24px] relative max-tablet:py-[28px] max-tablet:px-[16px]" : ""}`}>
                            {children}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default Content