import {IGetVisitsUser, IUser} from "../../../../../../models/users";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Line} from "react-chartjs-2";
import {useMutation} from "react-query";
import {UsersService} from "../../../../../../services/users.service";
import DatePiker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import Loader from "../../../../../ui/loader/Loader";
import {useParams} from "react-router-dom";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
const UserStatistics = () => {

    const {id} = useParams()
    const [show, setShow] = useState<boolean>(false)
    const [labels, setLabels] = useState<string[]>([])
    const [datasets, setDatasets] = useState<number[]>([])

    const [startDate, setStartDate] = useState<Date | null>(null)
    const [finishDate, setFinishDate] = useState<Date | null>(null)

    let dataChart = {
        labels,
        datasets: [
            {
                label: 'Время на сайте (в минутах)',
                data: datasets,
                borderColor: 'rgb(23, 171, 87)',
                backgroundColor: 'rgba(23, 171, 87, 0.5)',
                fill: true,
                cubicInterpolationMode: 'monotone' as const
            }
        ],
    };

    const visits = useMutation(
        (data: IGetVisitsUser) => UsersService.getVisits(data),
        {
            onSuccess: (response) => {
                response.map((item) => {
                    setLabels(prev => [...prev, dayjs(item.date).format("DD.MM.YYYY")])
                    setDatasets(prev => [...prev, item.minutes])
                })
                dataChart = {
                    labels,
                    datasets: [
                        {
                            label: 'Время на сайте',
                            data: datasets,
                            borderColor: 'rgb(23, 171, 87)',
                            backgroundColor: 'rgba(23, 171, 87, 0.5)',
                            fill: true,
                            cubicInterpolationMode: 'monotone' as const
                        }
                    ],
                };
                setShow(false)
            }
        }
    )

    const options = {
        responsive: true,
    };

    useEffect(() => {
        if(startDate && finishDate) {
            setShow(true)
            setLabels([])
            setDatasets([])
            const dataSubmit: IGetVisitsUser = {
                userId: Number(id),
                start: startDate,
                finish: finishDate
            }
            visits.mutate(dataSubmit)
        }
    }, [startDate, finishDate]);

    const handleChangeStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.name === 'startDate') setStartDate(dayjs(e.target.value).hour(5).minute(0).second(0).toDate())
        if(e.target.name === 'finishDate') setFinishDate(dayjs(e.target.value).hour(5).minute(0).second(0).add(1, 'day').toDate())
    }

    return (
        <>
            {show && <Loader/>}
            <div className="p-[36px] rounded-[20px] bg-white w-full h-full flex flex-col gap-[32px]">
                <div className="flex w-full justify-between max-tablet:flex-col gap-[32px] items-start">
                    <div className="text-[20px] leading-[120%] font-[500]">Время на сайте</div>
                    <div className="flex gap-[12px] max-tablet:flex-col max-tablet:w-full">
                        <input
                            onChange={handleChangeStartDate}
                            type="date"
                            name="startDate"
                            className="py-[16px] px-[20px] border-solid border-[1px] border-[#BDBDBD] rounded-[12px] text-[16px] text-[#292B2C] font-[500] max-tablet:w-full "/>
                        <input
                            onChange={handleChangeStartDate}
                            type="date"
                            name="finishDate"
                            className="py-[16px] px-[20px] border-solid border-[1px] border-[#BDBDBD] rounded-[12px] text-[16px] text-[#292B2C] font-[500] max-tablet:w-full "/>
                    </div>
                </div>
                <div className="max-h-[400px] h-full w-full flex justify-center">
                    {labels.length > 0 && datasets.length > 0 && (
                        <Line data={dataChart} options={options}/>
                    )}
                </div>
            </div>
        </>
    )
}

export default UserStatistics