import React, {useRef, useState} from "react";
import {IDropdownMenu} from "../../../../../../../models/dropdownmenu";
import {useParams} from "react-router-dom";
import {IUserCourseAttach, IUserCourses, IUserCourseUntie} from "../../../../../../../models/users";
import DropdownMenu from "../../../../../../ui/dropdown-menu/DropdownMenu";
import {ReactComponent as CourseIcon} from "../../../../../../../assets/images/menu/courses-icon.svg";
import {ReactComponent as EditIcon} from "../../../../../../../assets/images/global/edit-icon.svg";
import {ReactComponent as DeleteIcon} from "../../../../../../../assets/images/global/delete.svg";
import {ReactComponent as DownloadUpIcon} from "../../../../../../../assets/images/global/download.svg";
import {ReactComponent as ProgressIcon} from "../../../../../../../assets/images/global/loading-icon.svg";
import {ReactComponent as BlockCourseIcon} from "../../../../../../../assets/images/users/block-course.svg";
import {ReactComponent as XCircleIcon} from "../../../../../../../assets/images/users/x-circle.svg";
import {ReactComponent as UnblockCourseIcon} from "../../../../../../../assets/images/users/unblock-course.svg";
import {ReactComponent as CorrectIcon} from "../../../../../../../assets/images/global/correct-icon.svg";
import {useMutation, useQueryClient} from "react-query";
import {UsersService} from "../../../../../../../services/users.service";
import Loader from "../../../../../../ui/loader/Loader";
import {AxiosError} from "axios";

interface IUserCoursesItemProps {
    course: IUserCourses
    setError: (error: number | null) => void
}

const UserCoursesItem = (
    {
        course,
        setError
    }: IUserCoursesItemProps
) => {

    const {id} = useParams()
    const queryClient = useQueryClient()
    const refDownloadFile = useRef<HTMLAnchorElement>(null)
    const [showLoader, setShowLoader] = useState<boolean>(false)

    const untieCourse = useMutation(
        (data: IUserCourseUntie) => UsersService.untieCourse(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["user_courses"])
            }
        }
    )

    const untieCourseHandle = (courseId: number) => {
        let untieSuccess = window.confirm("Вы действительно хотите отвязать курс у пользователя? Это действие необратимо")
        if (untieSuccess) {
            const data: IUserCourseUntie = {
                "userId": Number(id),
                "courseId": courseId
            }
            untieCourse.mutate(data)
        }
    }

    const downloadUps = useMutation(
        (data: IUserCourseAttach) => UsersService.downloadUps(data),
        {
            onSuccess: (response) => {
                if (refDownloadFile.current) {
                    refDownloadFile.current.href = response
                    refDownloadFile.current.click()
                }
                setShowLoader(false)
            },
            onError: (response) => {
                const e = response as AxiosError
                setError(e.response ? e.response.status : 400)
                setShowLoader(false)
            }
        }
    )

    const handleDownloadUps = (userId: number, courseId: number) => {
        setShowLoader(true)
        const data: IUserCourseAttach = {
            "userId": userId,
            "courseId": courseId,
        }
        downloadUps.mutate(data)
    }

    const handleBlockAccess = (userId: number, courseId: number) => {
        setShowLoader(true)
        const data: IUserCourseAttach = {
            "userId": userId,
            "courseId": courseId,
        }
        changeBlockAccess.mutate(data)
    }

    const changeBlockAccess = useMutation(
        (data: IUserCourseAttach) => UsersService.changeAccessForCourse(data),
        {
            onSuccess: (response) => {
                queryClient.invalidateQueries(["user_courses"])
                setShowLoader(false)
            },
            onError: (response) => {
                const e = response as AxiosError
                setError(e.response ? e.response.status : 400)
                setShowLoader(false)
            }
        }
    )

    const listDropdownMenu: IDropdownMenu[] = [
        {
            link: `/users/${id}/course/${course.course.id}/attach-lessons`,
            name: "Редактировать",
            svg: <EditIcon/>
        },
        {
            action: () => handleDownloadUps(Number(id), course.course.id),
            name: "Скачать УП",
            svg: <DownloadUpIcon/>
        },
        {
            link: `/users/${id}/course/${course.course.id}`,
            name: "Открыть прогресс",
            svg: <ProgressIcon/>
        },
        {
            action: () => handleBlockAccess(Number(id), course.course.id),
            name: course.isBlocked ? "Разблокировать" : "Блокировать",
            svg: course.isBlocked ? <UnblockCourseIcon/> : <BlockCourseIcon/>
        },
        {
            action: () => untieCourseHandle(course.course.id),
            name: "Отвязать курс",
            color: "#EB5757",
            svg: <DeleteIcon/>
        }
    ]

    return (
        <>
            {showLoader && <Loader/>}
            <a ref={refDownloadFile} href="#" className="hidden"></a>
            <div className="text-[#292B2C] items-center flex text-[16px] leading-[120%] py-[12px] px-[16px] font-[400] hover:bg-[#F2F2F2]
                max-tablet:mb-[12px] max-tablet:bg-white max-tablet:rounded-[12px] max-tablet:p-[24px] max-tablet:flex-col max-tablet:items-start max-tablet:hover:bg-white max-tablet:gap-[16px]">
                <div
                    className="w-[calc(100%/3*2-12px)] text-[16px] font-[500] text-[#292B2C] max-tablet:flex max-tablet:w-full max-tablet:justify-between max-tablet:items-start">
                    <div className="flex gap-[8px] items-center leading-[120%]">
                        {course.isBlocked ? (<BlockCourseIcon className="flex-shrink-0"/>) : (
                            <CourseIcon className="flex-shrink-0 fill-[#17AB57]"/>)}
                        {course.course.name}
                    </div>
                    <div className="hidden max-tablet:flex">
                        <DropdownMenu list={listDropdownMenu} size={24}/>
                    </div>
                </div>
                <div className="w-[calc(100%/3)] text-[16px] font-[400] max-tablet:w-full">
                    {course.isBlocked ? (
                        <div className="leading-[120%] text-[#EB5757] flex items-center gap-[8px]">
                            <XCircleIcon className="flex-shrink-0 fill-[#EB5757] hidden max-tablet:block"/>
                            Заблокирован
                        </div>
                    ) : (course.isPassed ? (
                        <div className="leading-[120%] text-[#17AB57] flex items-center gap-[8px]">
                            <CorrectIcon className="flex-shrink-0 fill-[#17AB57] hidden max-tablet:block"/>
                            Завершен
                        </div>
                    ) : (
                        <div className="leading-[120%] text-[#2D9CDB] flex items-center gap-[8px]">
                            <ProgressIcon className="flex-shrink-0 fill-[#2D9CDB] hidden max-tablet:block"/>
                            В процессе
                        </div>
                    ))}
                </div>
                <div className="w-[74px] flex-shrink-0 flex justify-end max-tablet:hidden">
                    <DropdownMenu list={listDropdownMenu} size={42}/>
                </div>
            </div>
        </>
    )
}

export default UserCoursesItem