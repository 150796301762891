import React, {useContext, useEffect, useRef, useState} from "react";
import Cookies from "universal-cookie";
import {
    IActionMessage,
    IDeleteMessage,
    IMessage
} from "../../../../../models/chat";
import {ReactComponent as ReadMessage} from "../../../../../assets/images/chat/readMessage.svg";
import {ReactComponent as FileIcon} from "../../../../../assets/images/chat/file.svg";
import {ReactComponent as DeleteIcon} from "../../../../../assets/images/global/delete.svg";
import {ReactComponent as EditIcon} from "../../../../../assets/images/global/edit-icon.svg";
import {ReactComponent as AnswerIcon} from "../../../../../assets/images/chat/answer.svg";
import {ReactComponent as CopyIcon} from "../../../../../assets/images/global/copy-icon.svg";
import {useMutation} from "react-query";
import {ChatService} from "../../../../../services/chat.service";
import Loader from "../../../../ui/loader/Loader";
import {AuthContext} from "../../../../../context/AuthContext";
import DOMPurify from "dompurify";
import {IDropdownMenu} from "../../../../../models/dropdownmenu";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu";

interface IMessageProps {
    message: IMessage
    index: number
    handleDownloadFile: (id: number) => void
    chatId: number | null
    setActionMessage: (message: IActionMessage) => void
    setAnswerMessage: (id: number | null) => void
    changeUnreadMessage: number | null
    answerMessage: number | null
    countUnreadMessage: number | null
    messages: IMessage[]
    setLastMessage: (flag: boolean) => void
}

const Message = (
    {
        message,
        index,
        handleDownloadFile,
        chatId,
        setActionMessage,
        setAnswerMessage,
        changeUnreadMessage,
        answerMessage,
        messages,
        countUnreadMessage,
        setLastMessage
    }: IMessageProps
) => {

    const cookies = new Cookies()
    const refDownloadFile = useRef<HTMLAnchorElement>(null)
    const answeredMessageRef = useRef<HTMLDivElement>(null)
    const unreadMessage = useRef<HTMLDivElement>(null)

    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [lastMessageBySender, setLastMessageBySender] = useState<boolean>(false)
    const [showDate, setShowDate] = useState<boolean>(false)
    const {authenticated, setAuthenticated, currentRoleId, currentUserId} = useContext(AuthContext)

    const deleteMessage = useMutation(
        (data: IDeleteMessage) => ChatService.deleteMessage(data),
        {
            onSuccess: (response) => {
                setShowLoading(false)
            },
            onError: (error) => {
                setShowLoading(false)
            }
        }
    )

    const handleDeleteMessage = () => {
        if (chatId) {
            let deleteSuccess = window.confirm("Вы действительно хотите удалить сообщение? Это действие необратимо")
            if (deleteSuccess) {
                setShowLoading(true)
                let data: IDeleteMessage = {
                    chatId: chatId,
                    currentUserId: Number(currentUserId),
                    messageId: message.id
                }
                deleteMessage.mutate(data)
            }
        }
    }

    const handleActionMessage = (edit: boolean, answer: boolean) => {
        setActionMessage({
            edit: edit,
            answer: answer,
            message: message
        })
    }

    const handleScrollElement = () => {
        if (message.answeredMessage) {
            setAnswerMessage(message.answeredMessage?.id)
        }
    }

    const handleCopyText = () => {
        if (message.text) {
            navigator.clipboard.writeText(message.text.replace(/(<([^>]+)>)/gi, ''))
        }
    }

    useEffect(() => {
        if (answeredMessageRef.current && answerMessage === message.id) {
            answeredMessageRef.current.classList.add("bg-[#17ab571a]")

            setTimeout(() => {
                if (answeredMessageRef.current && answerMessage === message.id) answeredMessageRef.current.classList.remove("bg-[#17ab571a]")
            }, 3000)
        }
    }, [answerMessage]);

    useEffect(() => {
        setLastMessageBySender(false)
        setShowDate(false)
        messages.map(messageItem => {
            if (messageItem === message) {
                if (messages[index + 1] && message.senderId === messages[index + 1].senderId) {
                    setLastMessageBySender(true)
                }
                if (messages[index - 1] && message.date !== messages[index - 1].date || !messages[index - 1]) {
                    setShowDate(true)
                }
            }
            if (messageItem === messages[messages.length - 1]) {
                setLastMessage(true)
            }
        })
    }, [messages]);

    const [listDropdownMenu, setListDropdownMenu] = useState<IDropdownMenu[]>([])

    useEffect(() => {
        setListDropdownMenu([])
        setListDropdownMenu(prev => [...prev,
            {
                action: () => handleActionMessage(false, true),
                name: "Ответить",
                svg: <AnswerIcon/>
            }
        ])
        if(!message.files){
            setListDropdownMenu(prev => [...prev,
                {
                    action: () => handleCopyText(),
                    name: "Скопировать",
                    svg: <CopyIcon/>
                }
            ])
        }
        if(message.senderId === Number(currentUserId)) {
            if(!message.files) {
                setListDropdownMenu(prev => [...prev,
                    {
                        action: () => handleActionMessage(true, false),
                        name: "Редактировать",
                        svg: <EditIcon/>
                    }
                ])
            }
            setListDropdownMenu(prev => [...prev,
                {
                    action: () => handleDeleteMessage(),
                    name: "Удалить",
                    svg: <DeleteIcon/>,
                    color: "#EB5757"
                }
            ])
        }
    }, [message])

    return (
        <>
            {showLoading && <Loader/>}
            <div className="w-full first:pt-[12px] last:pb-[12px]" id={`mes-${String(message.id)}`}>
                {changeUnreadMessage === message.id && message.senderId !== Number(currentUserId) && (
                    <div ref={unreadMessage} id="unreadMessages"
                         className="flex items-center w-full justify-center bg-[#E0E0E0] py-[12px] text-[16px] text-[#292B2C] font-[500] my-[9px]">
                        Непрочитанные сообщения
                        ({countUnreadMessage})
                    </div>
                )}
                {showDate && (
                    <div className="flex justify-center my-[14px]">
                        <div
                            className="px-[12px] py-[8px] bg-[#E0E0E0] rounded-[16px] text-[16px] text-[#828282] ">{message.date}</div>
                    </div>
                )}
                <div
                    className={`flex ${message.senderId === Number(currentUserId) ? "justify-end" : "justify-start"}`}>
                    <div className="w-full flex flex-col">
                        <div
                            ref={answeredMessageRef}
                            key={index}
                            className={`transition-all relative flex gap-[10px] items-end py-[4px] px-[16px] ${!lastMessageBySender ? `pb-[14px] ${message === messages[messages.length - 1] ? `pb-0` : ``}` : ``} ${message.senderId === Number(currentUserId) ? "flex-row-reverse" : ""} `}>
                            <div
                                className={`max-w-[580px] max-tablet:max-w-full p-[12px] rounded-t-[16px] flex flex-col gap-[10px] relative group ${!lastMessageBySender ? `${message.senderId === Number(currentUserId) ? "rounded-bl-[16px] rounded-br-[4px]" : "rounded-br-[16px] rounded-bl-[4px]"}` : "rounded-b-[16px]"} ${message.senderId === Number(currentUserId) ? "bg-[#BAE6CD] ml-auto" : "bg-white text-[#292B2C] mr-auto"}`}>
                                {message.answeredMessage && Object.keys(message.answeredMessage).length > 0 && (
                                    <a
                                        href={`#mes-${message.answeredMessage.id}`}
                                        onClick={handleScrollElement}
                                        className={`px-[10px] py-[6px] rounded-[2px] bg-[#17ab574d] cursor-pointer`}>
                                        <div
                                            className="font-bold">{message.answeredMessage.lastName} {message.answeredMessage.firstName}</div>
                                        <div
                                            className="truncate max-tablet:truncate overflow-hidden whitespace-nowrap text-ellipsis">
                                            {DOMPurify.sanitize(message.answeredMessage.text, {ALLOWED_TAGS: []})}
                                        </div>
                                    </a>
                                )}
                                <div className="flex gap-[10px] items-end">
                                    <div className="flex w-full">
                                        {message.files ? (
                                            <div className={`flex flex-col gap-[14px]`}>
                                                {message.files.map((file, index) => (
                                                    <div key={index} className="flex gap-[10px] items-center">
                                                        <div
                                                            className="w-[36px] h-[36px] flex-shrink-0 rounded-[4px] bg-[#2F80ED] flex items-center justify-center">
                                                            <FileIcon/>
                                                        </div>
                                                        <div className="flex flex-col gap-[2px]">
                                                            <div className="break-all">{file.name}{file.extention}</div>
                                                            <div
                                                                onClick={() => handleDownloadFile(file.id)}
                                                                className={`${message.senderId === Number(currentUserId) ? "" : "text-[#2F80ED]"} underline text-[12px] cursor-pointer`}>Скачать
                                                            </div>
                                                            <a ref={refDownloadFile}
                                                               href="#"
                                                               className="hidden"></a>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="w-full break-words message"
                                                 dangerouslySetInnerHTML={{__html: message.text ? message.text : ""}}/>
                                        )}
                                    </div>
                                    <div
                                        className={`flex-shrink-0 ${message.senderId !== Number(currentUserId) ? "text-[#BDBDBD]" : ""}`}>{message.time}</div>
                                    {message.isViewed && message.senderId === Number(currentUserId) && (
                                        <ReadMessage className="flex-shrink-0"/>
                                    )}
                                    <DropdownMenu list={listDropdownMenu} isChat={true} senderIsCurrentUser={message.senderId === Number(currentUserId)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Message