import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../context/AuthContext";
import {Link, useNavigate} from "react-router-dom";
import {SubmitHandler, useForm} from "react-hook-form";
import {ILogin} from "../../../models/login";
import {UsersService} from "../../../services/users.service";
import {AxiosError} from "axios";
import ErrorMessage from "../../ui/error/ErrorMessage";
import Cookies from "universal-cookie"
import {Constants} from "../../../constans/constants";
import {useMutation} from "react-query";
import Loader from "../../ui/loader/Loader";
import {ReactComponent as EyePassword} from "../../../assets/images/global/eye-close.svg";
import {ReactComponent as Eye} from "../../../assets/images/global/eye-open.svg";
import Log from "../../../assets/images/global/login.png"
import useToggle from "../../../hooks/useToggle";
import ModalRecoveryPassword from "../../ui/modal/modal-recovery-password/ModalRecoveryPassword";
import {ReactComponent as Logo} from "../../../assets/images/global/logo-login.svg";
import ScrollContainer from "../../ui/scroll-container/ScrollContainer";
import ModalDocumentsInLogin from "../../ui/modal/documents-in-login/ModalDocumentsInLogin";

const Login = () => {

    const {authenticated, setAuthenticated, setUserId, setRoleId, currentRoleId} = useContext(AuthContext)
    const [error, setError] = useState<number | null>(null)
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [isModalRecoveryPassword, setOnModalOpenRecoveryPassword, setOnModalCloseRecoveryPassword] = useToggle()
    const [isModalDocuments, setOnModalOpenDocuments, setOnModalCloseDocuments] = useToggle()
    const [showLoading, setShowLoading] = useState<boolean>(false)

    const navigate = useNavigate()

    const cookies = new Cookies()

    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm<ILogin>()

    useEffect(() => {
        if (authenticated) {
            if (Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1) {
                navigate("/folders")
            } else {
                navigate("/student-courses")
            }
        }
    }, [authenticated]);

    const login = useMutation(
        (data: ILogin) => UsersService.login(data),
        {
            onSuccess: (data) => {
                cookies.set("token", data.accessToken, {
                    expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
                    path: "/"
                })
                cookies.set("refreshToken", data.refreshToken, {
                    expires: new Date(Date.now() + 180 * 24 * 60 * 60 * 1000),
                    path: "/"
                })
                cookies.set("role", data.role.id, {expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), path: "/"})
                cookies.set("user", data.id, {expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), path: "/"})
                setUserId(String(data.id))
                setRoleId(String(data.role.id))
                setAuthenticated(true)
                setShowLoading(false);
            },
            onError: (error) => {
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
                setShowLoading(false);
            }
        }
    )

    const onSubmit: SubmitHandler<ILogin> = async (data) => {
        setShowLoading(true);
        data.email = data.email.trim()
        data.password = data.password.trim()
        login.mutate(data)
    }

    return (
        <>
            {(showLoading || login.isLoading) && <Loader/>}
            {isModalRecoveryPassword && (
                <ModalRecoveryPassword
                    onClose={setOnModalCloseRecoveryPassword}
                />
            )}
            {isModalDocuments && (
                <ModalDocumentsInLogin
                    onClose={setOnModalCloseDocuments}
                />
            )}
            <ScrollContainer>
                <div className="w-full h-full flex flex-row-reverse max-tablet:flex-col">
                    <div
                        className="w-[50%] h-full flex items-center justify-center max-tablet:w-full p-[10px] max-tablet:px-[20px] max-tablet:py-[44px]">
                        <form
                            className="flex flex-col max-w-[560px] max-laptop:max-w-[400px] max-tablet:w-full gap-[40px] max-laptop:gap-[24px] max-tablet:gap-[28px] items-center"
                            onSubmit={handleSubmit(onSubmit)}>
                            {error && <ErrorMessage numberError={400}/>}
                            <div
                                className="leading-[120%] font-[700] text-[#292B2C] text-[36px] max-laptop:text-[28px]">
                                Добро пожаловать!
                            </div>
                            <div
                                className="text-center leading-[120%] font-[400] text-[#828282] text-[18px] max-laptop:text-[16px]">
                                Авторизуйтесь и учитесь эффективно с системой дистанционного образования
                            </div>
                            <div className="flex gap-[24px] flex-col w-full">
                                <div className="w-full flex flex-col gap-[4px]">
                                    <div className="leading-[120%] font-[400] text-[#828282] text-[16px]">
                                        Электронная почта
                                    </div>
                                    <input
                                        {...register("email", {required: true})}
                                        type="email"
                                        autoComplete="email"
                                        className="w-full rounded-[12px] border border-[#BDBDBD] p-[16px]"
                                    />
                                    {errors.email && errors.email.type === "required" && (
                                        <p className="text-red-600">Заполните поле</p>
                                    )}
                                </div>
                                <div className="w-full flex flex-col gap-[4px]">
                                    <div className="leading-[120%] font-[400] text-[#828282] text-[16px]">
                                        Пароль
                                    </div>
                                    <div className="relative">
                                        <div onClick={() => setShowPassword(!showPassword)}>
                                            {showPassword ? (
                                                <Eye
                                                    className="w-[20px] h-[20px] absolute top-[50%] translate-y-[-50%] right-[20px] cursor-pointer"/>
                                            ) : (
                                                <EyePassword
                                                    className="w-[20px] h-[20px] absolute top-[50%] translate-y-[-50%] right-[20px] cursor-pointer"/>
                                            )}
                                        </div>
                                        <input
                                            {...register("password", {required: true})}
                                            type={showPassword ? "text" : "password"}
                                            autoComplete="current-password"
                                            className="w-full rounded-[12px] border border-[#BDBDBD] p-[16px]"
                                        />
                                        {errors.password && errors.password.type === "required" && (
                                            <p className="text-red-600">Заполните поле</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <button
                                className="w-full rounded-[12px] bg-[#17AB57] px-[16px] py-[20px] text-[16px] font-[500] text-white">
                                Авторизоваться
                            </button>
                            <div
                                className="text-center leading-[120%] font-[400] text-[#828282] text-[16px] max-laptop:text-[14px]">
                                Авторизовываясь, вы соглашаетесь с <a target="_blank"
                                                                      className="cursor-pointer underline"
                                                                      href="/Политика-обработки-персональных-данных.pdf">правилами
                                обработки персональных
                                данных</a>
                            </div>
                            <div className="leading-[120%] font-[400] text-[#292B2C] text-[16px] ">
                                Забыли пароль? <span onClick={setOnModalOpenRecoveryPassword}
                                                     className="text-[#17AB57] cursor-pointer underline">Восстановить</span>
                            </div>
                        </form>
                    </div>
                    <div
                        className="w-[50%] h-full bg-gradient-to-bl from-[#17AB57] from-[17.96%] to-[#328C8F] to-[130.66%] flex items-center justify-center p-[10px] tablet:rounded-r-[120px] max-tablet:w-full max-tablet:rounded-t-[120px] max-tablet:py-[48px] max-tablet:px-[20px]">
                        <div
                            className="flex flex-col items-center gap-[60px] max-laptop:gap-[40px] max-tablet:gap-[32px] max-w-[640px] max-laptop:max-w-[400px] max-tablet:w-full">
                            <Logo className="max-laptop:w-[250px] max-tablet:w-[200px]"/>
                            <div
                                className="text-center text-white text-[20px] fonr-[400] leading-[120%] max-tablet:text-[14px]">
                                СДО "АПОК СТАДИ" — это система дистанционного образования, которая предоставляет доступ
                                к
                                курсам, учебным материалам и тестированиям, а также позволяет отслеживать
                                прогресс.<br/><br/>
                                Обучающиеся могут взаимодействовать с кураторами, оставлять обратную связь и свободно
                                перемещаться между доступными курсами.
                            </div>
                            <div
                                onClick={setOnModalOpenDocuments}
                                className="bg-white py-[16px] px-[20px] rounded-[12px] text-[#17AB57] text-[16px] font-[500] leading-[120%] cursor-pointer max-tablet:w-full justify-center flex">
                                Ознакомиться с документами
                            </div>
                        </div>
                    </div>
                    {/*    /!*<div*/}
                    {/*        className="flex justify-center px-[36px] py-[18px] rounded-[6px] bg-white max-w-[710px] w-full max-md:px-[25px] text-center">*/}
                    {/*        <div className=" text-[#828282] text-[14px] underline">*/}
                    {/*            Если у вас возникли технические проблемы, свяжитесь с нами по номеру <a*/}
                    {/*            href="tel:+7">+7XXXXX</a>*/}
                    {/*        </div>*/}
                    {/*    </div>*!/*/}
                </div>
            </ScrollContainer>
        </>
    )
}

export default Login