import {IFile, IFileDelete, IFileUpdateName} from "../models/file";
import api from "./api";

export const FileService = {
    async create(data: any) {
        const response = await api.post<IFile>(`/api/File/add/`, data, {
            headers: {'Content-Type': 'multipart/form-data'}
        })
        return response.data
    },

    async delete(data: IFileDelete) {
        return await api.post<boolean>(`/api/File/delete/`, data)
    },

    async update(id: number, data: IFileUpdateName) {
        return await api.post<IFile>(`/api/File/update/${id}`, data)
    },

    async getLinkForDownload(link: string) {
        const {request} = await api.head<any>(link)
        return request
    }
}