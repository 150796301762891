import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useContext, useState} from "react";
import {useQuery} from "react-query";
import {UsersService} from "../../../../../services/users.service";
import ErrorMessage from "../../../../ui/error/ErrorMessage";
import {ReactComponent as ArrowBackIcon} from "../../../../../assets/images/global/arrow-back-icon.svg";
import ScrollContainer from "../../../../ui/scroll-container/ScrollContainer";
import {AuthContext} from "../../../../../context/AuthContext";
import StudentLessonTestViewResultItem from "./student-lesson-test-view-result-item/StudentLessonTestViewResultItem";
import {Constants} from "../../../../../constans/constants";
import {positionValues} from "react-custom-scrollbars-2";
import {ReactComponent as ArrowIcon} from "../../../../../assets/images/global/arrow.svg";
import Loader from "../../../../ui/loader/Loader";

const StudentLessonTestViewResult = () => {

    const {userId, id, testId, lessonId} = useParams()
    const navigate = useNavigate()
    const {currentUserId, currentRoleId} = useContext(AuthContext)
    const [positionScroll, setPositionScroll] = useState<positionValues | null>(null)
    const [scrollToTop, setScrollToTop] = useState<boolean>(true)
    const isAdmin = Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1
    const [urlPrevLesson, setUrlPrevLesson] = useState<string>("")
    const [urlNextLesson, setUrlNextLesson] = useState<string>("")

    const {data: test, isLoading, isError} = useQuery(
        ["result_test", testId, id, currentUserId, lessonId, userId],
        () => UsersService.getResultTest(userId ? Number(userId) : Number(currentUserId), Number(testId), Number(lessonId)),
        {
            staleTime: 1000,
            cacheTime: 0,
            refetchOnMount: "always",
            onSuccess: (response) => {
                if (!response.id) {
                    if (!isAdmin) {
                        navigate(`/student-courses/${id}/lesson/${lessonId}/test-passing/${testId}`)
                    } else {
                        navigate(-1)
                    }
                }
                if (response.isBlocked) navigate("/student-courses")
                if (response.prevLesson) {
                    setUrlPrevLesson(`/student-courses/${id}/lesson/${response.prevLesson.id}`)
                } else {
                    setUrlPrevLesson(`/student-courses/${id}`)
                }
                if (response.nextLesson) {
                    setUrlNextLesson(`/student-courses/${id}/lesson/${response.nextLesson.id}`)
                } else {
                    setUrlNextLesson(`/student-courses/${id}`)
                }
            },
        }
    )

    if (isLoading) return <Loader/>
    if (isError) return <ErrorMessage numberError={400}/>
    if (!test) return <ErrorMessage numberError={400}/>

    return (
        <>
            {positionScroll && positionScroll.top <= 1 && positionScroll.top > 0 && (
                <div
                    onClick={() => setScrollToTop(!scrollToTop)}
                    className="absolute p-[16px] rounded-[49px] bg-white right-[10px] bottom-[20px] z-[50] cursor-pointer shadow-[0_0px_20px_0_rgba(0,0,0,0.15)]">
                    <div className="w-full h-full relative flex items-center justify-center">
                        <ArrowIcon className={`fill-black`}/>
                    </div>
                </div>
            )}
            <ScrollContainer
                scrollToTop={scrollToTop}
                setPositionScroll={setPositionScroll}>
                <div className="flex flex-col gap-[16px] pb-[32px] max-laptop:pb-[24px]">
                    <div className="inline-flex">
                        <Link to={userId ? `/users/${userId}/course/${id}` : `/student-courses/${id}`}
                              className="flex gap-[10px] items-center px-[16px] py-[12px] text-[18px] max-tablet:p-0 cursor-pointer">
                            <ArrowBackIcon className="flex-shrink-0"/>
                            <span className="max-tablet:hidden">Назад</span>
                            <span
                                className="tablet:hidden text-[24px] font-[700] leading-[120%]">Результат тестирования</span>
                        </Link>
                    </div>
                    <div
                        className="max-tablet:hidden font-[700] text-[36px] leading-[120%] max-laptop:text-[28px]">
                        Результат тестирования "{test.name}"
                    </div>
                </div>
                <div className="flex gap-[20px] max-tablet:flex-col-reverse">
                    <div className="h-full flex flex-col gap-[24px] w-full">
                        {test.questions.map((question, index) => (
                            <StudentLessonTestViewResultItem
                                isAdmin={isAdmin}
                                question={question}
                                key={index}
                                index={index + 1}
                                length={test.questions.length}/>
                        ))}
                    </div>
                    <div
                        className="w-[400px] sticky top-0 self-start flex-shrink-0 max-laptop:w-[300px] max-tablet:w-full max-tablet:relative">
                        <div
                            className="flex flex-col gap-[24px] rounded-[20px] bg-white p-[36px] mb-[12px] max-laptop:p-[24px]">
                            <div className="text-[#292B2C] text-[20px] leading-[120%] font-[500]">Статистика
                            </div>
                            <div className="flex flex-col gap-[8px] text-[16px] font-[500] leading-[120%]">
                                <div
                                    className={`w-full py-[16px] px-[20px] rounded-[12px] border-solid border-[1px] flex justify-between items-center ${test.result >= test.threshold ? `text-[#17AB57] border-[#17AB57]` : `text-[#EB5757] border-[#EB5757]`}`}>
                                    <span>Ваш результат</span>
                                    <span className="text-[28px] leading-[120%]">{test.result}%</span>
                                </div>
                                <div
                                    className="w-full py-[16px] px-[20px] rounded-[12px] bg-[#F2F2F2] flex justify-between items-center">
                                    <span>Порог</span>
                                    <span className="text-[28px] leading-[120%]">{test.threshold}%</span>
                                </div>
                                <div className="flex flex-col gap-[8px] max-tablet:flex-row">
                                    <div
                                        className="w-full py-[16px] px-[20px] rounded-[12px] bg-[#F2F2F2] flex justify-between items-center max-tablet:flex max-tablet:flex-col max-tablet:gap-[8px] max-tablet:items-start max-tablet:text-[14px]">
                                        <span>Правильных ответов</span>
                                        <span
                                            className="text-[20px] leading-[120%] text-[#17AB57]">{test.correct}</span>
                                    </div>
                                    <div
                                        className="w-full py-[16px] px-[20px] rounded-[12px] bg-[#F2F2F2] flex justify-between items-center max-tablet:flex max-tablet:flex-col max-tablet:gap-[8px] max-tablet:items-start max-tablet:text-[14px]">
                                        <span>Неправильных ответов</span>
                                        <span
                                            className="text-[20px] leading-[120%] text-[#EB5757]">{test.incorrect}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!isAdmin && (
                            <div className="flex items-center gap-[8px] mt-[12px] w-full">
                                {test.prevLesson && (
                                    <Link
                                        className="rounded-[8px] flex py-[8px] px-[16px] items-center justify-center border border-[#17AB57] self-stretch"
                                        to={urlPrevLesson}>
                                        <ArrowIcon className="fill-[#17AB57]"/>
                                    </Link>
                                )}
                                <Link
                                    className="bg-[#17AB57] text-white rounded-[12px] flex py-[16px] px-[20px] cursor-pointer justify-center text-[16px] leading-[120%] font-[500] w-full"
                                    to={`/student-courses/${id}/lesson/${lessonId}/test-passing/${testId}`}>
                                    Пересдать
                                </Link>
                                {test.nextLesson && test.result >= test.threshold && (
                                    <Link
                                        className="rounded-[8px] flex py-[8px] px-[16px] items-center justify-center border border-[#17AB57] self-stretch"
                                        to={urlNextLesson}>
                                        <ArrowIcon className="rotate-180 fill-[#17AB57]"/>
                                    </Link>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </ScrollContainer>
        </>
    )
}

export default StudentLessonTestViewResult