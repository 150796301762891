import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import {GroupsService} from "../../../../../services/groups.service";
import ErrorMessage from "../../../../ui/error/ErrorMessage";
import {ReactComponent as ArrowBackIcon} from "../../../../../assets/images/global/arrow-back-icon.svg";
import {ReactComponent as CreateCoursesIcon} from "../../../../../assets/images/courses/create-courses-icon.svg";
import useToggle from "../../../../../hooks/useToggle";
import ModalRenameGroup from "../../../../ui/modal/modal-rename-group/ModalRenameGroup";
import {IGroupDetailId} from "../../../../../models/groups";
import ModalAttachUserGroup from "../../../../ui/modal/modal-attach-user-group/ModalAttachUserGroup";
import Loader from "../../../../ui/loader/Loader";
import ModalAttachCourseUser from "../../../../ui/modal/modal-attach-course-user/ModalAttachCourseUser";
import GroupsDetailUserItem from "./groups-detail-user-item/GroupsDetailUserItem";
import {AuthContext} from "../../../../../context/AuthContext";
import Search from "../../../../ui/search/Search";
import ScrollContainer from "../../../../ui/scroll-container/ScrollContainer";
import {AxiosError} from "axios";
import {endWord} from "../../../../../helpers/WorkWithWord";

const GroupsDetail = () => {

    const navigate = useNavigate()
    const {id} = useParams()
    const {currentRoleId, currentUserId} = useContext(AuthContext)

    const [searchTerm, setSearchTerm] = useState<string>("")
    const [isModalUpdateGroup, openIsModalUpdateGroup, closeIsModalUpdateGroup] = useToggle()
    const [isModalAttachUser, setOnModalOpenAttachUser, setOnModalCloseAttachUser] = useToggle()
    const [isModalAttachGroupToCourse, setOnModalOpenAttachGroupToCourse, setOnModalCloseAttachGroupToCourse] = useToggle()
    const [error, setError] = useState<number | null>(null)
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [userIdForAddCourse, setUserIdForAddCourse] = useState<number | null>(null)

    const {data: group, isLoading: groupLoading, isError: groupError} = useQuery(
        ["group", Number(id)],
        () => GroupsService.getById(Number(id)),
        {
            enabled: !!id
        }
    )

    useEffect(() => {
        if (userIdForAddCourse) setOnModalOpenAttachGroupToCourse()
    }, [userIdForAddCourse]);

    const deleteGroup = useMutation(
        (data: IGroupDetailId) => GroupsService.delete(data),
        {
            onSuccess: () => {
                setShowLoader(false)
                navigate(`/groups`)
            },
            onError: (error) => {
                setShowLoader(false)
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    if (groupLoading) return <Loader/>
    if (groupError) return <ErrorMessage numberError={400}/>
    if (!group) return <ErrorMessage numberError={400}/>

    const handleDelete = () => {
        let deleteSuccess = window.confirm("Вы действительно хотите удалить группу? Это действие необратимо")
        if (deleteSuccess) {
            setShowLoader(true)
            const data: IGroupDetailId = {
                "id": group.id
            }
            deleteGroup.mutate(data)
        }
    }

    return (
        <>
            {showLoader && <Loader/>}
            {error && <ErrorMessage numberError={error}/>}
            {isModalUpdateGroup && <ModalRenameGroup
                detail={true}
                onClose={closeIsModalUpdateGroup}
                group={group}/>}
            {isModalAttachUser && <ModalAttachUserGroup
                group={group}
                onClose={setOnModalCloseAttachUser}/>}
            {isModalAttachGroupToCourse && <ModalAttachCourseUser
                userId={Number(currentUserId)}
                group={group}
                setUserIdForAddCourse={setUserIdForAddCourse}
                idUserGroup={userIdForAddCourse}
                onClose={setOnModalCloseAttachGroupToCourse}/>}
            <ScrollContainer>
                <div className="flex flex-col gap-[32px] h-full max-laptop:gap-[24px] max-tablet:gap-[28px]">
                    <div className="flex flex-col gap-[16px] text-[#292B2C]">
                        <div className="inline-flex">
                            <Link to={`/groups`}
                                  className="flex gap-[10px] items-center px-[16px] py-[12px] text-[18px] max-tablet:p-0 cursor-pointer">
                                <ArrowBackIcon className="flex-shrink-0"/>
                                <span className="max-tablet:hidden">Назад</span>
                                <span
                                    className="tablet:hidden text-[24px] font-[700] leading-[120%]">Информация о группе</span>
                            </Link>
                        </div>
                        <div className="flex items-center justify-between">
                            <div
                                className="max-tablet:hidden font-[700] text-[36px] leading-[120%] max-laptop:text-[28px]">
                                Информация о группе
                            </div>
                            <div
                                className="flex gap-[12px] items-center text-[16px] font-[500] leading-[120%] max-tablet:text-[12px] max-tablet:w-full">
                                <div
                                    onClick={openIsModalUpdateGroup}
                                    className="cursor-pointer rounded-[12px] py-[16px] px-[20px] border-solid border-[1px] border-[#17AB57] text-[#17AB57] max-tablet:w-full max-tablet:py-[12px] max-tablet:px-[16px] whitespace-nowrap text-center">
                                    Переименовать группу
                                </div>
                                <div
                                    onClick={handleDelete}
                                    className="cursor-pointer rounded-[12px] py-[16px] px-[20px] border-solid border-[1px] border-[#EB5757] text-[#EB5757] max-tablet:w-full max-tablet:py-[12px] max-tablet:px-[16px] whitespace-nowrap text-center">
                                    Удалить группу
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col p-[36px] bg-white rounded-[20px] gap-[32px] max-tablet:gap-[16px]">
                        <div className="flex flex-col gap-[16px]">
                            <div className="flex flex-col gap-[16px] max-tablet:gap-[8px]">
                                <div
                                    className="text-[18px] font-[400] leading-[120%] text-[#828282] max-tablet:text-[16px]">Группа
                                </div>
                                <div
                                    className="text-[24px] font-[500] leading-[120%] text-[#292B2C] max-tablet:text-[20px]">{group.name}</div>
                            </div>
                            <div
                                className="text-[20px] font-[500] leading-[120%] text-[#828282] max-tablet:text-[16px]">
                                {group.users ? group.users.length : 0} {endWord(group.users ? group.users.length : 0, "пользователь", "пользователя", "пользователей")}
                            </div>
                        </div>
                        <div
                            onClick={setOnModalOpenAttachGroupToCourse}
                            className="flex w-full rounded-[12px] bg-[#17AB57] text-[#FFFFFF] text-[16px] leading-[120%] font-[500] py-[16px] px-[20px] justify-center text-center max-tablet:text-[14px] cursor-pointer">
                            Назначить всем пользователям один курс
                        </div>
                    </div>
                    <div className="flex gap-[24px] flex-col h-full">
                        <div className="w-full flex gap-[12px] items-center text-[16px] max-tablet:flex-col">
                            <Search setSearchTerm={setSearchTerm}/>
                            <div
                                onClick={setOnModalOpenAttachUser}
                                className="bg-[#17AB57] text-white rounded-[12px] items-center flex gap-[6px] py-[16px] px-[20px] font-[500] cursor-pointer whitespace-nowrap max-tablet:w-full justify-center">
                                <CreateCoursesIcon className="flex-shrink-0"/>
                                Добавить пользователя
                            </div>
                        </div>
                        <div
                            className="bg-white p-[36px] rounded-[20px] flex flex-col text-[16px] h-full max-tablet:p-0 max-tablet:bg-[#F7F7F7] max-tablet:gap-[12px]">
                            <div
                                className="flex text-[#828282] max-tablet:hidden pr-[16px] w-full border-solid border-b-[0.5px] border-[#BDBDBD] border-opacity-[0.3] pb-[20px]">
                                <div className="w-[calc(100%/3)]">Имя</div>
                                <div className="w-[calc(100%/3*2)]">Назначенные курсы</div>
                                <div className="w-[74px] flex-shrink-0">Действия</div>
                            </div>
                            {group.users && group.users
                                .filter(u => u.fatherName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                    u.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                    u.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                    u.email.toLowerCase().includes(searchTerm.toLowerCase()))
                                .map((user, index) => (
                                    <GroupsDetailUserItem
                                        groupId={Number(id)}
                                        user={user}
                                        setUserIdForAddCourse={setUserIdForAddCourse}
                                        key={index}/>
                                ))}
                        </div>
                    </div>

                </div>
            </ScrollContainer>
        </>
    )
}

export default GroupsDetail