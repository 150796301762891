import {
    IGetVisitsUser,
    IProgressCourseUser, IRecoveryPassword, IUpdateLessonPassMark,
    IUser, IUserCourseAttach,
    IUserCourses, IUserCourseUntie,
    IUserDetail,
    IUserDetailId,
    IUserGroup, IUserMethodist, IUserPassingTest,
    IUserPasswordRepeat, IUserResultTest,
    IUserSearchCourse, IUserWithoutEducationId, IVisitsUser
} from "../models/users";
import {IRole} from "../models/role";
import {ILogin, IToken, IUserAuth} from "../models/login";
import {IPassingTest} from "../models/tests";
import {ICoursesForAttach, ICoursesSearchForAttach} from "../models/courses";
import {ILessonsAttachInUser} from "../models/lessons";
import api from "./api"
import {ITab} from "../models/tabs";
import {cleanParams} from "../helpers/WorkWithGroup";


export const UsersService = {
    async getAll() {
        const response = await api.get<IUser[]>('/api/User')
        return response.data
    },

    async getAllForAttachGroup(id: number, page: number, search?: string) {
        const params = {
            id: id,
            page: page,
            search: search
        }
        const cleanedParams = cleanParams(params)
        const queryString = new URLSearchParams(cleanedParams).toString()
        const response = await api.get<IUser[]>(`/api/User/attachGroup?${queryString}`)
        return response.data
    },

    async getRoles() {
        const response = await api.get<IRole[]>('/api/User/roles')
        return response.data
    },

    async getRolesWithCount() {
        const response = await api.get<ITab[]>(`/api/User/getListRole/`)
        return response.data
    },

    async create(data: IUserDetail) {
        const response = await api.post<IUser>('/api/User/add', data)
        return response.data
    },

    async getById(id: string | undefined) {
        const response = await api.get<IUser>(`/api/User/${id}`)
        return response.data
    },

    async update(id: number, data: IUserDetail) {
        const response = await api.post<IUser>(`/api/User/update/${id}`, data)
        return response.data
    },

    async login(data: ILogin) {
        const response = await api.post<IUserAuth>(`/api/User/login`, data)
        return response.data
    },

    // async refreshToken(data: IToken) {
    //     const response = await api.post<IUserAuth>(`/api/User/refreshToken`, data)
    //     return response.data
    // },

    async searchCourse(search: string, courseId: number) {
        const dataQuery: IUserSearchCourse = {
            name: search,
            courseId: courseId
        }
        const {data} = await api.post<IUser[]>(`/api/User/searchCourse`, dataQuery)
        return data
    },

    async updatePassword(dataPassword: IUserPasswordRepeat) {
        const {data} = await api.post<IUser>(`/api/User/updatePassword`, dataPassword)
        return data
    },

    async updateSupervisor(id: number) {
        const {data} = await api.post<IUser>(`/api/User/updateSupervisor/${id}`, {})
        return data
    },

    async excludeGroup(id: number) {
        const {data} = await api.post<IUser>(`/api/User/excludeGroup/${id}`, {})
        return data
    },

    async delete(data: IUserDetailId) {
        return await api.post<boolean>(`/api/User/delete/`, data)
    },

    async uploadAvatar(data: any) {
        const response = await api.post<IUser>(`/api/File/uploadAvatar/`, data, {
            headers: {'Content-Type': 'multipart/form-data'}
        })
        return response.data
    },

    async getCourses(id: number, sortField?: string, sortDirection?: string) {
        const params = {
            sortField: sortField,
            sortDirection: sortDirection
        }
        const cleanedParams = cleanParams(params)
        const queryString = new URLSearchParams(cleanedParams).toString()
        const {data} = await api.get<IUserCourses[]>(`/api/User/getCoursesByUser/${id}?${queryString}`)
        return data
    },

    async getListCoursesForAttach(page: number, search?: string) {
        const params = {
            page: page,
            search: search
        }
        const cleanedParams = cleanParams(params)
        const queryString = new URLSearchParams(cleanedParams).toString()
        const {data} = await api.get<ICoursesForAttach[]>(`/api/User/listCoursesForAttach?${queryString}`)
        return data
    },

    async searchCourseForAttach(dataSubmit: ICoursesSearchForAttach) {
        const {data} = await api.post<ICoursesForAttach[]>(`/api/User/searchCourseForAttach`, dataSubmit)
        return data
    },

    async updateGroup(dataUpdate: IUserGroup) {
        const {data} = await api.post<IUser>(`/api/User/updateGroup`, dataUpdate)
        return data
    },

    async getProgressCourse(id: number, courseId: number) {
        const {data} = await api.get<IProgressCourseUser>(`/api/User/progress/${id}/${courseId}`)
        return data
    },

    async getProgressCourseForLesson(id: number, courseId: number) {
        const {data} = await api.get<IProgressCourseUser>(`/api/User/progressForLesson/${id}/${courseId}`)
        return data
    },

    async updateLessonPassMark(id: number, dataUpdate: IUpdateLessonPassMark) {
        const {data} = await api.post<boolean>(`/api/User/updateLessonPassMark/${id}`, dataUpdate)
        return data
    },

    async getByPage(role: number | null, page: number, search: string | undefined, sortField: string, sortDirection: string) {
        const params = {
            search: search,
            role: role,
            page: page,
            sortField: sortField,
            sortDirection: sortDirection
        }
        const cleanedParams = cleanParams(params);
        const queryString = new URLSearchParams(cleanedParams).toString()
        const {data} = await api.get<IUser[]>(`/api/User/getByPage?${queryString}`)
        return data
    },

    async getQuestionsForPassingTest(testId: number, lessonId: number) {
        const {data} = await api.get<IUserPassingTest>(`/api/User/questionsPassing/${testId}/${lessonId}`)
        return data
    },

    async finishTest(id: number, testId: number, lessonId: number, courseId: number, dataSubmit: IPassingTest[]) {
        const {data} = await api.post<boolean>(`/api/User/finishTest/${id}/${testId}/${lessonId}/${courseId}`, dataSubmit)
        return data
    },

    async getResultTest(id: number, testId: number, lessonId: number) {
        const {data} = await api.get<IUserResultTest>(`/api/User/testResult/${id}/${testId}/${lessonId}`)
        return data
    },

    async untieCourse(dataSubmit: IUserCourseUntie) {
        const {data} = await api.post<boolean>(`/api/User/untieCourse/`, dataSubmit)
        return data
    },

    async getVisits(dataSubmit: IGetVisitsUser) {
        const {data} = await api.post<IVisitsUser[]>(`/api/User/getVisitsByUser/`, dataSubmit)
        return data
    },

    async attachUserInCourse(dataSubmit: IUserCourseAttach) {
        const {data} = await api.post<IUserCourseAttach>(`/api/User/attachUserInCourse/`, dataSubmit)
        return data
    },

    async attachUserInCourseWithLessons(dataSubmit: ILessonsAttachInUser) {
        const {data} = await api.post<boolean>(`/api/User/attachUserInCourseWithLessons/`, dataSubmit)
        return data
    },

    async downloadUps(dataSubmit: IUserCourseAttach) {
        const {data} = await api.post<string>(`/api/User/downloadUps/`, dataSubmit)
        return data
    },

    async recoveryPassword(dataSubmit: IRecoveryPassword) {
        const {data} = await api.post<IRecoveryPassword>(`/api/User/recoveryPassword/`, dataSubmit)
        return data
    },

    async getListUsersWithoutEducationId() {
        const {data} = await api.get<IUserWithoutEducationId[]>(`/api/User/getListUserWithoutEducationId/`)
        return data
    },

    async getListMethodistByCompany() {
        const {data} = await api.get<IUserMethodist[]>(`/api/User/getListMethodistByCompany/`)
        return data
    },

    async changeAccessForCourse(dataSubmit: IUserCourseAttach) {
        const {data} = await api.post<IUserCourses>(`/api/User/blockAccessForCourse/`, dataSubmit)
        return data
    }
}