import React, {ReactNode, useEffect, useState} from "react";
import {ReactComponent as ArrowSortIcon} from "../../../assets/images/global/arrow-sort-icon.svg";

interface IArrowSort {
    children: ReactNode
    className: string
    setSortDirection: (direction: "asc" | "desc") => void
    sortField: string
    setSortField: (field: string) => void
    activeSortField: string
}

const ArrowSort = (
    {
        children,
        className,
        setSortDirection,
        sortField,
        setSortField,
        activeSortField
    }: IArrowSort,
) => {
    const [sort, setSort] = useState<"asc" | "desc">("asc")

    const handleSort = () => {
        setSortField(sortField)
        setSortDirection(sort === "asc" ? "desc" : "asc")
        setSort(sort === "asc" ? "desc" : "asc")
    }

    return (
        <>
            <div className={`cursor-pointer flex gap-[4px] ${className}`}
                 onClick={handleSort}
            >
                {children}
                <ArrowSortIcon
                    className={`transition-all ${sort === "asc" ? "rotate-0" : "rotate-180"} ${activeSortField === sortField ? "fill-[#17AB57]" : "fill-[#828282]"}`}
                />
            </div>
        </>
    )
}

export default ArrowSort