import React, {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {TestsService} from "../../../../services/tests.service";
import {ITest} from "../../../../models/tests";
import TestsItem from "./tests-item/TestsItem";
import ErrorMessage from "../../../ui/error/ErrorMessage";
import ScrollContainer from "../../../ui/scroll-container/ScrollContainer";
import Search from "../../../ui/search/Search";
import {ReactComponent as ArrowScroll} from "../../../../assets/images/global/arrow.svg";
import {positionValues} from "react-custom-scrollbars-2";
import {Link} from "react-router-dom";
import ArrowSort from "../../../ui/arrow-sort/ArrowSort";
import {ReactComponent as PlusIcon} from "../../../../assets/images/global/plus-icon.svg";
import Loader from "../../../ui/loader/Loader";

const Tests = () => {

    const [filteredTests, setFilteredTests] = useState<ITest[]>([])
    const [error, setError] = useState<number | null>(null)
    const [page, setPage] = useState<number>(1)
    const [searchTerm, setSearchTerm] = useState<string>("")
    const [sortField, setSortField] = useState<string>('Test.Name')
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc")
    const [scrollIsBottom, setScrollIsBottom] = useState<boolean>(false)
    const [positionScroll, setPositionScroll] = useState<positionValues | null>(null)
    const [scrollToTop, setScrollToTop] = useState<boolean>(true)

    const {data: tests, isLoading, isError} = useQuery(
        ["tests", page, searchTerm, sortField, sortDirection],
        () => TestsService.getByPage(page, searchTerm, sortField, sortDirection),
        {
            onSuccess: (response) => {
                if (page === 1) {
                    setFilteredTests(response)
                } else {
                    response.map(test => {
                        setFilteredTests(prev => [...prev, test])
                    })
                }
            },
            keepPreviousData: true,
        }
    )

    useEffect(() => {
        setPage(1)
    }, [searchTerm, sortField, sortDirection]);

    useEffect(() => {
        if (scrollIsBottom) setPage(prev => prev + 1)
    }, [scrollIsBottom]);

    if (isLoading) return <Loader/>
    if (isError) return <ErrorMessage numberError={400}/>
    if (!tests) return <ErrorMessage numberError={400}/>

    return (
        <>
            {positionScroll && positionScroll.top <= 1 && positionScroll.top > 0 && (
                <div
                    onClick={() => setScrollToTop(!scrollToTop)}
                    className="absolute p-[16px] rounded-[49px] bg-white right-[10px] bottom-[20px] z-[50] cursor-pointer shadow-[0_0px_20px_0_rgba(0,0,0,0.15)]">
                    <div className="w-full h-full relative flex items-center justify-center">
                        <ArrowScroll className={`fill-black rotate-90`}/>
                    </div>
                </div>
            )}
            {error && <ErrorMessage numberError={error}/>}
            <ScrollContainer
                scrollToTop={scrollToTop}
                setPositionScroll={setPositionScroll}
                setScrollIsBottom={setScrollIsBottom}
                searchTerm={searchTerm}
                sortField={sortField}
                sortDirection={sortDirection}>
                <div className="flex flex-col gap-[32px] h-full max-tablet:gap-[28px]">
                    <div className="text-[36px] font-[700] text-[#292B2C] leading-[120%]">Поиск тестов</div>
                    <div className="w-full flex gap-[12px] items-center">
                        <Search setSearchTerm={setSearchTerm}/>
                        <Link
                            to={`/tests/add`}
                            className="bg-[#17AB57] text-white rounded-[12px] items-center flex gap-[6px] py-[16px] px-[20px] font-medium whitespace-nowrap justify-center">
                            <PlusIcon className="flex-shrink-0"/>
                            Создать тест
                        </Link>
                    </div>
                    <div className="flex flex-col gap-[12px]">
                        <div
                            className="bg-white p-[36px] rounded-[20px] flex flex-col text-[16px] h-full max-tablet:p-0 max-tablet:bg-[#F7F7F7]">
                            <div
                                className="flex text-[#828282] max-tablet:hidden pr-[16px] w-full border-solid border-b-[0.5px] border-[#BDBDBD] border-opacity-[0.3] pb-[20px]">
                                <ArrowSort className={`w-[50%]`} sortField={"Test.Name"}
                                           setSortField={setSortField} setSortDirection={setSortDirection}
                                           activeSortField={sortField}>
                                    Название
                                </ArrowSort>
                                <div className="w-[25%]">Дата создания</div>
                                <div className="w-[25%]">Дата изменения</div>
                                <div className="w-[74px] flex-shrink-0">Действия</div>
                            </div>
                            {filteredTests && filteredTests.map((test, key) => (
                                <TestsItem
                                    setError={setError}
                                    test={test}
                                    key={key}/>
                            ))}
                        </div>
                    </div>
                </div>
            </ScrollContainer>
        </>

    )
}

export default Tests