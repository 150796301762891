import Portal from "../portal/Portal";
import React, {useState} from "react";
import {SubmitHandler, useForm, Controller} from "react-hook-form";
import {ICourses, ICoursesFolder} from "../../../../models/courses";
import {AxiosError} from "axios";
import Select from "react-select";
import {FolderService} from "../../../../services/folder.service";
import {IFolderMove, IGroupedFolder} from "../../../../models/folders";
import {CoursesService} from "../../../../services/courses.service";
import Modal from "../Modal";
import {ReactComponent as CloseIcon} from "../../../../assets/images/global/close.svg";
import {useMutation, useQuery} from "react-query";
import ErrorMessage from "../../error/ErrorMessage";

interface MoveCourse {
    course: ICourses
    onClose: () => void
    removeCourse: (id: number) => void
}

const ModalMoveCourse = (
    {
        course,
        onClose,
        removeCourse
    }: MoveCourse
) => {

    const [error, setError] = useState<number | null>(null)
    const [groupFolder, setGroupFolder] = useState<IGroupedFolder[]>([])

    const {
        setValue,
        register,
        handleSubmit,
        control,
    } = useForm<ICoursesFolder>()

    const listFolders = useQuery(
        ["list_folders"],
        () => FolderService.getList(),
        {
            onSuccess: (response) => {
                setValue("id", course.id)
                response.forEach(element => {
                    let first = element[0]
                    let options: IFolderMove[] = []
                    element.map(el => {
                        options.push(
                            {
                                id: Number(el.id),
                                name: el.name
                            }
                        )
                    })
                    setGroupFolder(prev => [...prev, {
                        label: first?.name,
                        options: options
                    }])
                })
            }
        }
    )

    const moveCourse = useMutation(
        (data: ICoursesFolder) => CoursesService.move(data),
        {
            onSuccess: () => {
                removeCourse(course.id)
                onClose()
            },
            onError: (error) => {
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const onSubmit: SubmitHandler<ICoursesFolder> = async (data) => {
        moveCourse.mutate(data)
    }

    if(listFolders.isError) {
        const e = listFolders.error as AxiosError
        setError(e.response ? e.response.status : 400)
    }

    return (
        <Portal>
            <Modal>
                {error && <ErrorMessage numberError={error}/>}
                <form className="flex flex-col gap-[24px] text-[16px]" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex justify-between items-center font-bold text-[16px]">
                        Перемещение курса
                        <CloseIcon className="cursor-pointer" onClick={onClose}/>
                    </div>
                    <div>
                        <Controller
                            control={control}
                            name={"folder"}
                            rules={{
                                required: true
                            }}
                            render={({field: {onChange, value}, fieldState: {error}}) =>
                                <>
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            colors: {
                                                ...theme.colors,
                                                primary: "#17AB57",
                                                primary25: "#17AB571a"
                                            }
                                        })}
                                        placeholder={"Выберите папку"}
                                        options={groupFolder}
                                        onChange={onChange}
                                        value={value}
                                        isSearchable={true}
                                        noOptionsMessage={() => "Не создано папок"}
                                        getOptionLabel={(folder: IFolderMove) => folder.name}
                                        getOptionValue={(folder: IFolderMove) => String(folder.id)}
                                    />
                                    {error && (
                                        <p className="text-red-600">Выберите папку</p>
                                    )}
                                </>
                            }
                        />
                        <input type="hidden" {...register("id")}/>
                    </div>
                    <div className="flex gap-[12px] justify-end">
                        <button
                            type="submit"
                            className="text-white font-medium py-[8px] px-[16px] rounded-[5px] bg-[#17AB57]"
                        >
                            Переместить
                        </button>
                        <button
                            className="font-medium py-2 px-4 rounded-[5px] border-[1px] border-solid border-[#17AB57]"
                            onClick={onClose}>Отмена
                        </button>
                    </div>
                </form>
            </Modal>
        </Portal>
    )
}

export default ModalMoveCourse