import dayjs from "dayjs";
import {Link} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {ISearchLesson} from "../../../../../models/lessons";
import {ReactComponent as ActionIcon} from "../../../../../assets/images/global/action-icon-horizontal.svg";
import {ReactComponent as PreviewIcon} from "../../../../../assets/images/courses/preview-icon.svg";

interface ILessonItem {
    lesson: ISearchLesson
}

const LessonItem = (
    {
        lesson
    }: ILessonItem
) => {

    const ref = useRef<HTMLDivElement>(null)
    const [showMenu, setShowMenu] = useState<boolean>(false)

    const handleClick = () => {
        setShowMenu(!showMenu)
    }

    useEffect(() => {
        if (!showMenu) return
        handleClickRef(ref, handleClick)
    }, [showMenu])

    const handleClickRef = (ref: React.RefObject<HTMLDivElement>, handleSet: () => void) => {
        const handleClick = (e: MouseEvent) => {
            let element = e.target as HTMLDivElement
            if (!ref.current) return
            if (!ref.current.contains(element)) {
                handleSet()
            }
        }

        document.addEventListener('click', handleClick)
        return () => {
            document.removeEventListener('click', handleClick)
        }
    }

    return (
        <div className="flex items-center px-[18px] py-[20px] border-solid border-b-[2px] border-[#BDBDBD] last:border-none max-lg:py-[12px] max-lg:px-0 max-lg:flex-col gap-[15px]">
            <div className="w-[30%] max-lg:w-full">
                {lesson.name}
            </div>
            <div className="w-[30%] max-lg:w-full">
                <Link target="_blank" to={`/folders/courses/${lesson.courseId}`} className="underline">
                    {lesson.courseName}
                </Link>
            </div>
            <div className="w-[15%] max-lg:w-full">
                {dayjs(lesson.createdDate).format("DD.MM.YYYY HH:mm")}
            </div>
            <div className="w-[15%] max-lg:w-full">
                {dayjs(lesson.updatedDate).format("DD.MM.YYYY HH:mm")}
            </div>
            <div className="w-[10%] flex justify-end max-lg:w-full">
                <div ref={ref} className="w-[6%] flex justify-end relative">
                    <ActionIcon className="flex-shrink-0 cursor-pointer" onClick={handleClick}/>
                    <div
                        className={`absolute right-[10px] bg-white rounded-[6px] overflow-hidden shadow-[0_5px_10px_0_rgba(0,0,0,0.37)] z-[90] top-[15px]` + (showMenu ? " block" : " hidden")}>
                        <Link
                            target="_blank"
                            to={`/folders/preview-lesson/${lesson.id}`}
                            className="px-[12px] py-[10px] flex gap-[12px] items-center hover:bg-[#17AB571a] cursor-pointer">
                            <PreviewIcon/>
                            Предпросмотр
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LessonItem