import Modal from "../Modal";
import {ReactComponent as CloseIcon} from "../../../../assets/images/global/close.svg";
import Portal from "../portal/Portal";
import React, {useState} from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {ILessonsUpdateHours, IListLessonsByCourse} from "../../../../models/lessons";
import {LessonsService} from "../../../../services/lessons.service";
import Loader from "../../loader/Loader";
import {AxiosError} from "axios";
import ErrorMessage from "../../error/ErrorMessage";
import {useFieldArray, useForm} from "react-hook-form";
import ScrollContainer from "../../scroll-container/ScrollContainer";

interface IChangeHours {
    onClose: () => void
    courseId: number
    setIsModalOpen: (flag: boolean) => void
}

type FormValues = {
    cart: ILessonsUpdateHours[];
};

const ChangeHours = (
    {
        courseId,
        onClose,
        setIsModalOpen
    }: IChangeHours
) => {

    const queryClient = useQueryClient()
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [text, setText] = useState<string>("")
    const [error, setError] = useState<boolean>(false)

    const {
        register,
        handleSubmit,
        control,
        formState: {errors}
    } = useForm<FormValues>()

    const {
        fields, append, remove
    } = useFieldArray({
        name: "cart",
        control
    })

    const onSubmit = (data: FormValues) => {
        setShowLoader(true)
        updateHours.mutate(data.cart)
    };

    const lessons = useQuery<IListLessonsByCourse>(
        ['list_lessons_course_change_hours', Number(courseId)],
        () => LessonsService.getByCourse(Number(courseId)),
        {
            onSuccess: (response) => {
                remove()
                response.lessons.map(item => {
                    append({
                        id: item.id,
                        name: item.name,
                        hours: item.hours
                    })
                })
            }
        }
    )

    const updateHours = useMutation(
        (data: ILessonsUpdateHours[]) => LessonsService.updateHours(Number(courseId), data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["course_detail", courseId])
                setShowLoader(false)
                setText("Часы сохранены")
            },
            onError: () => {
                setError(true)
                setText("Произошла ошибка")
            }
        }
    )

    const handleClose = () => {
        onClose()
        setIsModalOpen(false)
    }

    if (lessons.isLoading) return <Loader/>

    if (lessons.isError) {
        const e = lessons.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    if (!lessons.data) {
        return <div className="text-center">Данные отсутствуют</div>
    }

    return (
        <>
            {showLoader && <Loader/>}
            <Portal>
                <Modal>
                    <div className="h-[700px]">
                        <form className="flex flex-col gap-[24px] text-[16px] h-full" onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex justify-between items-center font-bold text-[16px]">
                                Массовая замена часов у курса
                                <CloseIcon className="cursor-pointer" onClick={handleClose}/>
                            </div>
                            {text && (
                                <div className={`text-center ` + (error ? "text-red-600" : "text-[#17AB57]")}>{text}</div>
                            )}
                            <div className="h-full">
                                <ScrollContainer chat={false}>
                                    <div className="flex flex-col gap-[12px]">
                                        {fields.map((item, index) => (
                                            <div key={item.id} className="w-full flex items-center">
                                                <div className="w-[50%]">{index + 1}. {item.name}</div>
                                                <div className="w-[50%]">
                                                    <input type="hidden" {...register(`cart.${index}.id` as const)}/>
                                                    <input
                                                        type="number"
                                                        className="py-[8px] pl-[12px] pr-[10px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[5px]"
                                                        {...register(`cart.${index}.hours` as const, {required: true})}/>
                                                    {errors?.cart?.[index]?.hours && (
                                                        <p className="text-red-600">Укажите часы</p>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </ScrollContainer>
                            </div>
                            <div className="flex justify-end">
                                <button
                                    type="submit"
                                    className="py-[8px] px-[16px] bg-[#17AB57] rounded-[5px] text-white text-[16px] font-medium">
                                    Сохранить
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </Portal>
        </>
    )
}

export default ChangeHours