import Modal from "../Modal"
import Portal from "../portal/Portal"
import {ReactComponent as CloseIcon} from "../../../../assets/images/global/close.svg"
import {useNavigate} from "react-router-dom";

interface IUndoChanges {
    onClose: () => void
    link: string
}

const UndoChanges = (
    {
        onClose,
        link
    }: IUndoChanges
) => {

    const navigate = useNavigate()

    return (
        <Portal>
            <Modal>
                <div className="flex flex-col gap-[28px]">
                    <div className="relative flex justify-center text-[24px] max-tablet:text-[20px] leading-[120%] font-[500]">
                        Внимание!
                        <CloseIcon className="cursor-pointer fill-[#000] w-[24px] h-[24px] absolute top-[3px] right-0" onClick={onClose}/>
                    </div>
                    <div className="flex items-center text-center text-[16px] leading-[120%] font-[400]">
                        Ваши данные не были сохранены. Если вы продолжите, все несохраненные изменения будут потеряны.
                        <br/><br/>
                        Хотите продолжить?
                    </div>
                    <div className="flex max-tablet:flex-col gap-[12px] text-[16px]">
                        <div
                            onClick={() => navigate(link)}
                            className="w-full py-[16px] px-[20px] rounded-[12px] flex justify-center items-center text-[#292B2C] font-[500] bg-[#bdbdbd4d] cursor-pointer">
                            Выйти без сохранения
                        </div>
                        <div
                            onClick={onClose}
                            className="w-full py-[16px] px-[20px] rounded-[12px] flex justify-center items-center text-white font-[500] bg-[#17AB57] cursor-pointer">
                            Вернуться
                        </div>
                    </div>
                </div>
            </Modal>
        </Portal>
    )
}

export default UndoChanges