import {
    IUser,
    IUserDetail,
    IUserDetailId
} from "../../../../../../models/users";
import React, {useContext, useEffect, useState} from "react";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {UsersService} from "../../../../../../services/users.service";
import {AxiosError} from "axios";
import ErrorMessage from "../../../../../ui/error/ErrorMessage";
import {Constants} from "../../../../../../constans/constants";
import {useNavigate, useParams} from "react-router-dom";
import useToggle from "../../../../../../hooks/useToggle";
import ModalPassword from "../../../../../ui/modal/modal-password/ModalPassword";
import Loader from "../../../../../ui/loader/Loader";
import {AuthContext} from "../../../../../../context/AuthContext";
import MethodistSelect from "../../../../../ui/user/methodist-select/MethodistSelect";
import RoleSelect from "../../../../../ui/user/role-select/RoleSelect";
import ResetSubmit from "../../../../../ui/reset-submit/ResetSubmit";
import {ShowBx24Id} from "../../../../../../helpers/WorkWithDomain";

interface IUserMainInformationProps {
    user: IUser
}

const UserMainInformation = (
    {
        user
    }: IUserMainInformationProps
) => {

    const methods = useForm<IUserDetail>()
    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors},
    } = methods

    const {id} = useParams()

    const [error, setError] = useState<number | null>(null)
    const [show, setShow] = useState<boolean>(false)
    const [isModalPassword, setOnModalOpenPassword, setOnModalClosePassword] = useToggle()
    const {currentRoleId, currentUserId} = useContext(AuthContext)
    const [isAdmin, setIsAdmin] = useState<boolean>(false)

    const navigate = useNavigate()
    const queryClient = useQueryClient()

    useEffect(() => {
        setValue("lastName", user.lastName)
        setValue("fatherName", user.fatherName)
        setValue("firstName", user.firstName)
        setValue("email", user.email)
        setValue("password", user.password)
        setValue("dealId", user.dealId)
        setValue("bx24Id", user.bx24Id)
        setIsAdmin(Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1)
    }, [user]);

    const updateUser = useMutation(
        (data: IUserDetail) => UsersService.update(user ? user.id : 0, data),
        {
            onSuccess: (response) => {
                queryClient.invalidateQueries(["user", id])
                queryClient.invalidateQueries(["current_user", id])
                setShow(false)
            },
            onError: (errorUpdate) => {
                const e = errorUpdate as AxiosError
                setError(e.response ? e.response.status : 400)
                setShow(false)
            }
        }
    )

    const onSubmit: SubmitHandler<IUserDetail> = async (data) => {
        setShow(true)
        updateUser.mutate(data)
    }

    const deleteUser = useMutation(
        (data: IUserDetailId) => UsersService.delete(data),
        {
            onSuccess: () => {
                setShow(false)
                queryClient.invalidateQueries(["users"])
                navigate("/users")
            },
            onError: (errorDelete) => {
                setShow(false)
                const e = errorDelete as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const handleDelete = () => {
        if (user) {
            let deleteSuccess = window.confirm("Вы действительно хотите удалить пользователя? Это действие необратимо")
            if (deleteSuccess) {
                setShow(true)
                const data = {
                    "id": user.id
                }
                deleteUser.mutate(data)
            }
        }
    }

    // const onClearChat = () => {
    //     if (user) {
    //         let deleteSuccess = window.confirm("Вы действительно хотите очистить чат с пользователем? Это действие необратимо")
    //         if (deleteSuccess) {
    //             setShow(true)
    //             clearChat.mutate()
    //         }
    //     }
    // }
    //
    // const clearChat = useMutation(
    //     () => ChatService.clearChat(user?.id),
    //     {
    //         onSuccess: (response) => {
    //             setShow(false)
    //         },
    //         onError: (error) => {
    //             setShow(false)
    //         }
    //     }
    // )

    // const onCreateChat = () => {
    //     if (user) {
    //         setShow(true)
    //         const data: ICreateChat = {
    //             studentId: user.id,
    //             methodistId: Number(currentUserId)
    //         }
    //         createChat.mutate(data)
    //     }
    // }

    // const createChat = useMutation(
    //     (data: ICreateChat) => ChatService.createChat(data),
    //     {
    //         onSuccess: (response) => {
    //             setShow(false)
    //             setShowCreateChat(false)
    //         },
    //         onError: (error) => {
    //             setShow(false)
    //             const e = error as AxiosError
    //             setError(e.response ? e.response.status : 400)
    //         }
    //     }
    // )

    // const checkExistChat = useQuery(
    //     ["exist_chat", user?.id],
    //     () => ChatService.checkExistChat(userId),
    //     {
    //         enabled: !!user,
    //         onSuccess: (response) => {
    //             if (response === 0) setShowCreateChat(true)
    //             setShow(false)
    //         },
    //         onError: (error) => {
    //             setShow(false)
    //             const e = error as AxiosError
    //             setError(e.response ? e.response.status : 400)
    //         }
    //     }
    // )
    //
    // const handleChangeDeal = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     if (Number(e.target.value) < 1) {
    //         setValue(`dealId`, Math.abs(Number(e.target.value)))
    //     }
    // }

    // if (userLoading) return <Loader/>
    // if (userError) return <ErrorMessage numberError={400}/>
    // if (!user) return <ErrorMessage numberError={400}/>

    const handleReset = () => {
        setValue("lastName", user.lastName)
        setValue("fatherName", user.fatherName)
        setValue("firstName", user.firstName)
        setValue("email", user.email)
        setValue("password", user.password)
        setValue("dealId", user.dealId)
        setValue("bx24Id", user.bx24Id)
        setValue("role", user.role)
        setValue("methodist", user.methodist)
    }

    return (
        <>
            {show && <Loader/>}
            {error && <ErrorMessage numberError={error}/>}
            {isModalPassword && user &&
                <ModalPassword
                    user={user}
                    onClose={setOnModalClosePassword}/>}
            <div className="w-full h-full">
                <FormProvider {...methods}>
                    <form className="flex gap-[20px] max-tablet:flex-col max-tablet:gap-[16px]"
                          onSubmit={handleSubmit(onSubmit)}>
                        <div
                            className="p-[36px] rounded-[20px] bg-white w-[calc(100%-10px)] max-tablet:w-full flex flex-col gap-[36px] max-tablet:gap-[24px] max-tablet:p-[24px]">
                            <div className="flex flex-col gap-[20px] max-tablet:gap-[16px]">
                                <div className="text-[#292B2C] text-[20px] font-[500]">Персональные данные</div>
                                <div
                                    className={`flex gap-[12px] w-full ${isAdmin ? "flex-col" : "flex-row max-tablet:flex-col"}`}>
                                    <div
                                        className={`flex flex-col gap-[4px] ${isAdmin ? "w-full" : "w-[calc(100%/3-8px)] max-tablet:w-full"}`}>
                                        <div className="text-[16px] text-[#828282]">Фамилия</div>
                                        <input
                                            className="p-[16px] border-[1px] border-solid border-[#BDBDBD] rounded-[12px]"
                                            placeholder="Введите фамилию"
                                            {...register("lastName", {required: true})}/>
                                        {errors.lastName && errors.lastName.type === "required" && (
                                            <p className="text-red-600">ФИО обязательно</p>
                                        )}
                                    </div>
                                    <div
                                        className={`flex flex-col gap-[4px] ${isAdmin ? "w-full" : "w-[calc(100%/3-8px)] max-tablet:w-full"}`}>
                                        <div className="text-[16px] text-[#828282]">Имя</div>
                                        <input
                                            className="p-[16px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[12px]"
                                            placeholder="Введите имя"
                                            {...register("firstName", {required: true})}/>
                                        {errors.firstName && errors.firstName.type === "required" && (
                                            <p className="text-red-600">Имя обязательно</p>
                                        )}
                                    </div>
                                    <div
                                        className={`flex flex-col gap-[4px] ${isAdmin ? "w-full" : "w-[calc(100%/3-8px)] max-tablet:w-full"}`}>
                                        <div className="text-[16px] text-[#828282]">Отчество</div>
                                        <input
                                            className="p-[16px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[12px]"
                                            placeholder="Введите отчество"
                                            {...register("fatherName")}/>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-[20px] max-tablet:gap-[16px]">
                                <div className="text-[#292B2C] text-[20px] font-[500]">Данные аккаунта</div>
                                <div className="flex gap-[12px] flex-col">
                                    <div className="flex flex-col gap-[4px] w-full">
                                        <div className="text-[16px] text-[#828282]">Почта</div>
                                        <input
                                            type="email"
                                            className="p-[16px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[12px]"
                                            placeholder="Введите почту"
                                            {...register("email", {required: true})}/>
                                        {errors.email && errors.email.type === "required" && (
                                            <p className="text-red-600">Почта обязательна</p>
                                        )}
                                    </div>
                                    {isAdmin && (
                                        <>
                                            <div
                                                onClick={setOnModalOpenPassword}
                                                className="flex items-center justify-center border-solid border-[1px] border-[#17AB57] w-full rounded-[12px] text-[16px] text-[#17AB57] font-[500] py-[16px] px-[20px] cursor-pointer">
                                                Сменить пароль
                                            </div>
                                            <div
                                                onClick={handleDelete}
                                                className="flex items-center justify-center border-solid border-[1px] border-[#EB5757] w-full rounded-[12px] text-[16px] text-[#EB5757] font-[500] py-[16px] px-[20px] cursor-pointer">
                                                Удалить пользователя
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        {isAdmin && (
                            <div
                                className="p-[36px] rounded-[20px] bg-white w-[calc(100%-10px)] max-tablet:w-full flex flex-col justify-between max-tablet:gap-[36px]">
                                <div className="flex flex-col gap-[20px]">
                                    <div className="text-[#292B2C] text-[20px] font-[500]">Дополнительно</div>
                                    <div className="flex flex-col gap-[12px] w-full">
                                        <div className="flex flex-col gap-[4px]">
                                            <div className="text-[16px] text-[#828282]">Сделка</div>
                                            <input
                                                type="number"
                                                className="p-[16px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[12px] h-[54px]"
                                                placeholder="Введите номер сделки"
                                                {...register("dealId", {valueAsNumber: true})}/>
                                        </div>
                                        <MethodistSelect user={user}/>
                                        <RoleSelect user={user}/>
                                        {ShowBx24Id() && (
                                            <div className="flex flex-col gap-[4px]">
                                                <div className="text-[16px] text-[#828282]">Bx24 ID</div>
                                                <input
                                                    type="number"
                                                    className="p-[16px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[12px] h-[54px]"
                                                    placeholder="Введите Id пользователя в Bx24"
                                                    {...register("bx24Id", {valueAsNumber: true})}/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className="pt-[36px] border-solid border-t-[0.5px] border-[#BDBDBD] flex gap-[12px] w-full max-tablet:flex-col">
                                    <ResetSubmit actionReset={handleReset}/>
                                </div>
                            </div>
                        )}
                    </form>
                </FormProvider>
            </div>
        </>
    )
}

export default UserMainInformation