import {IProgressCourseLessonsUser, IProgressCourseUser} from "../../../../../models/users";
import {ReactComponent as MarkSuccessLesson} from "../../../../../assets/images/global/mark-success.svg";
import {ReactComponent as TestIcon} from "../../../../../assets/images/users/test-icon.svg";
import {ReactComponent as LectureIcon} from "../../../../../assets/images/users/lecture-icon.svg";
import {ReactComponent as ResultTest} from "../../../../../assets/images/users/result-test.svg";
import {ReactComponent as ReloadCourse} from "../../../../../assets/images/users/reload.svg";
import {ReactComponent as ContinueCourse} from "../../../../../assets/images/users/continue.svg";
import {ReactComponent as CloseLesson} from "../../../../../assets/images/users/close-lesson.svg";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

interface IStudentCourseLessonsItem {
    index: number
    progress: IProgressCourseUser
    lesson: IProgressCourseLessonsUser
}

const StudentCourseListLessonsItem = (
    {
        index,
        progress,
        lesson
    }: IStudentCourseLessonsItem
) => {

    const [colorBorderBefore, setColorBorderBefore] = useState<string>("before:border-[#BDBDBD]")
    const [colorBorderAfter, setColorBorderAfter] = useState<string>("after:border-[#BDBDBD]")
    const [colorBorderLesson, setColorBorderLesson] = useState<string>("border-[#BDBDBD]")

    useEffect(() => {
        if(lesson && progress) {
            if (progress.lessons[index - 1]) {
                if (progress.lessons[index - 1].passMark) {
                    if (lesson.testId) {
                        if (lesson.passMark) {
                            setColorBorderBefore("before:border-[#219653]")
                        } else if (lesson.percentPass !== null && lesson.percentPass !== 0 && lesson.markUp && Number(lesson.percentPass) < lesson.markUp) {
                            setColorBorderBefore("before:border-[#EB5757]")
                        } else {
                            setColorBorderBefore("before:border-[#2F80ED]")
                        }
                    } else {
                        setColorBorderBefore("before:border-[#2F80ED]")
                        if (lesson.passMark)
                            setColorBorderBefore("before:border-[#219653]")
                    }
                }
            }

            if (progress.lessons[index + 1]) {
                if (lesson.passMark) {
                    if (progress.lessons[index + 1].testId) {
                        const percent = progress.lessons[index + 1].percentPass
                        const markUp = progress.lessons[index + 1].markUp
                        if (progress.lessons[index + 1].passMark) {
                            setColorBorderAfter("after:border-[#219653]")
                        } else if (percent !== null && percent !== 0 && markUp && Number(percent) < markUp) {
                            setColorBorderAfter("after:border-[#EB5757]")
                        } else {
                            setColorBorderAfter("after:border-[#2F80ED]")
                        }
                    } else {
                        setColorBorderAfter("after:border-[#2F80ED]")
                        if (progress.lessons[index + 1].passMark) {
                            setColorBorderAfter("after:border-[#219653]")
                        }
                    }
                }
            }

            if (lesson.passMark) {
                setColorBorderLesson("border-[#219653]")
            } else if (lesson.testId) {
                if(progress.lessons[index - 1].passMark)
                    setColorBorderLesson("border-[#2F80ED]")
                if(lesson.percentPass !== null && lesson.percentPass !== 0 && lesson.markUp && Number(lesson.percentPass) < lesson.markUp)
                    setColorBorderLesson("border-[#EB5757]")
            } else if (progress.lessons[0] == lesson || progress.lessons[index - 1].passMark) {
                setColorBorderLesson("border-[#2F80ED]")
            }
        }
    }, [lesson, progress])

    return (
        <div
            className={`flex gap-[16px] items-center group relative max-lg:before:content-none max-lg:after:content-none
            first:before:content-none before:content-[''] before:absolute before:top-[2px] before:left-[41px] before:h-[calc((100%-50px)/2)] before:border-dashed before:border-[1px] ${colorBorderBefore}
            last:after:content-none after:content-[''] after:absolute after:bottom-0 after:left-[41px] after:h-[calc((100%-48px)/2)] after:border-dashed after:border-[1px] ${colorBorderAfter}`}>
            <div className="pl-[18px] py-[20px] max-lg:hidden">
                <div
                    className={`w-[48px] h-[48px] rounded-[42px] border-[1px] border-solid flex justify-center flex-shrink-0 items-center ${colorBorderLesson}`}>
                    {lesson.passMark ? (
                        <>
                            <MarkSuccessLesson className="flex-shrink-0"/>
                        </>
                    ) : (
                        <>
                            {lesson.testId ? (
                                <>
                                    <TestIcon className="flex-shrink-0"
                                              fill={lesson.percentPass !== null && lesson.markUp && lesson.percentPass !== 0 && Number(lesson.percentPass) < lesson.markUp ? "#EB5757" :  (progress.lessons[index - 1].passMark ? "#2F80ED" : "#BDBDBD")}/>
                                </>
                            ) : (
                                <>
                                    <LectureIcon className="flex-shrink-0"
                                                 fill={(progress.lessons[0] == lesson || progress.lessons[index - 1].passMark) ? "#2F80ED" : "#BDBDBD"}/>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
            <div
                className="pr-[18px] py-[20px] border-b-[2px] border-solid border-[#BDBDBD] w-full flex items-center group-last:border-none max-lg:flex-col max-lg:gap-[16px]">
                <div className="w-[40%] gap-[6px] flex flex-col text-[16px] leading-[120%] max-lg:w-full max-lg:gap-[16px] max-lg:flex-row max-lg:items-center">
                    <div
                        className={`hidden flex-shrink-0 w-[48px] h-[48px] rounded-[42px] border-[1px] border-solid max-lg:flex justify-center items-center ${colorBorderLesson}`}>
                        {lesson.passMark ? (
                            <>
                                <MarkSuccessLesson className="flex-shrink-0"/>
                            </>
                        ) : (
                            <>
                                {lesson.testId ? (
                                    <>
                                        <TestIcon className="flex-shrink-0"
                                                  fill={lesson.percentPass !== null && lesson.markFrom && Number(lesson.percentPass) < lesson.markFrom ? "#EB5757" : "#BDBDBD"}/>
                                    </>
                                ) : (
                                    <>
                                        <LectureIcon className="flex-shrink-0"
                                                     fill={(progress.lessons[0] == lesson || progress.lessons[index - 1].passMark) ? "#2F80ED" : "#BDBDBD"}/>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div className="">
                        <div className="text-[#292B2C]">{lesson.name}</div>
                        <div className="text-[#BDBDBD]">{lesson.testId ? (
                            <>
                                {lesson == progress.lessons[progress.lessons.length - 1] ? (
                                    <>Итоговый тест</>
                                ) : (
                                    <>Тест</>
                                )}
                            </>
                        ) : (
                            <>Лекция</>
                        )}
                        </div>
                    </div>
                </div>
                {lesson.testId ? (
                    <>
                        {lesson.passMark ? (
                            <div className="w-[20%] flex justify-center text-[#219653] max-lg:w-full max-lg:justify-start">
                                Сдано {lesson.countCorrectQuestions}/{lesson.countGeneralQuestions} ({lesson.percentPass}%)
                            </div>
                        ) : (
                            <>
                                {progress.lessons[index - 1].passMark && lesson.percentPass !== null && lesson.markFrom && Number(lesson.percentPass) < lesson.markFrom ? (
                                    // <>
                                    //     {lesson.markUp && lesson.percentPass < lesson.markUp ? (
                                    //         <div className="w-[20%] flex justify-center text-[#EB5757] max-lg:w-full max-lg:justify-start">
                                    //             Не сдано {lesson.countCorrectQuestions}/{lesson.countGeneralQuestions} ({lesson.percentPass}%)
                                    //         </div>
                                    //     ) : (
                                    //         <div className="w-[20%] flex justify-center text-[#292B2C] max-lg:w-full max-lg:hidden">
                                    //             В процессе
                                    //         </div>
                                    //     )}
                                    // </>
                                    <div className="w-[20%] flex justify-center text-[#EB5757] max-lg:w-full max-lg:justify-start">
                                        Не сдано {lesson.countCorrectQuestions}/{lesson.countGeneralQuestions} ({lesson.percentPass}%)
                                    </div>
                                ) : (
                                    <>
                                        {progress.lessons[0] == lesson || progress.lessons[index - 1].passMark ? (
                                            <div className="w-[20%] flex justify-center text-[#292B2C] max-lg:w-full max-lg:hidden">
                                                В процессе
                                            </div>
                                        ) : (
                                            <div className="w-[20%] flex justify-center text-[#292B2C] max-lg:w-full max-lg:hidden"></div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {lesson.passMark ? (
                            <div className="w-[20%] flex justify-center text-[#219653] max-lg:w-full max-lg:justify-start">
                                Пройден
                            </div>
                        ) : (
                            <>
                                {(progress.lessons[0] == lesson || progress.lessons[index - 1].passMark) ? (
                                    <div className="w-[20%] flex justify-center text-[#2F80ED] max-lg:w-full max-lg:justify-start">
                                        В процессе
                                    </div>
                                ) : (
                                    <div className="w-[20%] flex justify-center text-[#292B2C] max-lg:w-full max-lg:hidden"></div>
                                )}
                            </>
                        )}
                    </>
                )}

                <div
                    className={`w-[20%] flex justify-center items-center text-[#292B2C] text-[16px]  ` + (lesson.testId && lesson.percentPass !== null && lesson.markFrom && Number(lesson.percentPass) < lesson.markFrom ? "max-lg:w-full" : "max-lg:hidden")}>
                    {lesson.testId && (
                        <>
                            {progress.lessons[index - 1].passMark && lesson.percentPass !== null ? (
                                <Link
                                    to={`/student-courses/${progress.id}/lesson/${lesson.id}/test-view-result/${lesson.testId}`}
                                    className="flex items-center gap-[6px] text-[#2D9CDB] underline"
                                >
                                    <ResultTest fill="#2D9CDB" className="flex-shrink-0"/>
                                    Результаты теста
                                </Link>
                            ) : (
                                <></>
                            )}
                        </>
                    )}
                </div>
                <div className="w-[20%] flex justify-end max-lg:w-full max-lg:justify-center">
                    {lesson.passMark ? (
                        <Link
                            to={`/student-courses/${progress.id}/lesson/${lesson.id}` + (lesson.testId ? `/test-view-result/${lesson.testId}` : ``)}
                            className="w-[240px] justify-center flex items-center gap-[6px] rounded-[5px] border-solid border-[1px] border-[#17AB57] py-[8px] pl-[12px] pr-[16px] max-lg:w-full whitespace-nowrap">
                            <ReloadCourse className="flex-shrink-0"/>
                            {lesson.testId ? ("Посмотреть результат") : ("Пройти снова")}
                        </Link>
                    ) : (
                        <>
                            {(lesson == progress.lessons[0] || progress.lessons[index - 1].passMark) ? (
                                <Link
                                    // to={lesson.testId ? `/student-courses/${progress.id}/test/${lesson.testId}` : `/student-courses/${progress.id}/lesson/${lesson.id}`}
                                    to={`/student-courses/${progress.id}/lesson/${lesson.id}` + (lesson.testId ? `/test-passing/${lesson.testId}` : ``)}
                                    className="w-[240px] justify-center flex items-center gap-[6px] rounded-[5px] bg-[#17AB57] py-[8px] pl-[12px] pr-[16px] text-white max-lg:w-full">
                                    <ContinueCourse className="flex-shrink-0"/>
                                    {lesson.testId && lesson.percentPass !== null && lesson.markFrom && Number(lesson.percentPass) < lesson.markFrom ? "Пересдать" : "Продолжить"}
                                </Link>
                            ) : (
                                <div className="w-[240px] flex items-center gap-[6px] text-[#BDBDBD]">
                                    <CloseLesson className="flex-shrink-0"/>
                                    Откроется после прохождения предыдущих
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default StudentCourseListLessonsItem
