import {useMutation} from "react-query";
import {ICourses, ICoursesDetailId} from "../../../../../models/courses";
import {CoursesService} from "../../../../../services/courses.service";
import {AxiosError} from "axios";
import Loader from "../../../../ui/loader/Loader";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import {ReactComponent as CoursesIcon} from "../../../../../assets/images/courses/courses-icon.svg";
import {ReactComponent as DeleteIcon} from "../../../../../assets/images/users/delete-course.svg";
import {ReactComponent as RestoreIcon} from "../../../../../assets/images/global/download.svg";
import React, {useContext, useState} from "react";
import ErrorMessage from "../../../../ui/error/ErrorMessage";
import {Constants} from "../../../../../constans/constants";
import {AuthContext} from "../../../../../context/AuthContext";

interface IItemCourseArchive {
    course: ICourses
    removeCourse: (id: number) => void
}

const ItemCourseArchive = (
    {
        course,
        removeCourse
    }: IItemCourseArchive
) => {

    const {currentRoleId} = useContext(AuthContext)
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [error, setError] = useState<number | null>(null)

    const deleteCourse = useMutation(
        (data: ICoursesDetailId) => CoursesService.delete(data),
        {
            onSuccess: () => {
                setShowLoader(false)
                removeCourse(course.id)
            },
            onError: (errorCopy) => {
                setShowLoader(false)
                const e = errorCopy as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const restoreArchiveCourse = useMutation(
        (data: ICoursesDetailId) => CoursesService.changeArchiveCourse(data),
        {
            onSuccess: () => {
                setShowLoader(false)
                removeCourse(course.id)
            },
            onError: (errorCopy) => {
                setShowLoader(false)
                const e = errorCopy as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const handleAction = (action: string) => {
        let actionSuccess = false
        switch (action) {
            case "restore":
                actionSuccess = window.confirm("Вы действительно хотите восстановить курс?")
                if(actionSuccess) {
                    setShowLoader(true)
                    let data = {
                        id: course.id,
                    }
                    restoreArchiveCourse.mutate(data)
                }
                break;
            case "delete":
                actionSuccess = window.confirm("Вы действительно хотите удалить курс? Это действие необратимо")
                if(actionSuccess) {
                    setShowLoader(true)
                    let data = {
                        id: course.id,
                    }
                    deleteCourse.mutate(data)
                }
                break;
            default: return;
        }
    }

    return (
        <div
            className="px-[18px] py-[20px] hover:bg-[#17AB571a] flex justify-between items-center max-lg:p-0 border-b-[2px] border-solid border-[#BDBDBD] last:border-0 max-lg:py-[12px]">
            {showLoader && <Loader/>}
            {error && <ErrorMessage numberError={error}/>}
            <div className="w-[30%] flex gap-[12px] items-center max-lg:gap-[6px] max-lg:w-[100%]">
                <CoursesIcon fill={course.isMain ? "#2F80ED" : "#BDBDBD"} className="flex-shrink-0"/>
                {window.location.host.indexOf("fedkurs") !== -1 ? (
                    <Link
                        target="_blank"
                        to={`/folders/courses/${course.id}`}>{course.name} ({course.hours})</Link>
                ) : (
                    <Link
                        target="_blank"
                        to={`/folders/courses/${course.id}`}>{course.name} {course.isMain ? `` : `(${course.hours})`}</Link>
                )}
            </div>
            <div
                className="w-[10%] whitespace-nowrap max-lg:hidden">{dayjs(course.createdDate).format("DD.MM.YYYY HH:mm")}</div>
            <div
                className="w-[10%] whitespace-nowrap max-lg:hidden">{dayjs(course.updatedDate).format("DD.MM.YYYY HH:mm")}</div>
            <div className="w-[6%] flex justify-end gap-[10px]">
                <div
                    onClick={() => handleAction("restore")}
                    className="rounded-[2px] cursor-pointer bg-[#2F80ED] w-[24px] h-[24px] flex items-center justify-center flex-shrink-0">
                    <RestoreIcon className="w-[16px] h-[16px] rotate-180"/>
                </div>
                {Constants.ADMIN_ROLE === Number(currentRoleId) && (
                    <div
                        onClick={() => handleAction("delete")}
                        className="rounded-[2px] cursor-pointer bg-[#EB5757] w-[24px] h-[24px] flex items-center justify-center flex-shrink-0">
                        <DeleteIcon className="w-[16px] h-[16px]"/>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ItemCourseArchive