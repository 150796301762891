import {IUserResultTestOptions} from "../../../../../../../models/users";
import {ReactComponent as RadioUnchecked} from "../../../../../../../assets/images/tests/radio-unchecked.svg";
import {ReactComponent as CheckboxUnchecked} from "../../../../../../../assets/images/tests/checkbox-unchecked.svg";
import {ReactComponent as WrongAnswer} from "../../../../../../../assets/images/global/close.svg";
import {ReactComponent as SuccessAnswer} from "../../../../../../../assets/images/global/mark-success.svg";
import React, {useEffect, useState} from "react";

interface IOptionResultTestProps {
    option: IUserResultTestOptions
    isAdmin: boolean
    isUserResponse: boolean
    countCorrectAnswer: number
}

const OptionResultTest = (
    {
        option,
        isAdmin,
        isUserResponse,
        countCorrectAnswer
    }: IOptionResultTestProps
) => {

    const COLORS = {
        DEFAULT: "#F2F2F280",
        DEFAULT_TEXT: "#292B2C",
        SUCCESS: "#17AB571a",
        SUCCESS_TEXT: "#17AB57",
        ERROR: "#EB57571a",
        ERROR_TEXT: "#EB5757",
    }

    const [colorBg, setColorBg] = useState<string>(COLORS.DEFAULT)
    const [colorText, setColorText] = useState<string>(COLORS.DEFAULT_TEXT)

    useEffect(() => {
        if (!isUserResponse) return

        if (isAdmin) {
            if (option.isCorrectAnswer) {
                setColorBg(option.isCorrectAnswer === option.answerUser ? COLORS.SUCCESS : COLORS.DEFAULT)
                setColorText(COLORS.SUCCESS_TEXT)
                return
            } else {
                setColorBg(option.answerUser ? COLORS.ERROR : COLORS.DEFAULT)
                setColorText(option.answerUser ? COLORS.ERROR_TEXT : COLORS.DEFAULT_TEXT)
                return
            }
        }

        if (option.answerUser) {
            setColorBg(option.isCorrectAnswer ? COLORS.SUCCESS : COLORS.ERROR)
            setColorText(option.isCorrectAnswer ? COLORS.SUCCESS_TEXT : COLORS.ERROR_TEXT)
            return
        }
    }, [option]);

    return (
        <div
            className={`flex items-center gap-[10px] rounded-[16px] p-[20px] max-tablet:py-[16px] bg-[${colorBg}] text-[16px] font-[500] leading-[120%] text-[${colorText}]`}
        >
            {countCorrectAnswer > 1 ? (
                <>
                    {!isUserResponse ? (
                        <CheckboxUnchecked className="flex-shrink-0"/>
                    ) : (
                        <>
                            {isAdmin ? (
                                <>
                                    {option.isCorrectAnswer ? (
                                        <>
                                            <SuccessAnswer className="flex-shrink-0"/>
                                        </>
                                    ) : (
                                        <>
                                            {option.answerUser ? (<WrongAnswer
                                                className="flex-shrink-0 fill-[#EB5757] w-[24px] h-[24px]"/>) : (
                                                <CheckboxUnchecked className="flex-shrink-0"/>)}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {option.answerUser ? (
                                        <>
                                            {option.isCorrectAnswer ? (<SuccessAnswer className="flex-shrink-0"/>) : (
                                                <WrongAnswer
                                                    className="flex-shrink-0 fill-[#EB5757] w-[24px] h-[24px]"/>)}
                                        </>
                                    ) : (
                                        <CheckboxUnchecked className="flex-shrink-0"/>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    {option.name}
                </>
            ) : (
                <>
                    {!isUserResponse ? (
                        <RadioUnchecked className="flex-shrink-0"/>
                    ) : (
                        <>
                            {isAdmin ? (
                                <>
                                    {option.isCorrectAnswer ? (
                                        <>
                                            <SuccessAnswer className="flex-shrink-0"/>
                                        </>
                                    ) : (
                                        <>
                                            {option.answerUser ? (<WrongAnswer
                                                className="flex-shrink-0 fill-[#EB5757] w-[24px] h-[24px]"/>) : (
                                                <RadioUnchecked className="flex-shrink-0"/>)}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {option.answerUser ? (
                                        <>
                                            {option.isCorrectAnswer ? (<SuccessAnswer className="flex-shrink-0"/>) : (
                                                <WrongAnswer
                                                    className="flex-shrink-0 fill-[#EB5757] w-[24px] h-[24px]"/>)}
                                        </>
                                    ) : (
                                        <RadioUnchecked className="flex-shrink-0"/>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    {option.name}
                </>
            )}
        </div>
    )
}

export default OptionResultTest