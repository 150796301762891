import Modal from "../Modal";
import ErrorMessage from "../../error/ErrorMessage";
import {ReactComponent as CloseIcon} from "../../../../assets/images/global/close.svg";
import Portal from "../portal/Portal";
import React, {useState} from "react";
import {useMutation} from "react-query";
import {SubmitHandler, useForm} from "react-hook-form";
import {AxiosError} from "axios";
import {IGroupDetail} from "../../../../models/groups";
import {GroupsService} from "../../../../services/groups.service";
import Loader from "../../loader/Loader";
import {useNavigate} from "react-router-dom";

interface IModalAddGroup {
    onClose: () => void,
}

const ModalAddGroup = (
    {
        onClose,
    }: IModalAddGroup
) => {

    const navigate = useNavigate()
    const [error, setError] = useState<number | null>(null)
    const [show, setShow] = useState<boolean>(false)

    const {
        register,
        handleSubmit,
        formState: {errors, isValid}
    } = useForm<IGroupDetail>()

    const addGroup = useMutation(
        (data: IGroupDetail) => GroupsService.create(data),
        {
            onSuccess: (response) => {
                navigate(`/groups/${response.id}`)
            },
            onError: (error) => {
                setShow(false)
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const onSubmit: SubmitHandler<IGroupDetail> = async (data) => {
        setShow(true)
        addGroup.mutate(data)
    }

    return (
        <Portal>
            <Modal>
                {show && <Loader/>}
                {error && <ErrorMessage numberError={error}/>}
                <form className="flex flex-col gap-[36px] text-[16px]" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex justify-between items-center font-[500] text-[20px]">
                        Создание новой группы
                        <CloseIcon className="cursor-pointer w-[24px] h-[24px]" onClick={onClose}/>
                    </div>
                    <input
                        className="p-[16px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[12px]"
                        type="text"
                        placeholder="Введите название группы"
                        {...register("name", {required: true})}/>
                    {errors.name && errors.name.type === "required" && (
                        <p className="text-red-600">Название обязательно</p>
                    )}
                    <div className="flex gap-[12px] w-full">
                        <button
                            className="font-[500] py-[16px] px-[20px] bg-[#BDBDBD4d] rounded-[12px] w-full"
                            onClick={onClose}
                        >
                            Отмена
                        </button>
                        <button
                            type="submit"
                            disabled={!isValid}
                            className={`font-[500] py-[16px] px-[20px] rounded-[12px] w-full ${!isValid ? "bg-[#66C28E] text-[#ffffff66]" : "bg-[#17AB57] text-[#ffffff]"}`}
                        >
                            Сохранить
                        </button>
                    </div>
                </form>
            </Modal>
        </Portal>
    )
}

export default ModalAddGroup