import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {IProgressCourseLessonsUser, IProgressCourseUser} from "../../../../../../../models/users";
import {ReactComponent as LessonIcon} from "../../../../../../../assets/images/courses/lesson-icon.svg";
import {ReactComponent as TestIcon} from "../../../../../../../assets/images/courses/test-icon.svg";

interface IUserCourseResultItemProps {
    index: number
    progress: IProgressCourseUser
    lesson: IProgressCourseLessonsUser
    userId: string
}

const UserCourseResultItem = (
    {
        index,
        progress,
        lesson,
        userId
    }: IUserCourseResultItemProps
) => {

    const COLORS = {
        DEFAULT: "#BDBDBD",
        SUCCESS: "#17AB57",
        ERROR: "#EB5757",
        PRIMARY: "#2F80ED",
        TEXT: "#292B2C"
    }

    const [colorBorder, setColorBorder] = useState<string>(COLORS.DEFAULT)
    const [colorDashed, setColorDashed] = useState<string>(COLORS.DEFAULT)
    const [colorIcon, setColorIcon] = useState<string>(COLORS.DEFAULT)
    const [colorText, setColorText] = useState<string>(COLORS.DEFAULT)

    useEffect(() => {
        const {passMark, testId, percentPass, markFrom} = lesson
        const prevLesson = progress.lessons[index - 1]
        const nextLesson = progress.lessons[index + 1]

        const getColorOnPercent = (percent: number | undefined, mark: number | undefined) => {
            if (percent != null && mark != null) {
                return Number(percentPass) >= Number(mark) ? COLORS.SUCCESS : COLORS.ERROR
            }
            return COLORS.PRIMARY
        }

        if (nextLesson) {
            if (passMark) {
                setColorBorder(COLORS.SUCCESS)
                setColorIcon(COLORS.SUCCESS)
                if (nextLesson.passMark) {
                    setColorDashed(COLORS.SUCCESS)
                } else {
                    if (nextLesson.testId) {
                        const color = getColorOnPercent(nextLesson.percentPass, nextLesson.markFrom)
                        setColorDashed(color)
                    } else {
                        setColorDashed(COLORS.PRIMARY)
                    }
                }
            } else {
                if (prevLesson) {
                    if (testId) {
                        if (prevLesson.passMark) {
                            const color = getColorOnPercent(percentPass, markFrom)
                            setColorBorder(color)
                            setColorIcon(color)
                        }
                    } else {
                        if (prevLesson.passMark) {
                            setColorBorder(COLORS.PRIMARY)
                            setColorIcon(COLORS.PRIMARY)
                        }
                    }
                } else {
                    setColorBorder(COLORS.PRIMARY)
                    setColorIcon(COLORS.PRIMARY)
                }
            }
        } else {
            if (prevLesson) {
                if(prevLesson.passMark) {
                    if (passMark) {
                        setColorBorder(COLORS.SUCCESS)
                        setColorIcon(COLORS.SUCCESS)
                    } else {
                        if (testId) {
                            if (prevLesson.passMark) {
                                const color = getColorOnPercent(percentPass, markFrom)
                                setColorBorder(color)
                                setColorIcon(color)
                            }
                        } else {
                            setColorBorder(COLORS.PRIMARY)
                            setColorIcon(COLORS.PRIMARY)
                        }
                    }
                }
            } else {
                if (passMark) {
                    setColorBorder(COLORS.SUCCESS)
                    setColorIcon(COLORS.SUCCESS)
                } else {
                    setColorBorder(COLORS.PRIMARY)
                    setColorIcon(COLORS.PRIMARY)
                }
            }
        }

        if(passMark || !prevLesson || percentPass != null || prevLesson.passMark) {
            setColorText(COLORS.TEXT)
        }

    }, [lesson, progress])

    return (
        <div className="flex gap-[16px] group">
            <div className="flex flex-col items-center">
                <div className={`p-[8px] border-solid border-[${colorBorder}] border-[1px] rounded-[100px]`}>
                    {lesson.testId ? (
                        <TestIcon className={`fill-[${colorIcon}]`}/>
                    ) : (
                        <LessonIcon className={`fill-[${colorIcon}]`}/>
                    )}
                </div>
                <div
                    className={`h-full border-dashed mx-[20px] border-[1px] border-[${colorDashed}] group-last:hidden min-h-[30px]`}></div>
            </div>
            <div className="pb-[12px] group-last:pb-0 flex gap-[20px] w-full max-tablet:flex-col max-tablet:gap-[4px]">
                <div className="w-full">
                    <div
                        className={`text-[16px] leading-[120%] font-[500] text-[${colorText}]`}>{lesson.name}</div>
                    <div
                        className="text-[14px] text-[#BDBDBD] leading-[120%] font-[400]">{lesson.testId ? lesson.name : "Лекция"}</div>
                </div>
                {lesson.testId && lesson.percentPass != null && lesson.markFrom && (
                    <div
                        className="flex gap-[20px] items-center whitespace-nowrap max-tablet:flex-col max-tablet:gap-[12px] max-tablet:items-start">
                        <div
                            className={`text-[16px] leading-[120%] font-[500] ${lesson.percentPass >= lesson.markFrom ? "text-[#219653]" : "text-[#EB5757]"}`}>
                            {lesson.percentPass >= lesson.markFrom ? "Сдано" : "Не сдано"} {lesson.countCorrectQuestions}/{lesson.countGeneralQuestions} ({lesson.percentPass}%)
                        </div>
                        <Link
                            target="_blank"
                            to={`/users/${userId}/${progress.id}/lesson/${lesson.id}/test-view-result/${lesson.testId}`}
                            className="px-[16px] py-[12px] bg-[#bdbdbd4d] rounded-[8px] text-[12px] text-[#292B2C] font-[500]">
                            Результаты теста
                        </Link>
                    </div>
                )}
            </div>
        </div>
    )
}

export default UserCourseResultItem