import {useEffect, useState} from "react";
import {IQuestionDetail} from "../../../../models/tests";
import {ReactComponent as RadioUnchecked} from "../../../../assets/images/tests/radio-unchecked.svg";
import {ReactComponent as CheckboxUnchecked} from "../../../../assets/images/tests/checkbox-unchecked.svg";
import {ReactComponent as SuccessAnswer} from "../../../../assets/images/global/mark-success.svg";

interface ITestsQuestionsItem {
    question: IQuestionDetail
}

const TestsQuestionsItem = (
    {
        question
    }: ITestsQuestionsItem
) => {

    const [count, setCount] = useState<number>(0)

    useEffect(() => {
        setCount(0)
        question.options.forEach((element) => {
            if (element.isCorrectAnswer) {
                setCount(prevState => ++prevState)
            }
        })
    }, [question])

    return (
        <>
            <div
                className="border-b-[1px] border-solid border-[#BDBDBD] last:border-none flex flex-col gap-[6px] py-[8px] first:pt-0 last:pb-0 leading-[120%] text-[16px] text-[#292B2C] font-medium">
                <span className="font-bold">{question.name}</span>
                {question.options.map((o, ind) => (
                    <div key={ind}>
                        {count === 1 ? (
                            <label className="flex items-center gap-[6px]">
                                <input readOnly checked={o.isCorrectAnswer} className="hidden peer" type="radio"/>
                                <RadioUnchecked className="block peer-checked:hidden flex-shrink-0"/>
                                <SuccessAnswer className="hidden peer-checked:block flex-shrink-0"/>
                                {o.name}
                            </label>
                        ) : (
                            <label className="flex items-center gap-[6px]">
                                <input readOnly checked={o.isCorrectAnswer} className="hidden peer" type="checkbox"/>
                                <CheckboxUnchecked className="block peer-checked:hidden flex-shrink-0"/>
                                <SuccessAnswer className="hidden peer-checked:block flex-shrink-0"/>
                                {o.name}
                            </label>
                        )}
                    </div>
                ))}
            </div>
        </>
    )
}

export default TestsQuestionsItem