import Portal from "../portal/Portal";
import Modal from "../Modal";
import React, {useState} from "react";
import ErrorMessage from "../../error/ErrorMessage";
import {ReactComponent as CloseIcon} from "../../../../assets/images/global/close.svg";
import {IRecoveryPassword} from "../../../../models/users";
import {SubmitHandler, useForm} from "react-hook-form";
import {ICourses} from "../../../../models/courses";
import {useMutation} from "react-query";
import {TestsService} from "../../../../services/tests.service";
import {UsersService} from "../../../../services/users.service";
import {AxiosError} from "axios";
import Loader from "../../loader/Loader";

interface IModalRecoveryPassword {
    onClose: () => void
}

const ModalRecoveryPassword = (
    {
        onClose
    }: IModalRecoveryPassword
) => {

    const [error, setError] = useState<number | null>(null)
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [mail, setMail] = useState<string>("")

    const {
        register,
        handleSubmit,
        formState: {errors, isValid}
    } = useForm<IRecoveryPassword>()

    const onSubmit: SubmitHandler<IRecoveryPassword> = async (data) => {
        setShowLoading(true)
        recoveryPassword.mutate(data)
    }

    const recoveryPassword = useMutation(
        (data: IRecoveryPassword) => UsersService.recoveryPassword(data),
        {
            onSuccess: (response) => {
                setShowLoading(false)
                setError(null)
                setMail(response.email)
            },
            onError: (error) => {
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
                setShowLoading(false)
            }
        }
    )

    return (
        <Portal>
            <Modal>
                {error && <ErrorMessage numberError={error}/>}
                {showLoading && <Loader/>}
                {mail ? (
                    <div className="flex flex-col gap-[32px] max-tablet:gap-[24px]">
                        <div className="flex justify-between items-center font-[500] text-[20px]">
                            Восстановление пароля
                            <CloseIcon className="cursor-pointer w-[24px] h-[24px]" onClick={onClose}/>
                        </div>
                        <div className="text-[16px] leading-[120%] font-[400] text-[#292B2C]">
                            Пароль успешно отправлен. Проверьте почту {mail}.
                        </div>
                        <button
                            className="font-[500] py-[16px] px-[20px] bg-[#BDBDBD4d] rounded-[12px] w-full"
                            onClick={onClose}>
                            Закрыть
                        </button>
                    </div>
                ) : (
                    <form className="flex flex-col gap-[32px] max-tablet:gap-[24px]"
                          onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex justify-between items-center font-[500] text-[20px]">
                            Восстановление пароля
                            <CloseIcon className="cursor-pointer w-[24px] h-[24px]" onClick={onClose}/>
                        </div>
                        <div className="text-[16px] leading-[120%] font-[400] text-[#292B2C]">
                            Укажите почту и мы отправим вам новый пароль
                        </div>
                        <input
                            type="email"
                            autoComplete="email"
                            placeholder="Введите адрес электронной почты"
                            {...register("email", {required: true})}
                            className="p-[16px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[12px]"
                        />
                        <div className="flex gap-[12px] w-full">
                            <button
                                className="font-[500] py-[16px] px-[20px] bg-[#BDBDBD4d] rounded-[12px] w-full"
                                onClick={onClose}
                            >
                                Отмена
                            </button>
                            <button
                                type="submit"
                                disabled={!isValid}
                                onClick={handleSubmit(onSubmit)}
                                className={`font-[500] py-[16px] px-[20px] rounded-[12px] w-full ${!isValid ? "bg-[#66C28E] text-[#ffffff66]" : "bg-[#17AB57] text-[#ffffff]"}`}
                            >
                                Отправить
                            </button>
                        </div>
                    </form>
                )}
            </Modal>
        </Portal>
    )
}

export default ModalRecoveryPassword