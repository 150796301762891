import Modal from "../Modal";
import ErrorMessage from "../../error/ErrorMessage";
import {ReactComponent as CloseIcon} from "../../../../assets/images/global/close.svg";
import Portal from "../portal/Portal";
import React, {useEffect, useState} from "react";
import {ICoursesForAttach} from "../../../../models/courses";
import {ReactComponent as CoursesIcon} from "../../../../assets/images/menu/courses-icon.svg";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {UsersService} from "../../../../services/users.service";
import {AxiosError} from "axios";
import {IUserCourseAttach, IUserWithoutEducationId} from "../../../../models/users";
import Loader from "../../loader/Loader";
import ScrollContainer from "../../scroll-container/ScrollContainer";
import {useNavigate} from "react-router-dom";
import {IGroup} from "../../../../models/groups";
import Select from "react-select";
import {getCustomStyleReactSelect} from "../../../../constans/constants";
import Search from "../../search/Search";
import {Controller, SubmitHandler, useForm} from "react-hook-form";

interface IModalAttachCourseUser {
    onClose: () => void
    userId: number
    group?: IGroup
    idUserGroup?: number | null
    setUserIdForAddCourse?: (id: number | null) => void
}

const ModalAttachCourseUser = (
    {
        onClose,
        userId,
        group,
        idUserGroup,
        setUserIdForAddCourse
    }: IModalAttachCourseUser
) => {

    const methods = useForm<IUserCourseAttach>();
    const {
        register,
        setValue,
        handleSubmit,
        formState: {isValid},
        control,
        reset
    } = methods
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const [page, setPage] = useState<number>(1)
    const [scrollIsBottom, setScrollIsBottom] = useState<boolean>(false)

    const [show, setShow] = useState<boolean>(false);
    const [error, setError] = useState<number | null>(null)
    const [searchTerm, setSearchTerm] = useState<string>("")
    const [activeCourse, setActiveCourse] = useState<number | null>(null)
    const [listCoursesForAttach, setListCoursesForAttach] = useState<ICoursesForAttach[]>([])

    const {data: listCourses, isLoading: listCoursesLoading, isError: listCoursesError} = useQuery(
        ["courses_attach", searchTerm, page],
        () => UsersService.getListCoursesForAttach(page, searchTerm),
        {
            keepPreviousData: true,
            onSuccess: (response) => {
                setValue("userId", userId)
                setActiveCourse(null)
                if (page === 1) {
                    setListCoursesForAttach(response)
                } else {
                    response.map((course) => {
                        setListCoursesForAttach(prev => [...prev, course])
                    })
                    setScrollIsBottom(false)
                }
            },
        }
    )

    useEffect(() => {
        setPage(1)
    }, [searchTerm]);

    useEffect(() => {
        if (scrollIsBottom) setPage(prev => prev + 1)
    }, [scrollIsBottom]);

    const {
        data: listUsersWithoutEducationId,
        isLoading: listUsersWithoutEducationIdLoading,
        isError: listUsersWithoutEducationIdError
    } = useQuery(
        ["list_users_without_education_id"],
        () => UsersService.getListUsersWithoutEducationId(),
        {
            keepPreviousData: true,
            enabled: window.location.host.indexOf("fedkurs") !== -1
        }
    )

    const addCourse = useMutation(
        (data: IUserCourseAttach) => UsersService.attachUserInCourse(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["user_courses"])
                setShow(false)
                onClose()
            },
            onError: (error) => {
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
                setShow(false)
            }
        }
    )

    // const handleClose = () => {
    //     if (idUserGroup && setUserIdForAddCourse) setUserIdForAddCourse(null)
    //     onClose()
    // }

    const onSubmit: SubmitHandler<IUserCourseAttach> = (data) => {
        if (group) {
            navigate(`/attach-course-in-group/${activeCourse}/group/${group.id}` + (idUserGroup ? `/user/${idUserGroup}` : ""))
        } else {
            setShow(true)
            addCourse.mutate(data)
        }
    }

    const handleActiveCourse = (courseId: number) => {
        setActiveCourse(courseId)
        setValue("courseId", courseId, {shouldValidate: true})
    }

    if (listCoursesLoading || listUsersWithoutEducationIdLoading) return <Loader/>
    if (listCoursesError || listUsersWithoutEducationIdError) setError(400)
    if (!listCourses || (window.location.host.indexOf("fedkurs") !== -1 && !listUsersWithoutEducationId)) setError(400)

    return (
        <Portal>
            <Modal>
                {show && <Loader/>}
                <form className="flex flex-col gap-[36px] h-full" onSubmit={handleSubmit(onSubmit)}>
                    {error && <ErrorMessage numberError={error}/>}
                    <div className="flex justify-between items-center font-[500] text-[20px]">
                        Назначить курс
                        <CloseIcon className="cursor-pointer w-[24px] h-[24px]" onClick={onClose}/>
                    </div>
                    <Search setSearchTerm={setSearchTerm}/>
                    <input type="hidden" {...register("userId", {required: true})}/>
                    <div className="h-[250px] max-tablet:h-[200px] relative">
                        <ScrollContainer setScrollIsBottom={setScrollIsBottom} searchTerm={searchTerm}>
                            {listCoursesForAttach.map((course, index) => (
                                <div
                                    onClick={() => handleActiveCourse(course.id)}
                                    key={index}
                                    className={`flex gap-[12px] py-[12px] mr-[20px] border-solid border-[#BDBDBD] border-b-[0.5px] border-opacity-[0.3] items-start last:border-none cursor-pointer ${activeCourse === course.id ? "bg-[#F2F2F2]" : ""}`}>
                                    <CoursesIcon className="flex-shrink-0 fill-[#17AB57]"/>
                                    <div className="flex flex-col">
                                        <div
                                            className="text-[#292B2C] text-[16px] leading-[120%] font-[500]">{course.name} {window.location.host.indexOf("fedkurs") !== -1 && (<>({course.id})</>)}</div>
                                        <div
                                            className="text-[#BDBDBD] text-[14px] leading-[120%] font-[400]">{course.path}</div>
                                    </div>
                                </div>
                            ))}
                        </ScrollContainer>
                        <input type="hidden" {...register("courseId", {required: true})}/>
                    </div>
                    {window.location.host.indexOf("fedkurs") !== -1 && (
                        <Controller
                            control={control}
                            name={"userWithoutEducationId"}
                            rules={{
                                required: true
                            }}
                            render={({field: {onChange, value}}) =>
                                <>
                                    <Select
                                        unstyled
                                        menuPlacement="top"
                                        styles={getCustomStyleReactSelect<IUserWithoutEducationId>()}
                                        required={true}
                                        placeholder={"Выберите учащегося"}
                                        options={listUsersWithoutEducationId}
                                        onChange={e => {
                                            onChange(e)
                                        }}
                                        value={value}
                                        isSearchable={true}
                                        isMulti={false}
                                        noOptionsMessage={() => "Нет пользователей"}
                                        getOptionLabel={(user: IUserWithoutEducationId) => "Сделка: " + user.dealId + ", Почта: " + user.email + ", ФИО: " + user.userName}
                                        getOptionValue={(user: IUserWithoutEducationId) => String(user.id)}
                                    />
                                </>
                            }
                        />
                    )}
                    <div className="flex gap-[12px] w-full">
                        <button
                            className="font-[500] py-[16px] px-[20px] bg-[#BDBDBD4d] rounded-[12px] w-full"
                            onClick={onClose}>
                            Отмена
                        </button>
                        <button
                            type="submit"
                            disabled={!isValid}
                            className={`font-[500] py-[16px] px-[20px] rounded-[12px] w-full ${!isValid ? "bg-[#66C28E] text-[#ffffff66]" : "bg-[#17AB57] text-[#ffffff]"}`}>
                            Назначить
                        </button>
                    </div>
                </form>
            </Modal>
        </Portal>
    )
}

export default ModalAttachCourseUser