import {ReactComponent as PlusIcon} from "../../../../assets/images/global/plus-icon.svg";
import React, {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {GroupsService} from "../../../../services/groups.service";
import {IGroup} from "../../../../models/groups";
import ErrorMessage from "../../../ui/error/ErrorMessage";
import GroupsItem from "./groups-item/GroupsItem";
import useToggle from "../../../../hooks/useToggle";
import ModalAddGroup from "../../../ui/modal/modal-add-group/ModalAddGroup";
import ScrollContainer from "../../../ui/scroll-container/ScrollContainer";
import {ReactComponent as ArrowScroll} from "../../../../assets/images/global/arrow.svg";
import {positionValues} from "react-custom-scrollbars-2";
import Loader from "../../../ui/loader/Loader";
import Search from "../../../ui/search/Search";
import ArrowSort from "../../../ui/arrow-sort/ArrowSort";

const Groups = () => {

    const [isModalAddGroup, openIsModalAddGroup, closeIsModalAddGroup] = useToggle()
    const [error, setError] = useState<number | null>(null)
    const [filteredGroups, setFilteredGroups] = useState<IGroup[]>([])
    const [page, setPage] = useState<number>(1)
    const [searchTerm, setSearchTerm] = useState<string>("")
    const [sortField, setSortField] = useState<string>('Group.Name')
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc")
    const [scrollIsBottom, setScrollIsBottom] = useState<boolean>(false)
    const [positionScroll, setPositionScroll] = useState<positionValues | null>(null)
    const [scrollToTop, setScrollToTop] = useState<boolean>(true)

    const {data: groups, isLoading, isError} = useQuery(
        ["groups", page, searchTerm, sortField, sortDirection],
        () => GroupsService.getByPage(page, searchTerm, sortField, sortDirection),
        {
            keepPreviousData: true,
            onSuccess: (response) => {
                if (page === 1) {
                    setFilteredGroups(response)
                } else {
                    response.map(group => {
                        setFilteredGroups(prev => [...prev, group])
                    })
                    setScrollIsBottom(false)
                }
            }
        }
    )

    useEffect(() => {
        setPage(1)
    }, [searchTerm, sortField, sortDirection]);

    useEffect(() => {
        if (scrollIsBottom) setPage(prev => prev + 1)
    }, [scrollIsBottom]);

    const onDelete = (id: number) => {
        setFilteredGroups(filteredGroups.filter(g => g.id != id))
    }

    if (isLoading) return <Loader/>
    if (isError) return <ErrorMessage numberError={400}/>
    if (!groups) return <ErrorMessage numberError={400}/>

    return (
        <>
            {error && <ErrorMessage numberError={error}/>}
            {isModalAddGroup && <ModalAddGroup onClose={closeIsModalAddGroup}/>}
            {positionScroll && positionScroll.top <= 1 && positionScroll.top > 0 && (
                <div
                    onClick={() => setScrollToTop(!scrollToTop)}
                    className="absolute p-[16px] rounded-[49px] bg-white right-[10px] bottom-[20px] z-[50] cursor-pointer shadow-[0_0px_20px_0_rgba(0,0,0,0.15)]">
                    <div className="w-full h-full relative flex items-center justify-center">
                        <ArrowScroll className={`fill-black`}/>
                    </div>
                </div>
            )}
            <ScrollContainer
                scrollToTop={scrollToTop}
                setPositionScroll={setPositionScroll}
                setScrollIsBottom={setScrollIsBottom}
                searchTerm={searchTerm}
                sortField={sortField}
                sortDirection={sortDirection}>
                <div className="flex flex-col gap-[32px] h-full max-tablet:gap-[28px]">
                    <div className="text-[36px] font-[700] text-[#292B2C] leading-[120%]">Группы</div>
                    <div className="w-full flex gap-[12px] items-center text-[16px] max-tablet:flex-col">
                        <Search setSearchTerm={setSearchTerm}/>
                        <div
                            onClick={openIsModalAddGroup}
                            className="bg-[#17AB57] text-white rounded-[12px] items-center flex gap-[6px] py-[16px] px-[20px] font-medium whitespace-nowrap cursor-pointer justify-center max-tablet:w-full">
                            <PlusIcon className="flex-shrink-0"/>
                            Добавить группу
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div
                            className="bg-white p-[36px] rounded-[20px] flex flex-col text-[16px] h-full max-tablet:p-0 max-tablet:bg-[#F7F7F7]">
                            {filteredGroups.length > 0 ? (
                                <>
                                    <div
                                        className="flex text-[#828282] max-tablet:hidden pr-[16px] w-full border-solid border-b-[0.5px] border-[#BDBDBD] border-opacity-[0.3] pb-[20px]">
                                        <ArrowSort className={`w-[calc(100%/3*2)]`} sortField={"Group.Name"}
                                                   setSortField={setSortField} setSortDirection={setSortDirection}
                                                   activeSortField={sortField}>
                                            Название
                                        </ArrowSort>
                                        <ArrowSort className={`w-[calc(100%/3)]`} sortField={"Group.Users.Count"}
                                                   setSortField={setSortField} setSortDirection={setSortDirection}
                                                   activeSortField={sortField}>
                                            Количество пользователей
                                        </ArrowSort>
                                        <div className="w-[74px] flex-shrink-0">Действия</div>
                                    </div>
                                    {filteredGroups.map((group, index) => (
                                        <GroupsItem
                                            setError={setError}
                                            onDelete={onDelete}
                                            group={group}
                                            key={index}
                                        />
                                    ))}
                                </>
                            ) : (
                                <div className="flex justify-center py-[12px] font-[400] text-[#828282]">
                                    Групп нет
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </ScrollContainer>
        </>
    )
}

export default Groups