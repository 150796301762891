import {ReactComponent as PlusIcon} from "../../../../assets/images/global/plus-icon.svg";
import React, {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {UsersService} from "../../../../services/users.service";
import ErrorMessage from "../../../ui/error/ErrorMessage";
import {IUser} from "../../../../models/users";
import UsersItem from "./users-item/UsersItem";
import {Link} from "react-router-dom";
import ScrollContainer from "../../../ui/scroll-container/ScrollContainer";
import Search from "../../../ui/search/Search";
import Loader from "../../../ui/loader/Loader";
import Tabs from "../../../ui/tabs/Tabs";
import {ITab} from "../../../../models/tabs";
import ArrowSort from "../../../ui/arrow-sort/ArrowSort";
import {ReactComponent as ArrowScroll} from "../../../../assets/images/global/arrow.svg";
import {positionValues} from "react-custom-scrollbars-2";

const Users = () => {

    const [error, setError] = useState<number | null>(null)
    const [filteredUsers, setFilteredUsers] = useState<IUser[]>([])
    const [page, setPage] = useState<number>(1)
    const [searchTerm, setSearchTerm] = useState<string>("")
    const [activeTab, setActiveTab] = useState<number | null>(null)
    const [sortField, setSortField] = useState<string>('User.LastName')
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc")
    const [scrollIsBottom, setScrollIsBottom] = useState<boolean>(false)
    const [positionScroll, setPositionScroll] = useState<positionValues | null>(null)
    const [scrollToTop, setScrollToTop] = useState<boolean>(true)

    const {data: users, isLoading: usersLoading, isError: usersError} = useQuery<IUser[]>(
        ["users", activeTab, page, searchTerm, sortField, sortDirection],
        () => UsersService.getByPage(activeTab, page, searchTerm, sortField, sortDirection),
        {
            keepPreviousData: true,
            onSuccess: (response) => {
                if (page === 1) {
                    setFilteredUsers(response)
                } else {
                    response.map((user) => {
                        setFilteredUsers(prev => [...prev, user])
                    })
                    setScrollIsBottom(false)
                }
            }
        }
    )

    const {data: roles, isLoading: rolesLoading, isError: rolesError} = useQuery<ITab[]>(
        ["roles_count"],
        () => UsersService.getRolesWithCount()
    )

    useEffect(() => {
        setPage(1)
    }, [searchTerm, activeTab, sortField, sortDirection]);

    useEffect(() => {
        if (scrollIsBottom) setPage(prev => prev + 1)
    }, [scrollIsBottom]);

    if (usersLoading || rolesLoading) return <Loader/>
    if (usersError || rolesError) return <ErrorMessage numberError={400}/>
    if (!users || !roles) return <ErrorMessage numberError={400}/>

    return (
        <>
            {positionScroll && positionScroll.top <= 1 && positionScroll.top > 0 && (
                <div
                    onClick={() => setScrollToTop(!scrollToTop)}
                    className="absolute p-[16px] rounded-[49px] bg-white right-[10px] bottom-[20px] z-[50] cursor-pointer shadow-[0_0px_20px_0_rgba(0,0,0,0.15)]">
                    <div className="w-full h-full relative flex items-center justify-center">
                        <ArrowScroll className={`fill-black rotate-90`}/>
                    </div>
                </div>
            )}
            {error && <ErrorMessage numberError={error}/>}
            <ScrollContainer
                scrollToTop={scrollToTop}
                setPositionScroll={setPositionScroll}
                setScrollIsBottom={setScrollIsBottom}
                searchTerm={searchTerm}
                sortField={sortField}
                sortDirection={sortDirection}
                activeTab={activeTab}>
                <div className="flex flex-col gap-[32px] h-full max-tablet:gap-[28px]">
                    <div className="text-[36px] font-[700] text-[#292B2C] leading-[120%]">Пользователи</div>
                    <div className="w-full flex gap-[12px] items-center text-[16px] max-tablet:flex-col">
                        <Search setSearchTerm={setSearchTerm}/>
                        <Link
                            to={"/users/add"}
                            className="bg-[#17AB57] text-white rounded-[12px] items-center flex gap-[6px] py-[16px] px-[20px] font-medium whitespace-nowrap cursor-pointer justify-center max-tablet:w-full">
                            <PlusIcon className="flex-shrink-0"/>
                            Добавить пользователя
                        </Link>
                    </div>
                    <div className="flex flex-col gap-[12px]">
                        <Tabs list={roles} setActiveTab={setActiveTab}/>
                        <div
                            className="bg-white p-[36px] rounded-[20px] flex flex-col text-[16px] h-full max-tablet:p-0 max-tablet:bg-[#F7F7F7]">
                            <div
                                className="flex text-[#828282] max-tablet:hidden pr-[16px] w-full border-solid border-b-[0.5px] border-[#BDBDBD] border-opacity-[0.3] pb-[20px]">
                                <ArrowSort className={`w-[calc(100%/3)]`} sortField={"User.LastName"}
                                           setSortField={setSortField} setSortDirection={setSortDirection}
                                           activeSortField={sortField}>
                                    Имя
                                </ArrowSort>
                                <div className=" w-[calc(100%/3)]">Почта</div>
                                <ArrowSort className={`w-[calc(100%/3)]`} sortField={"User.Group.Name"}
                                           setSortField={setSortField} setSortDirection={setSortDirection}
                                           activeSortField={sortField}>
                                    Группа
                                </ArrowSort>
                                <div className="w-[74px] flex-shrink-0">Действия</div>
                            </div>
                            {filteredUsers.map((user, index) => (
                                <UsersItem user={user} key={index} setError={setError}/>
                            ))}
                        </div>
                    </div>
                </div>
            </ScrollContainer>
        </>
    )
}

export default Users