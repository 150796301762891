import {
    ICourses,
    ICoursesDetail,
    ICoursesDetailId,
    ICourseSearch,
    ICoursesFolder,
    ListLeftArchiveCourse
} from "../models/courses";
import {IUser, IUserCourseAttach} from "../models/users";
import {IFolderSearchResult} from "../models/folders";
import {ILessonsAttachWithoutUser} from "../models/lessons";
import api from "./api";

export const CoursesService = {
    async getAll(id: string) {
        const response = await api.get<ICourses[]>(`/api/Course/folder/${id}`)
        return response.data
    },

    async getAllForFedkurs(id: number) {
        const response = await api.get<ICourses[]>(`/api/Course/folderForFedkurs/${id}`)
        return response.data
    },

    async getById(id: string) {
        const response = await api.get<ICourses>(`/api/Course/${id}`)
        return response.data
    },

    async create(data: ICoursesDetail) {
        return await api.post<ICourses>('/api/Course/add', data)
    },

    async update(id: number, data: ICoursesDetail) {
        return await api.post<ICourses>(`/api/Course/update/${id}`, data)
    },

    async delete(data: ICoursesDetailId) {
        return await api.post<boolean>('/api/Course/delete/', data)
    },

    async copy(data: ICoursesDetailId) {
        return await api.post<ICourses>('/api/Course/copy/', data)
    },

    async move(data: ICoursesFolder){
        return await api.post<ICourses>('/api/Course/move/', data)
    },

    async attachUser(data: IUserCourseAttach) {
        return await api.post('/api/Course/attach', data)
    },

    async getUsers(id: number) {
        const { data } = await api.get<IUser[]>(`/api/Course/users/${id}`)
        return data
    },

    async deleteUser(dataDeleteUser: IUserCourseAttach) {
        const { data } = await api.post<boolean>('/api/Course/deleteUser', dataDeleteUser)
        return data
    },

    async deleteAllUser(data: ICoursesDetailId) {
        return await api.post<boolean>('/api/Course/deleteAllUser', data)
    },

    async search(dataSearch: ICourseSearch) {
        const {data} = await api.post<IFolderSearchResult[]>(`/api/Course/search`, dataSearch)
        return data
    },

    async unloadingCourseFromFedkurs() {
        const {data} = await api.get(`/api/Course/unloadingCourse`)
        return data
    },

    async addCourseInFedkurs(dataSubmit: ILessonsAttachWithoutUser) {
        const {data} = await api.post<ICourses>(`/api/Course/createCourseInFedkurs/`, dataSubmit)
        return data
    },

    async searchIsArchiveCourses(page: number, searchTerm: string){
        const {data} = await api.get<ListLeftArchiveCourse>(`/api/Course/searchIsArchiveCourse/${page}/${searchTerm}`)
        return data
    },

    async changeArchiveCourse(dataSubmit: ICoursesDetailId) {
        const {data} = await api.post<ICourses>(`/api/Course/changeArchiveCourse/`, dataSubmit)
        return data
    }
}