import Cookies from "universal-cookie";
import {useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useRef, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {useMutation, useQuery} from "react-query";
import {UsersService} from "../../../../../services/users.service";
import {IPassingTest} from "../../../../../models/tests";
import {AxiosError} from "axios";
import ErrorMessage from "../../../../ui/error/ErrorMessage";
import {ReactComponent as CheckboxUnchecked} from "../../../../../assets/images/tests/checkbox-unchecked.svg";
import {ReactComponent as CheckboxChecked} from "../../../../../assets/images/tests/checkbox-checked.svg";
import {ReactComponent as RadioUnchecked} from "../../../../../assets/images/tests/radio-unchecked.svg";
import {ReactComponent as RadioChecked} from "../../../../../assets/images/tests/radio-checked.svg";
import useToggle from "../../../../../hooks/useToggle";
import FinishTest from "../../../../ui/modal/finish-test/FinishTest";
import {LoaderContext} from "../../../../../context/LoaderContext";
import Loader from "../../../../ui/loader/Loader";
import {IProgressCourseLessonsUser} from "../../../../../models/users";
import {AuthContext} from "../../../../../context/AuthContext";

const StudentLessonTestPassing = () => {

    const cookies = new Cookies()
    const navigate = useNavigate()
    const {id, testId, lessonId} = useParams()
    const ref = useRef<HTMLDivElement>(null)

    const {show, setShow} = useContext(LoaderContext)
    const [activeIndexQuestion, setActiveIndexQuestion] = useState<number>(1)
    const [passingTest, setPassingTest] = useState<number[]>([])
    const [finishTest, setFinishTest] = useState<boolean>(false)
    const [formSubmit, setFormSubmit] = useState<IPassingTest[]>([])
    const [error, setError] = useState<number | null>(null)
    const [isModalFinishTest, setOnModalOpenFinishTest, setOnModalCloseFinishTest] = useToggle()
    const [answerPassingTest, setAnswerPassingTest] = useState<number[]>([])
    const [unansweredPassingTest, setUnansweredPassingTest] = useState<number[]>([])
    const [difference, setDifference] = useState<number[]>([])

    const {authenticated, setAuthenticated, currentRoleId, currentUserId} = useContext(AuthContext)

    const {
        register,
        handleSubmit,
    } = useForm()

    const {data: test, isLoading: testLoading, isError: testError} = useQuery(
        ["test_passing", testId, id, currentUserId, lessonId],
        () => UsersService.getQuestionsForPassingTest(Number(testId), Number(lessonId)),
        {
            keepPreviousData: true,
            onSuccess: (response) => {
                response.questions.map((question, index) => {
                    setUnansweredPassingTest(prev => [...prev, index + 1])
                })
            }
        }
    )

    const {data: progress, isLoading: progressLoading, isError: progressError} = useQuery(
        ["progress_lesson_view", lessonId],
        () => UsersService.getProgressCourseForLesson(Number(currentUserId), Number(id)),
        {
            enabled: !!id && !!cookies.get("user"),
            onSuccess: (response) => {
                const currentLesson = response.lessons.findIndex(c => c.id === Number(lessonId))
                if(response.lessons[currentLesson - 1] && !response.lessons[currentLesson - 1].passMark) navigate(`/student-courses/${id}`)
                if(response.isBlocked) navigate("/student-courses")
            },
        }
    )

    const finish = useMutation(
        (data: IPassingTest[]) => UsersService.finishTest(Number(currentUserId), Number(testId), Number(lessonId), Number(id), data),
        {
            onSuccess: () => {
                setShow(false)
                navigate(`/student-courses/${id}/lesson/${lessonId}/test-view-result/${testId}`)
            },
            onError: (errorFinish) => {
                const e = errorFinish as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const onSubmit: SubmitHandler<any> = async (data) => {
        setFormSubmit([])
        data.answer.map((element: any, index: number) => {
            if (element) {
                if (typeof element !== "object") {
                    element = [element]
                }
                setFormSubmit(prev => [...prev, {"questionId": index, "answerId": element}])
            } else {
                setFormSubmit(prev => [...prev, {"questionId": index, "answerId": null}])
            }
        })
        setDifference(unansweredPassingTest.filter(x => !passingTest.includes(x)).concat(passingTest.filter(x => !unansweredPassingTest.includes(x))))
        setOnModalOpenFinishTest()
    }

    useEffect(() => {
        if(finishTest) {
            setShow(true)
            finish.mutate(formSubmit)
        }
    }, [finishTest])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAnswerPassingTest(prev => [...prev, Number(e.target.name.split(".")[1])])
        if(passingTest.indexOf(activeIndexQuestion) === -1) {
            setPassingTest(prev => [...prev, activeIndexQuestion])
        }
    }

    if (testLoading || progressLoading) return <Loader/>
    if (testError || progressError) return <ErrorMessage numberError={400}/>
    if (!test || !progress) return <ErrorMessage numberError={400}/>

    const updateActiveQuestion = (nextIndex: number, prevIndex: number) => {
        setActiveIndexQuestion(nextIndex)
        if(answerPassingTest.indexOf(test.questions[prevIndex - 1].id) !== -1 && passingTest.indexOf(activeIndexQuestion) === -1) {
            setPassingTest(prev => [...prev, prevIndex])
        }
    }

    return (
        <>
            {show && <Loader/>}
            {error && <ErrorMessage numberError={error}/>}
            <div className="flex h-full gap-[24px] flex-col">
                <div
                    className="text-[#292B2C] text-[36px] font-bold leading-[120%]">Вопрос {activeIndexQuestion} из {test.questions.length}</div>
                <form className="flex gap-[12px] lg:h-full min-h-0 max-2xl:flex-col" onSubmit={handleSubmit(onSubmit)}>
                    <div ref={ref} className="lg:h-full flex flex-col gap-[24px] w-full">
                        {test.questions.map((question, index) => (
                            <div
                                key={index}
                                className={`w-full p-[32px] max-lg:p-[14px] rounded-[6px] bg-white flex-col gap-[18px] hidden ` + (activeIndexQuestion == (index + 1) ? " !flex" : "")}>
                                <div
                                    className="leading-[120%] text-[#292B2C] text-[24px] font-medium max-lg:text-[20px]">
                                    {question.name}
                                </div>
                                <div className="flex flex-col gap-[12px]">
                                    {question.options.map((option, indexOption) => (
                                        <div
                                            key={indexOption}
                                            className="rounded-[4px] bg-[#F2F2F2] px-[10px] py-[12px] flex items-center justify-between">
                                            {question.countCorrectAnswer > 1 ? (
                                                <label className="flex items-center gap-[6px] cursor-pointer w-full">
                                                    <input {...register(`answer.${question.id}`, {
                                                        onChange: (e) => {handleChange(e)}
                                                    })} value={option.id}
                                                           className="hidden peer" type="checkbox"/>
                                                    <CheckboxUnchecked className="block peer-checked:hidden flex-shrink-0"/>
                                                    <CheckboxChecked className="hidden peer-checked:block flex-shrink-0"/>
                                                    {option.name}
                                                </label>
                                            ) : (
                                                <label className="flex items-center gap-[6px] cursor-pointer w-full">
                                                    <input
                                                        {...register(`answer.${question.id}`, {
                                                            onChange: (e) => {handleChange(e)}
                                                        })}
                                                        value={option.id}
                                                        className="hidden peer" type="radio"/>
                                                    <RadioUnchecked className="block peer-checked:hidden flex-shrink-0"/>
                                                    <RadioChecked className="hidden peer-checked:block flex-shrink-0"/>
                                                    {option.name}
                                                </label>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className="flex gap-[16px] justify-end max-lg:flex-col">
                                    {index != 0 && (
                                        <div
                                            onClick={() => updateActiveQuestion(activeIndexQuestion - 1, activeIndexQuestion)}
                                            className="rounded-[5px] px-[16px] py-[8px] text-center w-[240px] text-[#292B2C] text-[16px] leading-[120%] font-medium cursor-pointer border-solid border-[1px] border-[#17AB57] max-lg:w-full">
                                            Предыдущий вопрос
                                        </div>
                                    )}
                                    {index != (test.questions.length - 1) && (
                                        <div
                                            onClick={() => updateActiveQuestion(activeIndexQuestion + 1, activeIndexQuestion)}
                                            className="rounded-[5px] px-[16px] py-[8px] text-center w-[240px] text-white text-[16px] leading-[120%] font-medium cursor-pointer bg-[#17AB57] max-lg:w-full">
                                            Следующий вопрос
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div
                        className="w-[470px] flex flex-col gap-[10px] flex-shrink-0 max-2xl:w-full max-lg:pb-[24px]">
                        <div className="rounded-[5px] bg-white p-[32px] flex flex-col gap-[24px]">
                            <div className="text-[#292B2C] text-[24px] leading-[120%] font-bold">Вопросы</div>
                            <div
                                className="flex gap-x-[14px] gap-y-[14px] flex-wrap text-[#292B2C] text-[16px] leading-[120%] font-medium">
                                {test.questions.map((question, index) => (
                                    <div
                                        key={index}
                                        onClick={() => updateActiveQuestion(index + 1, activeIndexQuestion)}
                                        className={`cursor-pointer flex justify-center items-center h-[32px] w-[32px] flex-shrink-0 rounded-[5px] ` +
                                            (activeIndexQuestion == (index + 1) ? "text-white bg-[#17AB57]" : (passingTest.includes(index + 1) ? "bg-[#E0E0E0]" : ""))}>
                                        {index + 1}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="w-full text-center px-[16px] py-[12px] border-solid border-[1px] border-[#17AB57] rounded-[5px] cursor-pointer text-[#292B2C] text-[20px] leading-[120%] font-medium">
                            Завершить тестирование
                        </button>
                    </div>
                </form>
            </div>
            {isModalFinishTest && <FinishTest
                setFinishTest={setFinishTest}
                formSubmit={difference}
                onClose={setOnModalCloseFinishTest}/>}
        </>
    )
}

export default StudentLessonTestPassing