import React from 'react';
import Router from "./components/Router";
import Sidebar from "./components/ui/sidebar/Sidebar";
import Content from "./components/ui/content/Content";

function App() {

    return (
        <>
            <div
                className="flex h-screen w-screen font-roboto leading-[120%] not-italic max-tablet:flex-col max-tablet:relative">

                <Content>
                    <Router/>
                </Content>
            </div>
        </>
    )
}

export default App;
