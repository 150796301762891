import {ICompany, ICompanyDetail, ICompanyDetailId} from "../models/company";
import api from "./api";

export const CompanyService = {
    async getAll() {
        const {data} = await api.get<ICompany[]>(`/api/Company/`)
        return data
    },

    async getById(id: number) {
        const response = await api.get<ICompany>(`/api/Company/${id}`)
        return response.data
    },

    async create(dataSubmit: ICompanyDetail) {
        const {data} =  await api.post<ICompany>('/api/Company/add', dataSubmit)
        return data
    },

    async update(id: number, data: ICompanyDetail) {
        return await api.post<ICompany>(`/api/Company/update/${id}`, data)
    },

    async delete(data: ICompanyDetailId) {
        return await api.post<boolean>('/api/Company/delete/', data)
    }
}