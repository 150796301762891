import Loader from "../../../ui/loader/Loader";
import {Link} from "react-router-dom";
import SearchInput from "../../../ui/search-input/SearchInput";
import SearchFolders from "../../../ui/search-input/search-folders/SearchFolders";
import ErrorMessage from "../../../ui/error/ErrorMessage";
import FoldersItem from "../folders/folders-item/FoldersItem";
import CoursesList from "../courses/courses-list/CoursesList";
import ModalAddFolder from "../../../ui/modal/modal-add-folder/ModalAddFolder";
import ModalAddCourse from "../../../ui/modal/modal-add-course/ModalAddCourse";
import {ReactComponent as SearchIcon} from "../../../../assets/images/global/search-icon.svg";
import React, {useEffect, useState} from "react";
import {useMutation} from "react-query";
import {ISearchLesson, ISearchLessonPost} from "../../../../models/lessons";
import {LessonsService} from "../../../../services/lessons.service";
import {AxiosError} from "axios";
import LessonItem from "./lessons-item/LessonItem";

const LessonList = () => {

    const [error, setError] = useState<number | null>(null)
    const [lessons, setLessons] = useState<ISearchLesson[]>([])
    const [countLeft, setCountLeft] = useState<number | null>(null)
    const [searchTerm, setSearchTerm] = useState<string>("")
    const [page, setPage] = useState<number>(1)

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
        const data: ISearchLessonPost = {
            page: 1,
            search: e.target.value
        }
        searchLessons.mutate(data)
    }

    const searchLessons = useMutation(
        (data: ISearchLessonPost) => LessonsService.searchLessons(data),
        {
            onSuccess: (response) => {
                if(page > 1) {
                    response.lessons.map(lesson => {
                        setLessons(prev => [...prev, lesson]);
                    })
                } else {
                    setLessons(response.lessons)
                }
                setCountLeft(response.left)
                console.log(response)
            },
            onError: (error) => {
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    useEffect(() => {
        if(searchTerm && page > 1) {
            const data: ISearchLessonPost = {
                page: page,
                search: searchTerm
            }
            searchLessons.mutate(data)
        }
    }, [page]);

    return (
        <>
            <div className="h-full">
                <div className="flex flex-col gap-[34px] max-lg:gap-[16px] h-full">
                    <div className="flex justify-between items-center max-lg:flex-col max-lg:gap-[10px] max-lg:items-start">
                        <h2 className="font-bold text-[36px] leading-[120%]">Поиск уроков</h2>
                    </div>
                    <div className="w-full flex gap-[12px] max-lg:flex-col items-center text-[16px] max-lg:gap-[16px]">
                        <div className="relative w-full">
                            <SearchIcon className="absolute top-[8px] left-[10px]"/>
                            <input
                                onChange={handleSearch}
                                type="text"
                                placeholder="Поиск"
                                className="border-solid w-full bg-[#F2F2F2] border-[1px] border-[#BDBDBD] rounded-[5px] py-[8px] pr-[12px] pl-[34px] focus:outline-none focus:border-[#17AB57]"/>
                        </div>
                    </div>
                    {error && <ErrorMessage numberError={error}/>}
                    {searchTerm && (
                        <>
                            <div
                                className="bg-white p-[32px] rounded-[6px] flex flex-col text-[16px] font-medium max-lg:px-[14px] max-lg:py-[2px] max-lg:gap-0 h-full">
                                {lessons.length > 0 ? (
                                    <>
                                        <div
                                            className="px-[18px] flex justify-between text-[#828282] text-[16px] font-medium whitespace-nowrap max-lg:hidden mb-[18px]">
                                            <div className="w-[30%] cursor-pointer flex gap-[4px]">Название</div>
                                            <div className="w-[30%] cursor-pointer flex gap-[4px]">Курс</div>
                                            <div className="w-[15%]">Дата создания</div>
                                            <div className="w-[15%]">Дата изменения</div>
                                            <div className="w-[10%] flex justify-end">Действия</div>
                                        </div>
                                        {lessons.map((lesson, index) =>
                                            <LessonItem
                                                key={index}
                                                lesson={lesson}/>
                                        )}
                                    </>
                                ) : (
                                    <div className="text-center">Уроков по запросу "{searchTerm}" не найдено</div>
                                )}
                            </div>
                            {countLeft !== null && countLeft > 0 && (
                                <div
                                    onClick={() => setPage(prev => prev + 1)}
                                    className="flex text-center w-full px-[16px] py-[8px] border-[1px] border-solid border-[#17AB57] justify-center cursor-pointer rounded-[5px] text-[16px] font-medium text-[#292B2C] leading-[120%]">
                                    Показать ещё
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default LessonList