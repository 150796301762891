import React, {ReactNode} from "react";

interface Modal {
    children: ReactNode
    widthModal?: number
}

const Modal = (
    {
        children,
        widthModal
    }: Modal
) => {

    return (
        <div className="fixed top-0 left-0 flex tablet:items-center tablet:justify-center max-tablet:items-end bg-[#00000052] w-screen h-screen font-roboto leading-[120%] not-italic z-[9999]">
            <div
                className="bg-white w-[780px] rounded-[20px] p-[28px] max-tablet:w-full max-tablet:p-[24px] max-tablet:rounded-b-none"
                onClick={e => {
                    e.stopPropagation();
                }}>
                {children}
            </div>
        </div>
    )
}

export default Modal