import React, {useEffect, useState} from "react";
import {ReactComponent as LessonIcon} from "../../../../../../assets/images/courses/lesson-icon.svg";
import {ReactComponent as TestIcon} from "../../../../../../assets/images/courses/test-icon.svg";
import {ILessonsAttachInUser} from "../../../../../../models/lessons";
import {FieldArrayWithId, useFormContext} from "react-hook-form";
import {ReactComponent as DragLessonIcon} from "../../../../../../assets/images/courses/drap-lesson-icon.svg";
import {ReactComponent as CopyIcon} from "../../../../../../assets/images/global/copy-icon.svg";
import {Link} from "react-router-dom";
import {DraggableProvidedDragHandleProps} from "react-beautiful-dnd";

interface IUserAttachLessonsItemActive {
    index: number
    toggleLessonActive: (lesson: number, e: React.ChangeEvent<HTMLInputElement>, index: number) => void
    item: FieldArrayWithId<ILessonsAttachInUser, "lessons">
    dragHandleProps: DraggableProvidedDragHandleProps | null | undefined
}

const UserAttachLessonsItemActive = (
    {
        item,
        toggleLessonActive,
        index,
        dragHandleProps
    }: IUserAttachLessonsItemActive
) => {

    const {
        register,
        formState: {errors},
        setValue
    } = useFormContext<ILessonsAttachInUser>()

    const [activeCopy, setActiveCopy] = useState<boolean>(false)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        toggleLessonActive(Number(event.target.value), event, index)
    }

    const handleChangeHour = (e: React.ChangeEvent<HTMLInputElement>) => {
        let name = e.target.name.split('.')

        // if(Number(e.target.value) > 0) {
        //     item.hours = Number(e.target.value)
        // } else {
        //     setValue(`lessons.${Number(name[1])}.hours`, Math.abs(Number(e.target.value)), {shouldValidate: true})
        //     item.hours = Math.abs(Number(e.target.value))
        // }

        item.hours = Math.abs(Number(e.target.value))
        setValue(`lessons.${Number(name[1])}.hours`, Math.abs(Number(e.target.value)), {shouldValidate: true})

        if (Number(e.target.value) > 9999) {
            item.hours = 9999
            setValue(`lessons.${Number(name[1])}.hours`, 9999, {shouldValidate: true})
        }
    }

    const handeClick = () => {
        navigator.clipboard.writeText(item.name)
        setActiveCopy(true)
        setTimeout(() => {
            setActiveCopy(false)
        }, 5000)
    }

    const [currentIndexItem, setCurrentIndex] = useState<number>(index)

    useEffect(() => {
        setCurrentIndex(++index)
    }, [index]);



    return (
        <div className="flex gap-[40px] items-center w-full max-tablet:flex-col max-tablet:gap-[16px]">
            <input type="hidden" {...register(`lessons.${index}.lessonId` as const)}/>
            <input type="hidden" {...register(`lessons.${index}.order` as const)}/>
            <div className="w-full pr-[40px] flex items-center gap-[8px] max-laptop:p-0">
                <div {...dragHandleProps}>
                    <DragLessonIcon
                        className="flex-shrink-0"/>
                </div>
                <div className="flex items-center gap-[12px]">
                    {item.testId ?
                        <TestIcon className="flex-shrink-0 fill-[#2F80ED]"/> :
                        <LessonIcon className="flex-shrink-0 fill-[#17AB57]"/>
                    }
                    <div className="flex flex-col gap-[4px] text-[16px] leading-[120%]">
                        <div className="inline-flex">
                            <Link target="_blank" className="hover:underline text-[#292B2C] leading-[120%] font-[500] max-tablet:break-all"
                                  to={item.testId ?
                                      `/folders/preview-test/${item.lessonId}/${item.testId}?attach-lesson=true` : `/folders/preview-lesson/${item.lessonId}?attach-lesson=true`}>
                                {currentIndexItem}. {item.name}
                            </Link>
                            {/*<CopyIcon onClick={handeClick} className={`flex-shrink-0 cursor-pointer ` + (activeCopy ? 'fill-[#219653]' : 'fill-[#BDBDBD]' )}/>*/}
                        </div>
                        <div
                            className="text-[14px] leading-[120%] text-[#BDBDBD] font-[400]">{item.testId ? "Тест" : "Лекция"}</div>
                    </div>
                </div>
            </div>
            <div className="flex gap-[40px] items-center max-tablet:gap-[16px] max-tablet:w-full">
                <div className="w-[120px] max-tablet:w-full">
                    <input
                        type="number"
                        placeholder="Часы"
                        {...register(`lessons.${index}.hours` as const, {required: true, min: 1, max: 9999})}
                        onChange={e => handleChangeHour(e)}
                        className="w-full p-[16px] max-tablet:px-[16px] max-tablet:py-[8px] rounded-[12px] border-solid border-[1px] border-[#BDBDBD]"/>
                    {errors?.lessons?.[index]?.hours && (
                        <p className="text-red-600">Укажите часы верно</p>
                    )}
                </div>
                <label
                    className="inline-flex items-center cursor-pointer gap-[12px]">
                    <input
                        id={`checkbox-${item.lessonId}`}
                        name={`checkbox-${item.lessonId}`}
                        type="checkbox"
                        value={item.lessonId}
                        className="sr-only peer"
                        checked={true}
                        onChange={(event) => handleChange(event)}/>
                    <div
                        className="relative
                            w-[52px]
                            h-[28px]
                            bg-[#F2F2F2]
                            rounded-[50px]
                            peer
                            p-[2px]
                            peer-focus:outline-none
                            peer-checked:after:translate-x-full
                            rtl:peer-checked:after:-translate-x-full
                            after:content-['']
                            after:absolute
                            after:top-[2px]
                            after:start-[2px]
                            after:bg-[#BDBDBD]
                            peer-checked:after:bg-[#17AB57]
                            after:rounded-full
                            after:h-[24px]
                            after:w-[24px]
                            after:transition-all"/>
                </label>
            </div>
        </div>
    )
}

export default UserAttachLessonsItemActive
