import Modal from "../Modal";
import Portal from "../portal/Portal";
import React from "react";
import {ReactComponent as CloseIcon} from "../../../../assets/images/global/close.svg";
import {ReactComponent as FileIcon} from "../../../../assets/images/courses/lesson-file-icon.svg";
import {ReactComponent as ArrowLinkIcon} from "../../../../assets/images/global/arrow-link-icon.svg";
import ScrollContainer from "../../scroll-container/ScrollContainer";

interface IModalDocumentsInLoginProps {
    onClose: () => void
}

interface IDocument {
    pathname: string
    name: string
}

const ModalDocumentsInLogin = (
    {
        onClose
    }: IModalDocumentsInLoginProps
) => {

    const documents: IDocument[] = [
        {
            pathname: "/6_1_Инструкция_по_установке_и_эксплуатации_СДО.pdf",
            name: "Инструкция по установке и эксплуатации СДО"
        },
        {
            pathname: "/6_Функциональные_характеристики_и_архитектура_СДО.pdf",
            name: "Функциональные характеристики и архитектура СДО"
        }
    ]

    return (
        <Portal>
            <Modal>
                <div
                    className="flex flex-col gap-[40px] max-tablet:gap-[36px] max-tablet:max-h-[500px] max-tablet:overflow-y-auto">
                    <div className="flex flex-col gap-[24px] max-tablet:gap-[16px]">
                        <div className="flex justify-between items-center font-[500] text-[20px]">
                            Документы
                            <CloseIcon className="cursor-pointer w-[24px] h-[24px]" onClick={onClose}/>
                        </div>
                        <div className="flex flex-col gap-[8px]">
                            {documents.map((document, index) => (
                                <a
                                    key={index}
                                    className="flex gap-[12px] items-center py-[20px] px-[16px] max-tablet:items-start rounded-[12px] bg-[#F7F7F7]"
                                    href={document.pathname}
                                    target="_blank">
                                    <FileIcon className="fill-[#17AB57] flex-shrink-0"/>
                                    <span
                                        className="w-full text-[16px] leading-[120%] font-[500] text-[#292B2C]">{document.name}</span>
                                    <ArrowLinkIcon className="flex-shrink-0"/>
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className="flex flex-col gap-[24px]">
                        <div className="flex justify-between items-center font-[500] text-[20px]">
                            Информация о стоимости программного обеспечения
                        </div>
                        <div>
                            Стоимость программного обеспечения рассчитывается индивидуально в зависимости
                            от задач каждого конкретного пользователя. Для получения информация о стоимости
                            необходимо
                            связаться
                            по следующему телефону: <a className="text-[#17AB57] underline"
                                                       href="tel:88003500545">8(800)350-05-45</a> или по
                            адресу
                            электронной
                            почты <a className="text-[#17AB57] underline"
                                     href="mailto:zayavki@apokdpo.ru">zayavki@apokdpo.ru</a>.
                            <br/>
                            <br/>
                            СДО "АПОК СТАДИ" распространяется в виде интернет-сервиса, специальные действия
                            по
                            установке СДО
                            на
                            стороне пользователя не требуются.
                        </div>
                    </div>
                </div>
            </Modal>
        </Portal>
    )
}

export default ModalDocumentsInLogin