import {
    ICoursesGroup,
    IGroup, IGroupAttachCourse, IGroupAttachCourseForUser,
    IGroupDetail,
    IGroupDetailId,
    IGroupLeft,
    IGroupProgress,
    IGroupUser
} from "../models/groups";
import api from "./api";
import {cleanParams} from "../helpers/WorkWithGroup";

export const GroupsService = {
    async getAll() {
        const {data} = await api.get<IGroup[]>('/api/Group')
        return data
    },

    async create(data: IGroupDetail) {
        const response = await api.post<IGroup>('/api/Group/add', data)
        return response.data
    },

    async rename(id: number, data: IGroupDetail) {
        const response = await api.post<IGroup>(`/api/Group/rename/${id}`, data)
        return response.data
    },

    async update(id: number, data: IGroupDetail) {
        const response = await api.post<IGroup>(`/api/Group/update/${id}`, data)
        return response.data
    },

    async delete(dataDelete: IGroupDetailId) {
        const {data} = await api.post<boolean>('/api/Group/delete', dataDelete)
        return data
    },

    async getById(id: number) {
        const {data} = await api.get<IGroup>(`/api/Group/${id}`)
        return data
    },

    async deleteUsers(dataDelete: IGroupDetailId) {
        const {data} = await api.post<IGroup>(`/api/Group/deleteUsers`, dataDelete)
        return data
    },

    async attachUser(dataUser: IGroupUser) {
        const {data} = await api.post<IGroup>(`/api/Group/attachUser`, dataUser)
        return data
    },

    async getByPage(page: number, search: string | undefined, sortField: string, sortDirection: string) {
        const params = {
            search: search,
            page: page,
            sortField: sortField,
            sortDirection: sortDirection
        }
        const cleanedParams = cleanParams(params);
        const queryString = new URLSearchParams(cleanedParams).toString()
        const {data} = await api.get<IGroup[]>(`/api/Group/getByPage?${queryString}`)
        return data
    },

    async getProgress(id: number) {
        const {data} = await api.get<IGroupProgress>(`/api/Group/viewProgress/${id}`)
        return data
    },

    async getCourses() {
        const {data} = await api.get<ICoursesGroup[]>(`/api/Group/courses`)
        return data
    },

    async updateUserCourse(dataSubmit: IGroupAttachCourse) {
        const {data} = await api.post<IGroup>(`/api/Group/updateUserCourse`, dataSubmit)
        return data
    },

    async attachCourseForUserInGroup(dataSubmit: IGroupAttachCourseForUser) {
        const {data} = await api.post<IGroup>(`/api/Group/attachCourseForUserInGroup`, dataSubmit)
        return data
    }
}