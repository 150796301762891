import {IGroupUserItem} from "../../../../../../models/groups";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import {ReactComponent as UsersIcon} from "../../../../../../assets/images/menu/users-icon.svg";
import {ReactComponent as EditIcon} from "../../../../../../assets/images/global/edit-icon.svg";
import {ReactComponent as DeleteIcon} from "../../../../../../assets/images/global/delete.svg";
import {ReactComponent as DeleteCourseIcon} from "../../../../../../assets/images/global/close.svg";
import {IUserCourseUntie} from "../../../../../../models/users";
import {useMutation, useQueryClient} from "react-query";
import {UsersService} from "../../../../../../services/users.service";
import Loader from "../../../../../ui/loader/Loader";
import {AxiosError} from "axios";
import {IDropdownMenu} from "../../../../../../models/dropdownmenu";
import DropdownMenu from "../../../../../ui/dropdown-menu/DropdownMenu";
import {endWord} from "../../../../../../helpers/WorkWithWord";

interface IGroupsDetailUserItem {
    user: IGroupUserItem
    setUserIdForAddCourse: (id: number) => void,
    groupId: number
}

const GroupsDetailUserItem = (
    {
        user,
        setUserIdForAddCourse,
        groupId
    }: IGroupsDetailUserItem
) => {

    const queryClient = useQueryClient()
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [showListCourse, setShowListCourse] = useState<boolean>(false)

    const handleDeleteCourse = (courseId: number) => {
        let untieSuccess = window.confirm("Вы действительно хотите отвязать курс у пользователя? Это действие необратимо")
        if (untieSuccess) {
            setShowLoader(true)
            const data: IUserCourseUntie = {
                "userId": user.id,
                "courseId": courseId
            }
            untieCourse.mutate(data)
        }
    }

    const untieCourse = useMutation(
        (data: IUserCourseUntie) => UsersService.untieCourse(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["group", groupId])
                setShowLoader(false)
            },
            onError: (error) => {
                setShowLoader(false)
            }
        }
    )

    const handleExcludeUser = () => {
        let deleteSuccess = window.confirm("Вы действительно хотите отвязать пользователя?")
        if (deleteSuccess) {
            setShowLoader(true)
            excludeUser.mutate()
        }
    }

    const excludeUser = useMutation(
        () => UsersService.excludeGroup(user.id),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["group", groupId])
                setShowLoader(false)
            },
            onError: (error) => {
                console.log(error)
                setShowLoader(false)
                const e = error as AxiosError
                // setError(e.response ? e.response.status : 400)
            }
        }
    )

    const handleAddCourse = () => {
        setUserIdForAddCourse(user.id)
    }

    const listDropdownMenu: IDropdownMenu[] = [
        {
            name: "Назначить курс",
            action: handleAddCourse,
            svg: <EditIcon/>
        },
        {
            name: "Удалить",
            svg: <DeleteIcon/>,
            color: "#EB5757",
            action: handleExcludeUser
        }
    ]

    return (
        <>
            {showLoader && (<Loader/>)}
            <div
                className={`flex w-full py-[12px] px-[16px] max-tablet:py-[16px] max-tablet:px-[24px] max-tablet:flex-col max-tablet:rounded-[12px] max-tablet:gap-[20px] ${showListCourse ? "bg-[#F2F2F2] max-tablet:bg-[#FFF] rounded-[12px]" : "bg-white"}`}>
                <div
                    className="w-[calc(100%/3-24px)] max-tablet:flex max-tablet:w-full max-tablet:justify-between max-tablet:items-start">
                    <div className="flex gap-[8px]">
                        <UsersIcon className="flex-shrink-0" fill="#17AB57"/>
                        <Link to={`/users/${user.id}`} target='_blank'
                              className="flex flex-col gap-[4px] text-[16px] font-[500] leading-[120%] text-[#292B2C]">
                            <span>{user.lastName} {user.firstName} {user.fatherName}</span>
                            <span className="font-[400]">{user.email}</span>
                            <span className="font-[400] text-[#BDBDBD]">{user.role.name}</span>
                        </Link>
                    </div>
                    <div className="hidden max-tablet:flex">
                        <DropdownMenu list={listDropdownMenu} size={24}/>
                    </div>
                </div>
                <div className={`w-[calc(100%/3*2)] max-tablet:w-full ${!user.courses ? `max-tablet:hidden` : ``}`}>
                    {user.courses && (
                        <div className="flex gap-[8px] max-tablet:flex-col max-tablet:gap-0">
                            <div
                                className={`max-w-[480px] max-laptop:max-w-[300px] max-tablet:max-w-full w-full flex flex-col gap-[4px] ${user.courses.length > 1 ? `max-tablet:pb-[16px] border-solid border-b-[0.5px] border-opacity-[0.3] border-[#BDBDBD]` : ``}`}>
                                <div
                                    className={`p-[8px] flex gap-[32px] items-center w-full rounded-[8px] ${showListCourse ? "bg-white max-tablet:bg-[#F2F2F2]" : "bg-[#F2F2F2]"}`}>
                                    <span className="w-full">{user.courses[0].name}</span>
                                    <DeleteCourseIcon
                                        onClick={() => {
                                            if (user.courses) handleDeleteCourse(user.courses[0].id)
                                        }}
                                        className="flex-shrink-0 hover:fill-[#EB5757] cursor-pointer"/>
                                </div>
                                {showListCourse && user.courses.length > 1 && (
                                    <>
                                        {user.courses.slice(1).map((course) => (
                                            <div key={course.id}
                                                 className={`p-[8px] flex gap-[32px] items-center w-full rounded-[8px] ${showListCourse ? "bg-white max-tablet:bg-[#F2F2F2]" : "bg-[#F2F2F2]"}`}>
                                                <span className="w-full">{course.name}</span>
                                                <DeleteCourseIcon
                                                    onClick={() => handleDeleteCourse(course.id)}
                                                    className="flex-shrink-0 hover:fill-[#EB5757] cursor-pointer"/>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                            {user.courses.length > 1 && (
                                <div className="py-[8px] cursor-pointer max-tablet:pt-[16px] max-tablet:pb-0"
                                     onClick={() => setShowListCourse(!showListCourse)}>
                                    {showListCourse ? `Свернуть` : `Показать еще ${user.courses.slice(1).length} ${endWord(user.courses.slice(1).length, "курс", "курса", "курсов")}`}
                                </div>
                            )}
                        </div>

                    )}
                </div>
                <div className="w-[74px] flex-shrink-0 flex justify-end max-tablet:hidden">
                    <DropdownMenu list={listDropdownMenu} size={42} showCircuit={showListCourse}/>
                </div>
            </div>
        </>
    )
}

export default GroupsDetailUserItem