import {useQuery} from "react-query";
import {UsersService} from "../../../../services/users.service";
import {Constants, getCustomStyleReactSelect} from "../../../../constans/constants";
import React, {useContext, useEffect, useState} from "react";
import {IUser, IUserDetail, IUserMethodist} from "../../../../models/users";
import Loader from "../../loader/Loader";
import ErrorMessage from "../../error/ErrorMessage";
import {AuthContext} from "../../../../context/AuthContext";
import {Controller, useFormContext} from "react-hook-form";
import Select from "react-select";

interface IMethodistSelectProps {
    setMethodist?: (methodist: IUserMethodist) => void
    user?: IUser
}

const MethodistSelect = (
    {
        setMethodist,
        user
    }: IMethodistSelectProps
) => {

    const {
        control,
        setValue
    } = useFormContext<IUserDetail>()

    const {currentRoleId, currentUserId} = useContext(AuthContext)
    const [optionsMethodist, setOptionsMethodist] = useState<IUserMethodist[]>([])

    const {data: methodists, isLoading: methodistsLoading, isError: methodistsError} = useQuery(
        ["list_methodist"],
        () => UsersService.getListMethodistByCompany(),
        {
            enabled: Constants.LIST_ADMIN_ROLE.indexOf(Number(currentRoleId)) !== -1,
            onSuccess: (response) => {
                response.map(element => {
                    setOptionsMethodist(prev => [...prev, {
                        id: element.id,
                        name: element.name
                    }])
                })
            }
        }
    )

    useEffect(() => {
        if(optionsMethodist) {
            if(user){
                setValue("methodist", user.methodist)
            } else {
                const defaultValue = optionsMethodist.find(el => el.id === Number(currentUserId))
                if(defaultValue) {
                    setValue("methodist", defaultValue)
                    if(setMethodist) setMethodist(defaultValue)
                }
            }
        }
    }, [optionsMethodist, user]);

    if (methodistsLoading) return <Loader/>
    if (methodistsError) return <ErrorMessage numberError={400}/>
    if (!methodists) return <ErrorMessage numberError={400}/>

    return (
        <>
            {optionsMethodist.length > 0 && (
                <div className="flex flex-col gap-[4px]">
                    <div className="text-[16px] text-[#828282]">Методист</div>
                    <Controller
                        control={control}
                        name={"methodist"}
                        rules={{
                            required: true
                        }}
                        render={({field: {onChange, value}, fieldState: {error}}) =>
                            <>
                                <Select
                                    unstyled
                                    styles={getCustomStyleReactSelect<IUserMethodist>()}
                                    menuPortalTarget={document.body}
                                    menuPlacement="top"
                                    placeholder={"Выберите методиста"}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                    noOptionsMessage={() => "Методист не найден"}
                                    isMulti={false}
                                    value={value}
                                    isSearchable={true}
                                    options={optionsMethodist}
                                    getOptionLabel={(methodist: IUserMethodist) => methodist.name}
                                    getOptionValue={(methodist: IUserMethodist) => String(methodist.id)}
                                />
                                {error && (<p className="text-red-600">Выберите
                                    методиста</p>)}
                            </>
                        }
                    />
                </div>
            )}
        </>
    )
}

export default MethodistSelect