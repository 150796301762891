import Modal from "../Modal";
import ErrorMessage from "../../error/ErrorMessage";
import {ReactComponent as CloseIcon} from "../../../../assets/images/global/close.svg";
import Portal from "../portal/Portal";
import React, {useEffect, useState} from "react";
import {useMutation} from "react-query";
import {AxiosError} from "axios";
import {SubmitHandler, useForm} from "react-hook-form";
import {IUser, IUserPasswordRepeat} from "../../../../models/users";
import {UsersService} from "../../../../services/users.service";
import {generatePassword} from "../../../../helpers/WorkWithUser";

interface IModalPassword {
    onClose: () => void
    user: IUser
}

const ModalPassword = (
    {
        onClose,
        user
    }: IModalPassword
) => {

    const generatePasswordHandler = () => {
        setValue("newPassword", generatePassword(), {shouldValidate: true})
    }

    const {
        setValue,
        register,
        handleSubmit,
        formState: {errors, isValid}
    } = useForm<IUserPasswordRepeat>()

    const [error, setError] = useState<number | null>(null)

    useEffect(() => {
        setValue("email", user.email)
    }, [user])

    const updatePassword = useMutation(
        (data: IUserPasswordRepeat) => UsersService.updatePassword(data),
        {
            onSuccess: (response) => {
                onClose()
            },
            onError: (error) => {
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const onSubmit: SubmitHandler<IUserPasswordRepeat> = async (data) => {
        updatePassword.mutate(data)
    }

    return (
        <Portal>
            <Modal>
                {error && <ErrorMessage numberError={error}/>}
                <form className="flex flex-col gap-[36px] text-[16px]">
                    <div className="flex justify-between items-center font-[500] text-[20px]">
                        Смена пароля
                        <CloseIcon className="cursor-pointer w-[24px] h-[24px]" onClick={onClose}/>
                    </div>
                    <input type="hidden" {...register("email", {required: true})}/>
                    <div className="flex flex-col gap-[4px] w-full">
                        <div className="text-[16px] text-[#828282]">Пароль</div>
                        <div className="flex gap-[12px] items-start max-tablet:flex-col">
                            <div className="w-full">
                                <input
                                    type="text"
                                    className="p-[16px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[12px]"
                                    placeholder="Введите пароль"
                                    {...register("newPassword", {required: true})}/>
                                {errors.newPassword && errors.newPassword.type === "required" && (
                                    <p className="text-red-600">Пароль обязателен</p>
                                )}
                            </div>
                            <div
                                onClick={generatePasswordHandler}
                                className="px-[20px] py-[16px] text-[#17AB57] border-solid border-[1px] border-[#17AB57] rounded-[12px] bg-white cursor-pointer max-tablet:w-full max-tablet:flex items-center justify-center font-[500]">
                                Сгенерировать
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-[12px] w-full">
                        <button
                            className="font-[500] py-[16px] px-[20px] bg-[#BDBDBD4d] rounded-[12px] w-full"
                            onClick={onClose}
                        >
                            Отмена
                        </button>
                        <button
                            type="submit"
                            disabled={!isValid}
                            onClick={handleSubmit(onSubmit)}
                            className={`font-[500] py-[16px] px-[20px] rounded-[12px] w-full ${!isValid ? "bg-[#66C28E] text-[#ffffff66]" : "bg-[#17AB57] text-[#ffffff]"}`}
                        >
                            Сохранить
                        </button>
                    </div>
                </form>
            </Modal>
        </Portal>
    )
}

export default ModalPassword