import {IQuestionDetailId, ITest} from "../../../../../models/tests";
import {ReactComponent as TestIcon} from "../../../../../assets/images/courses/test-icon.svg";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import {ReactComponent as EditIcon} from "../../../../../assets/images/global/edit-icon.svg";
import {ReactComponent as DeleteIcon} from "../../../../../assets/images/global/delete.svg";
import React, {useState} from "react";
import {useMutation, useQueryClient} from "react-query";
import {TestsService} from "../../../../../services/tests.service";
import {AxiosError} from "axios";
import Loader from "../../../../ui/loader/Loader";
import {IDropdownMenu} from "../../../../../models/dropdownmenu";
import DropdownMenu from "../../../../ui/dropdown-menu/DropdownMenu";

interface ITestsItem {
    test: ITest
    setError: (error: number) => void
}

const TestsItem = (
    {
        test,
        setError
    }: ITestsItem
) => {

    const queryClient = useQueryClient()
    const [showLoader, setShowLoader] = useState<boolean>(false)

    const deleteTest = useMutation(
        (data: IQuestionDetailId) => TestsService.delete(data),
        {
            onSuccess: () => {
                setShowLoader(false)
                queryClient.invalidateQueries(["tests"])
            },
            onError: (error) => {
                setShowLoader(false)
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const handleDelete = () => {
        let deleteSuccess = window.confirm("Вы действительно хотите удалить тест? Это действие необратимо")
        if(deleteSuccess) {
            setShowLoader(true)
            deleteTest.mutate({"id": test.id})
        }
    }

    const listDropdownMenu: IDropdownMenu[] = [
        {
            name: "Редактировать",
            link: `/tests/${test.id}`,
            svg: <EditIcon/>
        },
        {
            name: "Удалить",
            svg: <DeleteIcon/>,
            color: "#EB5757",
            action: handleDelete
        }
    ]

    return (
        <>
            {showLoader && <Loader/>}
            <div
                className="text-[#292B2C] items-center flex text-[16px] leading-[120%] py-[12px] px-[16px] font-[400] hover:bg-[#F2F2F2]
                max-tablet:mb-[12px] max-tablet:bg-white max-tablet:rounded-[12px] max-tablet:p-[24px] max-tablet:flex-col max-tablet:items-start max-tablet:hover:bg-white max-tablet:gap-[16px]">
                <div className="w-[calc(50%-12px)] max-tablet:flex max-tablet:w-full max-tablet:justify-between">
                    <Link to={`/tests/${test.id}`}
                          className="font-[500] flex items-center gap-[8px] max-tablet:items-start">
                        <TestIcon className="flex-shrink-0 fill-[#2F80ED]"/>
                        {test.name}
                    </Link>
                    <div className="hidden max-tablet:flex">
                        <DropdownMenu list={listDropdownMenu} size={24}/>
                    </div>
                </div>
                <div className="w-[25%] max-tablet:w-full leading-[120%] flex gap-[16px] text-[16px] font-[400]">
                    <span className="tablet:hidden text-[14px] text-[#828282]">Дата создания</span>{dayjs(test.createdDate).format("DD.MM.YYYY HH:mm")}
                </div>
                <div className="w-[25%] max-tablet:w-full leading-[120%] flex gap-[16px] text-[16px] font-[400]">
                    <span className="tablet:hidden text-[14px] text-[#828282]">Дата изменения</span>{dayjs(test.updatedDate).format("DD.MM.YYYY HH:mm")}
                </div>
                <div className="w-[74px] flex-shrink-0 flex justify-end max-tablet:hidden">
                    <DropdownMenu list={listDropdownMenu} size={42}/>
                </div>
            </div>
        </>
    )
}

export default TestsItem