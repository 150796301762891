import {IUser, IUserCourseAttach} from "../../../../../../../../models/users";
import {ReactComponent as UsersIcon} from "../../../../../../../../assets/images/menu/users-icon.svg";
import {ReactComponent as AddUser} from "../../../../../../../../assets/images/courses/add-user-icon.svg";
import React from "react";
import {useMutation, useQueryClient} from "react-query";
import {CoursesService} from "../../../../../../../../services/courses.service";

interface ICourseUserSearchItem {
    user: IUser
    courseId: number
    setShowSearchResult: (flag: boolean) => void
    setSearchTerm: (name: string) => void
    onClearInput: () => void
}

const CourseUserSearchItem = (
    {
        user,
        courseId,
        setShowSearchResult,
        setSearchTerm,
        onClearInput
    }: ICourseUserSearchItem
) => {

    const queryClient = useQueryClient()

    const attachUser = useMutation(
        (addUser: IUserCourseAttach) => CoursesService.attachUser(addUser),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["users_course"])
                queryClient.invalidateQueries(['list_lessons_course', Number(courseId)])
                setShowSearchResult(false)
                setSearchTerm("")
                onClearInput()
            }
        }
    )

    const handleAttach = () => {
        const data: IUserCourseAttach = {
            courseId: courseId,
            userId: user.id
        }
        attachUser.mutate(data)
    }

    return (
        <div
            className="flex justify-between border-solid border-b-[2px] border-[#BDBDBD] last:border-none py-[16px] last:pb-[18px] first:pt-[18px] items-center px-[18px] ">
            <div className="flex items-center gap-[8px] leading-[120%] text-[16px] text-[#292B2C] font-medium">
                <UsersIcon fill="#17AB57"/>
                {user.lastName} {user.firstName} {user.fatherName} <span className="text-[#BDBDBD]">({user.email})</span>
            </div>
            <div
                onClick={handleAttach}
                className="cursor-pointer rounded-[5px] flex gap-[6px] py-[8px] pl-[12px] pr-[16px] bg-[#17AB57] items-center text-[16px] text-[#FFFFFF]">
                <AddUser className="flex-shrink-0"/>
                Добавить
            </div>
        </div>
    )
}

export default CourseUserSearchItem