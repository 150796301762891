import {useQuery, useQueryClient} from "react-query";
import {ChatService} from "../../../services/chat.service";
import React, {useContext, useEffect, useState} from "react";
import Cookies from "universal-cookie";
import Loader from "../../ui/loader/Loader";
import ErrorMessage from "../../ui/error/ErrorMessage";
import ChatsItem from "./chat-item/ChatsItem";
import ScrollContainer from "../../ui/scroll-container/ScrollContainer";
import ChatDetail from "./chat-detail/ChatDetail";
import {HubConnectionBuilder} from "@microsoft/signalr";
import {Constants} from "../../../constans/constants";
import {AuthContext} from "../../../context/AuthContext";
import Search from "../../ui/search/Search";
import {useSearchParams} from "react-router-dom";

const Chats = () => {

    const hubConnection = new HubConnectionBuilder()
        .withUrl(Constants.LOCATION + "/chat")
        .withAutomaticReconnect()
        .build();

    const cookies = new Cookies()
    const queryClient = useQueryClient();

    const [searchParams, setSearchParams] = useSearchParams();
    const [activeChatId, setActiveChatId] = useState<number | null>(null)
    const [search, setSearch] = useState<string>("")
    const {authenticated, setAuthenticated, currentRoleId, currentUserId} = useContext(AuthContext)

    const {data: listChats, isLoading, isError} = useQuery(
        ["list_methodist", Number(currentUserId)],
        () => ChatService.getListByMethodist(Number(currentUserId)),
        {
            onSuccess: async (response) => {
                await hubConnection.start()
                    .then((connection) => {
                    })
                    .catch((err) => {
                        console.error('Connection failed: ', err);
                    });
                if(searchParams.get("active_id")) setActiveChatId(Number(searchParams.get("active_id")))
                searchParams.delete("active_id")
                setSearchParams(searchParams)
            }
        }
    )

    hubConnection.on("UpdateListMethodist", function (response: number) {
        if (response) {
            queryClient.invalidateQueries(["list_methodist", Number(response)]);
        }
    });

    if (isLoading) return <Loader/>
    if (isError) return <ErrorMessage numberError={400}/>
    if (!listChats) return <ErrorMessage numberError={400}/>

    return (
        <div className="flex w-full h-full">
            <div className={`w-[400px] max-laptop:w-[320px] max-tablet:w-full bg-white max-tablet:bg-[#F7F7F7] h-full border-solid border-x-[0.5px] border-[#BDBDBD] border-opacity-[0.3] flex flex-col max-tablet:gap-[28px] flex-shrink-0 ${activeChatId !== null ? "max-tablet:hidden" : ""}`}>
                <div className="p-[24px] max-tablet:px-[16px] max-tablet:pb-0 max-tablet:pt-[28px] flex flex-col gap-[10px] max-tablet:gap-[28px]">
                    <span className="text-[24px] font-[700] leading-[120%]">Сообщения</span>
                    <Search setSearchTerm={setSearch}/>
                </div>
                <ScrollContainer>
                    <div className="flex-col flex px-[8px] max-tablet:px-[16px] gap-[4px]">
                        {listChats
                            .filter(item => item.lastName.toLowerCase().includes(search.toLowerCase()) || item.lastName.toLowerCase().includes(search.toLowerCase()))
                            .map((item, i) => (
                                <ChatsItem chat={item} setActiveChat={setActiveChatId} activeChatId={activeChatId}
                                           key={i}/>
                            ))}
                    </div>
                </ScrollContainer>
            </div>
            <div className={`bg-[#F7F7F7] h-full w-full ${activeChatId !== null ? "max-tablet:block" : "max-tablet:hidden"}`}>
                {activeChatId !== null && (
                    <ChatDetail setActiveChatId={setActiveChatId} activeChat={activeChatId}/>
                )}
            </div>
        </div>

    )
}

export default Chats