import {IFile} from "../../../models/file";
import React, {ChangeEvent, ChangeEventHandler, useEffect, useRef, useState} from "react";
import DocViewer from "@cyntler/react-doc-viewer";
import {Document, Page} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import {IDocument} from "@cyntler/react-doc-viewer/dist/models";
import "@cyntler/react-doc-viewer/dist/index.css";
import ScrollContainer from "../scroll-container/ScrollContainer";
import {ReactComponent as FullScreenIcon} from "../../../assets/images/courses/fullscreen-icon.svg";
import {ReactComponent as ResetZoomIcon} from "../../../assets/images/global/refresh-icon.svg";
import {ReactComponent as ZoomInIcon} from "../../../assets/images/courses/zoom-in-icon.svg";
import {ReactComponent as ZoomOutIcon} from "../../../assets/images/courses/zoom-out-icon.svg";
import {ReactComponent as NextPrevPageIcon} from "../../../assets/images/global/download.svg";

interface IPreviewLessonDocumentProps {
    file: IFile
}

const PreviewLessonDocument = (
    {
        file
    }: IPreviewLessonDocumentProps
) => {

    const [embed, setEmbed] = useState<string[]>([]);
    const [docs, setDocs] = useState<IDocument[]>([]);
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfError, setPdfError] = useState<string | null>(null);
    const [pdfUrl, setPdfUrl] = useState<string | null>(null);
    const [scale, setScale] = useState<number>(1.0);
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
    const pdfContainerRef = useRef<HTMLDivElement>(null);
    const pdfDocumentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!file) return;

        let domain = "https://storage.yandexcloud.net/apokdpo-sdo/";
        const location = window.location.origin;

        if (location.includes("localhost:3000") || location.includes("dpo-plus.ru")) {
            domain = "https://storage.yandexcloud.net/for-testing-sdo/";
        }

        const fullPath = domain + file.pathName;

        if (file.extention === '.pdf') {
            checkPdfFile(fullPath);
        } else if (file.extention === '.youtu') {
            setEmbed(fullPath.split("/"));
        } else if (!['.pdf', '.youtu', '.mp4'].includes(file.extention)) {
            setDocs(prev => [...prev, {uri: fullPath}]);
        }
    }, [file]);

    const checkPdfFile = async (url: string) => {
        try {
            const response = await fetch(url, {method: 'HEAD'});

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const contentType = response.headers.get('content-type');
            if (!contentType?.includes('application/pdf')) {
                throw new Error('URL does not point to a PDF file');
            }

            setPdfUrl(url);
            setPdfError(null);
        } catch (error) {
            console.error('PDF load failed:', error);
            setPdfError('Не удалось загрузить PDF файл');
        }
    };

    const onDocumentLoadSuccess = ({numPages}: { numPages: number }) => {
        setNumPages(numPages);
        setPdfError(null);
    };

    const zoomIn = () => setScale(prev => Math.min(prev + 0.1, 3.0));
    const zoomOut = () => setScale(prev => Math.max(prev - 0.1, 0.5));
    const resetZoom = () => setScale(1.0);

    const toggleFullscreen = () => {
        setIsFullscreen(!isFullscreen)
        // if (!document.fullscreenElement) {
        //     pdfContainerRef.current?.requestFullscreen()
        //         .then(() => setIsFullscreen(true))
        //         .catch(console.error);
        // } else {
        //     document.exitFullscreen()
        //         .then(() => setIsFullscreen(false));
        // }
    };

    const handleChangePageNumber = (e: ChangeEvent<HTMLInputElement>) => {
        const page = Math.abs(Number(e.target.value))
        if (numPages && page > numPages) {
            setPageNumber(numPages);
        } else if (page < 1) {
            setPageNumber(1);
        } else {
            setPageNumber(Number(e.target.value));
        }
    }

    useEffect(() => {
        if (pdfDocumentRef.current) {
            pdfDocumentRef.current.scrollTo({
                top: 0
            })
        }
    }, [pageNumber]);

    return (
        <div
            className="bg-white rounded-[20px] p-[36px] max-tablet:p-[24px] h-[600px] max-tablet:h-[500px] flex">
            {file.extention === '.pdf' && (
                <>
                    {pdfError ? (
                        <div className="text-red-500">{pdfError}</div>
                    ) : (
                        <div ref={pdfContainerRef}
                             className={`flex flex-col gap-[20px] max-tablet:gap-[12px] flex-grow overflow-hidden ${isFullscreen ? 'fullscreen' : ''}`}>
                            <div className="flex gap-[36px] items-center justify-center max-tablet:flex-row-reverse max-tablet:gap-0 max-tablet:justify-between">
                                <div
                                    className="flex gap-[12px] items-center py-[16px] px-[20px] bg-[#BDBDBD4D] rounded-[12px] cursor-pointer"
                                    onClick={toggleFullscreen}>
                                    <FullScreenIcon className="flex-shrink-0"/>
                                    <span className="max-tablet:hidden">Полноэкранный режим</span>
                                </div>
                                <div className="flex gap-[12px] items-center">
                                    <div onClick={zoomIn}
                                         className="py-[16px] px-[20px] bg-[#BDBDBD4D] rounded-[12px] cursor-pointer">
                                        <ZoomInIcon className="flex-shrink-0"/>
                                    </div>
                                    <div onClick={resetZoom}
                                         className="p-[16px] border border-[#BDBDBD] rounded-[12px] cursor-pointer flex gap-[8px] items-center">
                                        <ResetZoomIcon className="flex-shrink-0 scale-x-[-1]"/>
                                        <span className="max-tablet:hidden">100%</span>
                                    </div>
                                    <div onClick={zoomOut}
                                         className="py-[16px] px-[20px] bg-[#BDBDBD4D] rounded-[12px] cursor-pointer">
                                        <ZoomOutIcon className="flex-shrink-0"/>
                                    </div>
                                </div>
                                <div className="max-tablet:hidden flex gap-[12px] items-center">
                                    <div onClick={() => setPageNumber(prev => Math.max(prev - 1, 1))}
                                         className="py-[16px] px-[20px] bg-[#BDBDBD4D] rounded-[12px] cursor-pointer">
                                        <NextPrevPageIcon className="flex-shrink-0 rotate-90 fill-black"/>
                                    </div>
                                    <input
                                        className="p-[16px] border border-[#BDBDBD] rounded-[12px]"
                                        type="number"
                                        min={1}
                                        value={pageNumber}
                                        onChange={(e) => handleChangePageNumber(e)}
                                        max={numPages ? numPages : 1}/>
                                    <div
                                        className="text-[#828282] text-[16px] leading-[120%] font-[500]">из {numPages}</div>
                                    <div
                                        onClick={() => setPageNumber(prev => Math.min(prev + 1, numPages ? numPages : 1))}
                                        className="py-[16px] px-[20px] bg-[#BDBDBD4D] rounded-[12px] cursor-pointer">
                                        <NextPrevPageIcon className="flex-shrink-0 scale-y-[-1] rotate-90 fill-black"/>
                                    </div>
                                </div>
                            </div>
                            <div
                                ref={pdfDocumentRef}
                                className={`flex justify-center overflow-y-auto overflow-x-auto ${isFullscreen ? 'h-screen' : ''}`}>
                                <Document
                                    file={pdfUrl}
                                    onLoadError={(error) => {
                                        console.error('PDF render error:', error);
                                        setPdfError('Ошибка отображения PDF');
                                    }}
                                    loading={<div>Загрузка PDF...</div>}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    className="h-full overflow-auto"
                                >
                                    <Page
                                        className="border border-[#BDBDBD] "
                                        scale={scale}
                                        pageNumber={pageNumber}
                                        loading={<div>Загрузка страницы {pageNumber}...</div>}
                                        renderAnnotationLayer={false}
                                        renderTextLayer={false}
                                        renderMode="canvas"
                                    />
                                </Document>
                            </div>
                            <div className="tablet:hidden flex gap-[12px] items-center w-full justify-center">
                                <div onClick={() => setPageNumber(prev => Math.max(prev - 1, 1))}
                                     className="py-[16px] px-[20px] bg-[#BDBDBD4D] rounded-[12px] cursor-pointer w-full flex justify-center">
                                    <NextPrevPageIcon className="flex-shrink-0 rotate-90 fill-black"/>
                                </div>
                                <input
                                    className="p-[16px] border border-[#BDBDBD] rounded-[12px] flex justify-center"
                                    type="number"
                                    min={1}
                                    value={pageNumber}
                                    onChange={(e) => handleChangePageNumber(e)}
                                    max={numPages ? numPages : 1}/>
                                <div
                                    className="text-[#828282] text-[16px] leading-[120%] font-[500] whitespace-nowrap">из {numPages}</div>
                                <div
                                    onClick={() => setPageNumber(prev => Math.min(prev + 1, numPages ? numPages : 1))}
                                    className="py-[16px] px-[20px] bg-[#BDBDBD4D] rounded-[12px] cursor-pointer w-full flex justify-center">
                                    <NextPrevPageIcon className="flex-shrink-0 scale-y-[-1] rotate-90 fill-black"/>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
            {file.extention === '.mp4' && (
                <video>
                    <source src={file.pathName}/>
                </video>
            )}
            {file.extention === '.youtu' && (
                <iframe src={`https://www.youtube.com/embed/${embed[embed.length - 1]}`}/>
            )}
            {file.extention !== '.pdf' && file.extention !== '.mp4' && file.extention !== '.youtu' && (
                <DocViewer documents={docs}/>
            )}
        </div>
    )
}


export default PreviewLessonDocument