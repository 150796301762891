import {IUserCourses} from "../../../../../../models/users";
import {useQuery} from "react-query";
import {UsersService} from "../../../../../../services/users.service";
import ErrorMessage from "../../../../../ui/error/ErrorMessage";
import React, {useState} from "react";
import {ReactComponent as PlusIcon} from "../../../../../../assets/images/global/plus-icon.svg";
import useToggle from "../../../../../../hooks/useToggle";
import ModalAttachCourseUser from "../../../../../ui/modal/modal-attach-course-user/ModalAttachCourseUser";
import {useParams} from "react-router-dom";
import Loader from "../../../../../ui/loader/Loader";
import ArrowSort from "../../../../../ui/arrow-sort/ArrowSort";
import UserCoursesItem from "./user-courses-item/UserCoursesItem";

const UserCourses = () => {

    const {id} = useParams()

    const [filteredCourses, setFilteredCourses] = useState<IUserCourses[]>([])
    const [isModalAttachCourse, setOnModalOpenAttachCourse, setOnModalCloseAttachCourse] = useToggle()
    const [error, setError] = useState<number | null>(null)
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [sortField, setSortField] = useState<string>('Course.Name')
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc")

    const {data: courses, isError: coursesError, isLoading: coursesLoading} = useQuery(
        ["user_courses", sortField, sortDirection],
        () => UsersService.getCourses(Number(id), sortField, sortDirection),
        {
            onSuccess: (response) => {
                setFilteredCourses(response)
            }
        }
    )

    if (coursesLoading) return <Loader/>
    if (coursesError) return <ErrorMessage numberError={400}/>
    if (!courses) return <ErrorMessage numberError={400}/>

    return (
        <>
            {error && <ErrorMessage numberError={error}/>}
            {showLoader && (<Loader/>)}
            {isModalAttachCourse && <ModalAttachCourseUser
                userId={Number(id)}
                onClose={setOnModalCloseAttachCourse}/>}
            <div
                onClick={setOnModalOpenAttachCourse}
                className="w-full py-[16px] px-[20px] rounded-[12px] bg-[#17AB57] text-white flex gap-[6px] items-center justify-center cursor-pointer">
                <PlusIcon className="flex-shrink-0"/>
                Назначить курс
            </div>
            <div className="bg-white p-[36px] rounded-[20px] flex flex-col text-[16px] max-tablet:p-0 max-tablet:bg-[#F7F7F7]">
                {filteredCourses.length > 0 ? (
                    <div className="flex flex-col gap-[32px]">
                        <div className="text-[20px] leading-[120%] font-[500] text-[#292B2C]">Информация о назначенных курсах</div>
                        <div className="flex flex-col">
                            <div
                                className="flex text-[#828282] max-tablet:hidden pr-[16px] w-full border-solid border-b-[0.5px] border-[#BDBDBD] border-opacity-[0.3] pb-[20px]">
                                <ArrowSort className={`w-[calc(100%/3*2)]`} sortField={"Course.Name"}
                                           setSortField={setSortField} setSortDirection={setSortDirection}
                                           activeSortField={sortField}>
                                    Название
                                </ArrowSort>
                                <ArrowSort className={`w-[calc(100%/3)]`} sortField={"IsPassed"}
                                           setSortField={setSortField} setSortDirection={setSortDirection}
                                           activeSortField={sortField}>
                                    Статус
                                </ArrowSort>
                                <div className="w-[74px] flex-shrink-0">Действия</div>
                            </div>
                            <div className="flex flex-col">
                                {filteredCourses.map((course, index) => (
                                    <UserCoursesItem course={course} key={course.course.id} setError={setError}/>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="flex justify-center py-[12px] font-[400] text-[#828282]">
                        Назначенных курсов пока нет
                    </div>
                )}
            </div>
        </>

    )
}

export default UserCourses