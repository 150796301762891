import {IUserResultTestQuestions} from "../../../../../../models/users";
import React, {useContext, useEffect, useState} from "react";
import {Constants} from "../../../../../../constans/constants";
import {AuthContext} from "../../../../../../context/AuthContext";
import OptionResultTest from "./option/OptionResultTest";

interface IStudentLessonTestViewResultItemProps {
    question: IUserResultTestQuestions
    index: number
    length: number
    isAdmin: boolean
}

const StudentLessonTestViewResultItem = (
    {
        question,
        index,
        length,
        isAdmin
    }: IStudentLessonTestViewResultItemProps
) => {

    const {currentRoleId} = useContext(AuthContext)

    return (
        <div
            className="w-full p-[36px] rounded-[20px] flex-col gap-[24px] flex bg-white max-tablet:p-[24px]">
            <div className="flex gap-[24px] items-center max-tablet:gap-[16px]">
                <div
                    className={`py-[8px] px-[16px] text-white text-[20px] font-[500] leading-[120%] rounded-[8px] ${question.isCorrectAnswer ? `bg-[#17AB57]` : `bg-[#EB5757]`}`}>
                    {question.isCorrectAnswer ? `Верно` : `Неверно`}
                </div>
                <div className="text-[#828282] text-[16px] leading-[120%]">Вопрос {index} из {length}</div>
            </div>
            {!question.isCorrectAnswer && question.countCorrectAnswer > 1 && (
                <div className="text-[#EB5757] text-[20px] font-[500] leading-[120%]">
                    Выбрано неправильное количество ответов
                    ({question.options.filter(c => c.answerUser && c.answerUser !== c.isCorrectAnswer).length}/{question.countCorrectAnswer})
                </div>
            )}
            <div
                className="leading-[120%] text-[#292B2C] text-[24px] font-[500]">
                {question.name}
            </div>
            <div className="flex flex-col gap-[8px]">
                {question.options.map((option) => (
                    <OptionResultTest
                        option={option}
                        isAdmin={isAdmin}
                        isUserResponse={question.options.filter(c => c.answerUser).length > 0}
                        countCorrectAnswer={question.countCorrectAnswer}
                        key={option.id}/>
                ))}
            </div>
        </div>
    )
}

export default StudentLessonTestViewResultItem