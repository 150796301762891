import {Link, useParams} from "react-router-dom";
import Cookies from "universal-cookie";
import {useQuery} from "react-query";
import {UsersService} from "../../../../../../services/users.service";
import ErrorMessage from "../../../../../ui/error/ErrorMessage";
import {ReactComponent as ArrowBack} from "../../../../../../assets/images/global/arrow-back-icon.svg";
import React, {useContext} from "react";
import UserCourseResultItem from "./user-course-result-item/UserCourseResultItem";
import ScrollContainer from "../../../../../ui/scroll-container/ScrollContainer";
import {AuthContext} from "../../../../../../context/AuthContext";
import Loader from "../../../../../ui/loader/Loader";

const UserCourseResult = () => {

    const {id, courseId} = useParams()
    const cookies = new Cookies()

    const {authenticated, setAuthenticated, currentRoleId, currentUserId} = useContext(AuthContext)

    const {data: progress, isLoading: progressLoading, isError: progressError} = useQuery(
        ["progress", currentUserId],
        () => UsersService.getProgressCourse(Number(id), Number(courseId)),
        {
            enabled: !!id && !!cookies.get("user")
        }
    )

    if (progressLoading) return <Loader/>
    if (progressError) return <ErrorMessage numberError={400}/>
    if (!progress) return <ErrorMessage numberError={400}/>
    if (!id) return <ErrorMessage numberError={400}/>

    return (
        <div className="flex flex-col gap-[32px] max-laptop:gap-[24px] max-tablet:gap-[28px] h-full">
            <div className="flex flex-col gap-[16px]">
                <div className="inline-flex">
                    <Link
                        to={`/users/${id}`}
                        className="px-[16px] py-[12px] flex gap-[10px] items-center">
                        <ArrowBack className="flex-shrink-0"/>
                        <span className="max-tablet:hidden">
                        Назад к профилю пользователя
                    </span>
                        <span className="tablet:hidden">
                        {progress.name}
                    </span>
                    </Link>
                </div>
                <div className="text-[36px] leading-[120%] text-[#292B2C] font-[700] max-tablet:hidden">{progress.name}</div>
            </div>
            <div
                className="bg-white p-[36px] rounded-[20px] flex flex-col gap-[32px] max-tablet:p-[24px] h-full">
                <div className="font-[500] text-[#292B2C] text-[20px] leading-[120%]">Уроки</div>
                <ScrollContainer>
                    {progress.lessons.map((lesson, index) =>
                        <UserCourseResultItem
                            userId={id}
                            key={index}
                            index={index}
                            progress={progress}
                            lesson={lesson}/>)}
                </ScrollContainer>
            </div>
        </div>
    )
}

export default UserCourseResult