import {useEffect, useRef, useState} from "react";
import {ReactComponent as ActionIcon} from "../../../assets/images/global/action-icon-horizontal.svg";
import {IDropdownMenu} from "../../../models/dropdownmenu";
import {Link} from "react-router-dom";

interface IDropdownMenuProps {
    isNavigation?: boolean
    list: IDropdownMenu[]
    size?: number
    isChat?: boolean
    senderIsCurrentUser?: boolean
    showCircuit?: boolean
}

const DropdownMenu = (
    {
        isNavigation = false,
        list,
        size = 18,
        isChat,
        senderIsCurrentUser,
        showCircuit
    }: IDropdownMenuProps
) => {

    const [leftMenu, setLeftMenu] = useState<boolean>(true)
    const [isOpen, setIsOpen] = useState(false);
    const [menuDirection, setMenuDirection] = useState<'down' | 'up'>('down');
    const buttonRef = useRef<HTMLDivElement>(null);

    const toggleMenu = () => {
        if (buttonRef.current) {
            const buttonRect = buttonRef.current.getBoundingClientRect()
            const spaceDown = window.innerHeight - buttonRect.bottom
            const spaceUp = buttonRect.top
            if((180 + buttonRect.left) > window.innerWidth) {
                setLeftMenu(false)
            }

            if (spaceDown < 250 && spaceUp >= 250) {
                setMenuDirection("up")
            } else {
                setMenuDirection("down")
            }
        }
        setIsOpen(!isOpen)
    };

    useEffect(() => {
        if (isNavigation)
            list.unshift({
                name: "Выберите домен",
                color: "text-[#BDBDBD]",
                title: true
            })

        const handleClickOutside = (event: MouseEvent) => {
            if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickAction = (action: () => void) => {
        action()
        setIsOpen(!isOpen)
    }

    return (
        <div>
            <div
                className={isChat ? `absolute group top-[10px] right-[10px] z-[100] w-[24px] h-[24px] cursor-pointer group-hover:bg-[#58db92d4] rounded-[49px]` : `relative`}
                ref={buttonRef}>
                <button onClick={toggleMenu}
                        style={{
                            width: `${size}px`,
                            height: `${size}px`
                        }}
                        className={isChat ? `hidden group-hover:flex items-center justify-center !w-full !h-full` : `bg-none border-none cursor-pointer flex items-center justify-center max-tablet:justify-end ${showCircuit ? `bg-white rounded-[50px]` : ``}`}>
                    <ActionIcon className={`flex-shrink-0 ${isChat ? `rotate-90` : ``}`}/>
                </button>
                {isOpen && (
                    <div
                        className={`absolute ${isNavigation ? 'left-0' : isChat ? senderIsCurrentUser ? 'right-0' : leftMenu ? "left-0" : "right-0" : 'right-0'} ${menuDirection === 'down' ? 'top-[100%]' : 'bottom-[100%]'} 
                    whitespace-nowrap bg-white z-[1000] rounded-[12px] shadow-[0_0px_32px_0_rgba(0,0,0,0.2)] flex flex-col w-auto overflow-hidden`}>
                        {list.map((item, index) => (
                            <>
                                {item.title && (
                                    <div key={index}
                                         className={`pb-[12px] px-[16px] pt-[16px] text-[${item.color ? item.color : `#292B2C`}] min-w-[210px]`}>
                                        {item.name}
                                    </div>
                                )}
                                {item.link && (
                                    <Link key={index}
                                          onClick={() => setIsOpen(!isOpen)}
                                          to={item.link}
                                          target='_blank'
                                          className={`py-[12px] px-[16px] hover:bg-[#F2F2F2] text-[${item.color ? item.color : `#292B2C`}] border-solid border-b-[0.5px] border-[#BDBDBD] border-opacity-[0.3] last:border-none hover:bg-[#F2F2F2] min-w-[210px] flex items-center justify-between`}
                                    >
                                        {item.name}
                                        {item.svg && (
                                            <>
                                                {item.svg}
                                            </>
                                        )}
                                    </Link>
                                )}
                                {item.action && (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            if (item.action) handleClickAction(item.action)
                                        }}
                                        className={`cursor-pointer py-[12px] px-[16px] hover:bg-[#F2F2F2] text-[${item.color ? item.color : `#292B2C`}] border-solid border-b-[0.5px] border-[#BDBDBD] border-opacity-[0.3] last:border-none min-w-[210px] ${isChat ? "max-tablet:min-w-[180px]" : ""} flex items-center justify-between`}
                                    >
                                        {item.name}
                                        {item.svg && (
                                            <>
                                                {item.svg}
                                            </>
                                        )}
                                    </div>
                                )}
                            </>
                        ))}
                    </div>
                )}
            </div>
        </div>

    )
}

export default DropdownMenu