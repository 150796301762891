import {Constants} from "../constans/constants";

export function WorkWithDomain(){

    let domain = ""
    let location = window.location
    let hostname = location.hostname
    let subdomain = hostname.split(".")
    if(subdomain[subdomain.length - 1] === "ru" && subdomain.length === 3) {
        domain = subdomain[0]
    } else if(subdomain[subdomain.length - 1] !== "ru" && subdomain.length === 2) {
        domain = subdomain[0]
    }

    return domain
}

export function ShowBx24Id() {
    let showBx24Id: boolean = false
    Constants.LIST_APOK_DOMAIN.map((element) => {
        if (element.value.indexOf(window.location.host) !== -1) {
            showBx24Id = true
        }
    })
    Constants.LIST_TEST_DOMAIN.map((element) => {
        if (element.value.indexOf(window.location.host) !== -1) {
            showBx24Id = true
        }
    })
    Constants.LIST_LOCAL_DOMAIN.map((element) => {
        if (element.value.indexOf(window.location.host) !== -1) {
            showBx24Id = true
        }
    })

    return showBx24Id
}

export function IsFedkursDomain(){

}