import {ReactComponent as SearchIcon} from "../../../../assets/images/global/search-icon.svg";
import React, {useContext, useState} from "react";
import {ReactComponent as ArrowSortIcon} from "../../../../assets/images/global/arrow-sort-icon.svg";
import SupervisorGroupItem from "./supervisor-group-item/SupervisorGroupItem";
import {useQuery} from "react-query";
import Cookies from "universal-cookie";
import {GroupsService} from "../../../../services/groups.service";
import {AxiosError} from "axios";
import ErrorMessage from "../../../ui/error/ErrorMessage";
import {IGroupProgressUser} from "../../../../models/groups";
import ScrollContainer from "../../../ui/scroll-container/ScrollContainer";
import {AuthContext} from "../../../../context/AuthContext";

const SupervisorGroup = () => {

    const cookies = new Cookies()
    const [blurSearch, setBlurSearch] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState<string>("")
    const [activeSort, setActiveSort] = useState<boolean>(false)
    const [filteredUsers, setFilteredUsers] = useState<IGroupProgressUser[]>([])

    const {authenticated, setAuthenticated, currentRoleId, currentUserId} = useContext(AuthContext)

    const progress = useQuery(
        ["progress_group", Number(currentUserId)],
        () => GroupsService.getProgress(Number(currentUserId)),
        {
            enabled: !!cookies.get("user"),
            onSuccess: (response) => {
                setFilteredUsers(response.users)
            }
        }
    )

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
    }

    const handleSort = () => {
        const arrSort = [
            {method: (a: IGroupProgressUser, b: IGroupProgressUser) => (a.lastName + " " + a.firstName + " " + a.fatherName) > (b.lastName + " " + b.firstName + " " + b.fatherName) ? 1 : -1},
            {method: (a: IGroupProgressUser, b: IGroupProgressUser) => (a.lastName + " " + a.firstName + " " + a.fatherName) < (b.lastName + " " + b.firstName + " " + b.fatherName) ? 1 : -1}
        ]
        const sorted = filteredUsers.sort(!activeSort ? arrSort[0].method : arrSort[1].method)
        setFilteredUsers([...sorted])
        setActiveSort(!activeSort)
    }

    if (progress.isLoading) return <div className="text-center">Загрузка...</div>

    if (progress.isError) {
        const e = progress.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    if (!progress.data) return <div className="text-center">Ошибка данных</div>

    return (
        <div className="h-full">
            <div className="flex flex-col gap-[34px] w-full h-full max-lg:gap-[12px]">
                <div className="text-[36px] font-bold leading-[120%] text-[#292B2C]">Моя команда</div>
                <div
                    className="bg-white h-full p-[32px] rounded-[6px] flex gap-[24px] flex-col max-lg:gap-0 overflow-hidden max-lg:p-[14px]">
                    <div className="relative w-full">
                        <SearchIcon
                            className={`absolute top-[8px] left-[10px] ` + (blurSearch ? "fill-[#292B2C]" : "")}/>
                        <input
                            onFocus={() => {
                                setBlurSearch(true)
                            }}
                            onBlur={() => {
                                setBlurSearch(false)
                            }}
                            onChange={handleSearch}
                            type="text"
                            placeholder="Поиск по пользователям"
                            className="py-[8px] pl-[36px] pr-[12px] border-solid border-[1px] border-[#BDBDBD] rounded-[5px] bg-white w-full focus:border-[#17AB57] focus:outline-none"/>
                    </div>
                    <div className="flex flex-col gap-[24px] h-full overflow-hidden">
                        <div className="px-[18px] flex w-full text-[#828282] max-lg:hidden">
                            <div className="w-[50%] max-lg:w-full flex gap-[4px] cursor-pointer" onClick={handleSort}>
                                Имя
                                <ArrowSortIcon
                                    className={!activeSort ? "rotate-180 transition-all" : "rotate-0 transition-all"}/>
                            </div>
                            <div className="w-[10%] max-lg:w-full">Активность</div>
                            <div className="w-[40%] max-lg:w-full flex justify-end">Курсы</div>
                        </div>
                        {/*<ScrollContainer>*/}
                            <div
                                className="flex flex-col h-full pr-[5px]">
                                {filteredUsers
                                    .filter(user =>
                                        user.lastName.toLowerCase().includes(searchTerm) ||
                                        user.firstName.toLowerCase().includes(searchTerm) ||
                                        user.fatherName.toLowerCase().includes(searchTerm) ||
                                        user.email.toLowerCase().includes(searchTerm))
                                    .map((user, index) => (
                                        <SupervisorGroupItem activeSortUser={activeSort} user={user} key={index}/>
                                    ))}
                            </div>
                        {/*</ScrollContainer>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupervisorGroup