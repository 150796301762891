import Loader from "../../../ui/loader/Loader";
import ErrorMessage from "../../../ui/error/ErrorMessage";
import {ReactComponent as ArrowSortIcon} from "../../../../assets/images/global/arrow-sort-icon.svg";
import {ReactComponent as SearchIcon} from "../../../../assets/images/global/search-icon.svg";
import React, {useState} from "react";
import {useQuery} from "react-query";
import {ICourses} from "../../../../models/courses";
import {CoursesService} from "../../../../services/courses.service";
import {AxiosError} from "axios";
import ItemCourseArchive from "./item-course-archive/ItemCourseArchive";
import {ITest} from "../../../../models/tests";

const ListCourseArchive = () => {

    const [error, setError] = useState<number | null>(null)
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState<string>("")
    const [page, setPage] = useState<number>(1)
    const [filteredCourses, setFilteredCourses] = useState<ICourses[]>([])
    const [showLoadMore, setShowLoadMore] = useState<boolean>(false)
    const [activeSort, setActiveSort] = useState<boolean>(false)

    const listArchiveCourse = useQuery(
        ["archive_course", page, searchTerm],
        () => CoursesService.searchIsArchiveCourses(page, searchTerm),
        {
            onSuccess:(response) => {
                if(page == 1) {
                    setFilteredCourses(response.courses)
                } else {
                    response.courses.map(course=> {
                        setFilteredCourses(prev => [...prev, course])
                    })
                }
                setShowLoadMore(false)
                if(response.left > 0) setShowLoadMore(true)
            }
        }
    )

    const handleSort = () => {
        // const arrSort = [
        //     {method: (a: ITest, b: ITest) => a.name > b.name ? 1 : -1},
        //     {method: (a: ITest, b: ITest) => a.name < b.name ? 1 : -1}
        // ]
        // const sorted = filteredCourses.sort(!activeSort ? arrSort[0].method : arrSort[1].method)
        // setFilteredCourses([...sorted])
        // setActiveSort(!activeSort)
    }

    const handleRemoveCourse = (courseId: number) => {
        setFilteredCourses(filteredCourses.filter(course => course.id !== courseId))
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPage(1)
        setSearchTerm(e.target.value)
    }

    if (listArchiveCourse.isLoading) return <Loader/>

    if (listArchiveCourse.isError) {
        const e = listArchiveCourse.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    if(!listArchiveCourse.data) return <ErrorMessage numberError={400}/>

    return (
        <>
            {showLoader && (<Loader/>)}
            <div className="h-full">
                <div className="flex flex-col gap-[34px] max-lg:gap-[16px] h-full">
                    <div className="flex justify-between items-center max-lg:flex-col max-lg:gap-[10px] max-lg:items-start">
                        <h2 className="font-bold text-[36px] leading-[120%]">Архивные курсы</h2>
                    </div>
                    <div className="relative">
                        <SearchIcon className="absolute top-[10px] left-[10px]"/>
                        <input
                            type="text"
                            onChange={e => handleSearch(e)}
                            placeholder="Введите название курса"
                            className="border-solid border-[1px] border-[#BDBDBD] rounded-[5px] py-[8px] pr-[12px] pl-[34px] w-full focus:outline-none focus:border-[#17AB57]"/>
                    </div>
                    <div
                        className="bg-white p-[32px] rounded-[6px] flex flex-col text-[16px] font-medium max-lg:px-[14px] max-lg:py-[2px] max-lg:gap-0 h-full">
                        {error && <ErrorMessage numberError={error}/>}
                        <div
                            className="px-[18px] flex justify-between text-[#828282] text-[16px] font-medium whitespace-nowrap max-lg:hidden mb-[18px]">
                            <div
                                onClick={handleSort}
                                className="w-[30%] cursor-pointer flex gap-[4px]">
                                Название
                                <ArrowSortIcon
                                    className={!activeSort ? "rotate-180 transition-all" : "rotate-0 transition-all"}/>
                            </div>
                            <div className="w-[10%]">Дата создания</div>
                            <div className="w-[10%]">Дата изменения</div>
                            <div className="w-[6%] flex justify-end">Действия</div>
                        </div>
                        {filteredCourses && filteredCourses.length > 0 && filteredCourses.map((course, index) => (
                            <ItemCourseArchive course={course} key={index} removeCourse={handleRemoveCourse}/>
                        ))}
                    </div>
                    {showLoadMore && (
                        <div
                            onClick={() => setPage(prev => prev + 1)}
                            className="flex text-center w-full px-[16px] py-[8px] border-[1px] border-solid border-[#17AB57] justify-center cursor-pointer rounded-[5px] text-[16px] font-medium text-[#292B2C] leading-[120%]">
                            Показать ещё
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default ListCourseArchive