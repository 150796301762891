import {useNavigate} from "react-router-dom";
import {useContext, useEffect} from "react";
import {useMutation} from "react-query";
import {IToken} from "../../../models/login";
import {UsersService} from "../../../services/users.service";
import Cookies from "universal-cookie";
import {AuthContext} from "../../../context/AuthContext";

interface ErrorMessage {
    messageError?: string
    numberError: number
}

const ErrorMessage = (
    {
        messageError,
        numberError
    }: ErrorMessage
) => {

    const cookies = new Cookies()
    const navigate = useNavigate()
    const {setAuthenticated} = useContext(AuthContext)

    const switchError = (numberError: number) => {
        switch (numberError) {
            case 401: return "";
            case 403: return "Нет доступа";
            case 404: return "Ошибка данных";
            default: return messageError ? messageError : "Произошла ошибка";
        }
    }

    return <p className="text-center text-red-600">{switchError(numberError)}</p>
}

export default ErrorMessage