import React from "react";
import {useFormContext} from "react-hook-form";

interface IResetSubmitProps {
    actionReset: () => void
}

const ResetSubmit = (
    {
        actionReset,
    }: IResetSubmitProps
) => {

    const {} = useFormContext()

    return (
        <>
            <div
                onClick={() => actionReset()}
                className="w-full px-[20px] py-[16px] rounded-[12px] bg-[#bdbdbd4d] text-[#292B2C] font-[500] cursor-pointer flex justify-center whitespace-nowrap">
                Сбросить изменения
            </div>
            <button
                type="submit"
                className="w-full px-[20px] py-[16px] rounded-[12px] bg-[#17AB57] text-white font-[500] cursor-pointer flex justify-center whitespace-nowrap">
                Сохранить изменения
            </button>
        </>
    )
}

export default ResetSubmit