import {useQuery} from "react-query";
import {CompanyService} from "../../../../services/company.service";
import {AxiosError} from "axios";
import ErrorMessage from "../../../ui/error/ErrorMessage";
import React, {useEffect, useRef, useState} from "react";
import Loader from "../../../ui/loader/Loader";
import {ReactComponent as SearchIcon} from "../../../../assets/images/global/search-icon.svg";
import {ReactComponent as CreateCoursesIcon} from "../../../../assets/images/courses/create-courses-icon.svg";
import {ReactComponent as ArrowSortIcon} from "../../../../assets/images/global/arrow-sort-icon.svg";
import ScrollContainer from "../../../ui/scroll-container/ScrollContainer";
import {ITest} from "../../../../models/tests";
import {ICompany} from "../../../../models/company";
import useToggle from "../../../../hooks/useToggle";
import CompanyItem from "./company-item/CompanyItem";
import ModalAddCompany from "../../../ui/modal/modal-add-company/ModalAddCompany";

const Company = () => {

    const [blurSearch, setBlurSearch] = useState<boolean>(false)
    const [activeSort, setActiveSort] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState<string>("")
    const [modalCompany, setModalCompany] = useState<ICompany>({} as ICompany)
    const [filteredCompanies, setFilteredCompanies] = useState<ICompany[]>([])
    const [isModalAddCompany, setOnModalOpenAddCompany, setOnModalCloseAddCompany] = useToggle()
    const refListCompanies = useRef<HTMLDivElement>(null)
    const [rect, setRect] = useState<DOMRect>({} as DOMRect)
    const [error, setError] = useState<number|null>(null)

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
    }

    const companies = useQuery(
        ['list_companies'],
        () => CompanyService.getAll(),
        {
            keepPreviousData: true,
            onSuccess: (response) => {
                setFilteredCompanies(response)
                if (refListCompanies.current) {
                    setRect(refListCompanies.current.getBoundingClientRect())
                }
            }
        }
    )

    useEffect(() => {
        if(Object.keys(modalCompany).length > 0) {
            setFilteredCompanies(prev => [...prev, modalCompany])
        }
    }, [modalCompany])

    const handleSort = () => {
        // const arrSort = [
        //     {method: (a: ITest, b: ITest) => a.name > b.name ? 1 : -1},
        //     {method: (a: ITest, b: ITest) => a.name < b.name ? 1 : -1}
        // ]
        // const sorted = filteredCompanies.sort(!activeSort ? arrSort[0].method : arrSort[1].method)
        // setFilteredCompanies([...sorted])
        // setActiveSort(!activeSort)
    }

    const onDelete = (id: number) => {
        setFilteredCompanies(filteredCompanies.filter(t => t.id != id))
    }

    if (companies.isLoading) return <Loader/>

    if (companies.isError) {
        const e = companies.error as AxiosError
        return <ErrorMessage numberError={e.response ? e.response.status : 400}/>
    }

    if(!companies.data) return <div className="text-center text-red-600">Ошибка данных</div>

    return (
        <div className="h-full">
            <div className="flex flex-col gap-[34px] h-full">
                <div className="w-full flex gap-[12px] max-lg:flex-col items-center text-[16px] max-lg:gap-[16px]">
                    <div className="relative w-full">
                        <SearchIcon
                            className={`absolute top-[8px] left-[10px] ` + (blurSearch ? "fill-[#292B2C]" : "")}/>
                        <input
                            onFocus={() => {
                                setBlurSearch(true)
                            }}
                            onBlur={() => {
                                setBlurSearch(false)
                            }}
                            onChange={handleSearch}
                            type="text"
                            placeholder="Поиск"
                            className="py-[8px] pl-[36px] pr-[12px] border-solid border-[1px] border-[#BDBDBD] rounded-[5px] bg-[#F2F2F2] w-full focus:border-[#17AB57] focus:outline-none"/>
                    </div>
                    <div
                        onClick={() => setOnModalOpenAddCompany()}
                        className="bg-[#17AB57] text-white rounded-[5px] items-center flex gap-[6px] py-[8px] pl-[12px] pr-[16px] font-medium whitespace-nowrap cursor-pointer max-lg:w-[50%] justify-center">
                        <CreateCoursesIcon className="flex-shrink-0"/>
                        Создать компанию
                    </div>
                </div>
                <div ref={refListCompanies}
                     className="bg-white p-[32px] rounded-[6px] flex flex-col gap-[18px] text-[16px] font-medium max-lg:px-[14px] max-lg:py-[2px] max-lg:gap-0 h-full">
                    {error && <ErrorMessage numberError={error}/>}
                    <div
                        className="px-[18px] flex justify-between text-[#828282] text-[16px] font-medium whitespace-nowrap max-lg:hidden">
                        <div className="w-[90%] cursor-pointer flex gap-[4px]" onClick={handleSort}>
                            Название
                            <ArrowSortIcon
                                className={!activeSort ? "rotate-180 transition-all" : "rotate-0 transition-all"}/>
                        </div>
                        <div className="w-[10%] flex justify-end">Действия</div>
                    </div>
                    {/*<ScrollContainer>*/}
                        <div className="h-full">
                            {filteredCompanies && filteredCompanies.filter(value => value.name.toLowerCase().includes(searchTerm.toLowerCase())).map((company, key) => (
                                <CompanyItem setError={setError} onDelete={onDelete} parentParameter={rect} company={company} key={key}/>
                            ))}
                        </div>
                    {/*</ScrollContainer>*/}
                </div>
                {isModalAddCompany && (
                    <ModalAddCompany
                        setModalCompany={setModalCompany}
                        onClose={setOnModalCloseAddCompany}/>
                )}
            </div>
        </div>
    )
}

export default Company