import Modal from "../Modal";
import ErrorMessage from "../../error/ErrorMessage";
import {ReactComponent as CloseIcon} from "../../../../assets/images/global/close.svg";
import {ReactComponent as UserIcon} from "../../../../assets/images/menu/users-icon.svg";
import Portal from "../portal/Portal";
import React, {useContext, useEffect, useState} from "react";
import {IUser} from "../../../../models/users";
import {IGroup, IGroupUser} from "../../../../models/groups";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {UsersService} from "../../../../services/users.service";
import {GroupsService} from "../../../../services/groups.service";
import {LoaderContext} from "../../../../context/LoaderContext";
import Loader from "../../loader/Loader";
import ScrollContainer from "../../scroll-container/ScrollContainer";
import {AxiosError} from "axios";
import {SubmitHandler, useForm} from "react-hook-form";
import Search from "../../search/Search";

interface IModalAttachUserGroup {
    onClose: () => void
    group: IGroup
}

const ModalAttachUserGroup = (
    {
        onClose,
        group
    }: IModalAttachUserGroup
) => {

    const queryClient = useQueryClient()
    const methods = useForm<IGroupUser>();
    const {
        register,
        setValue,
        handleSubmit,
        formState: {isValid}
    } = methods

    const [page, setPage] = useState<number>(1)
    const {show, setShow} = useContext(LoaderContext)
    const [error, setError] = useState<number | null>(null)
    const [searchTerm, setSearchTerm] = useState<string>("")
    const [activeUser, setActiveUser] = useState<number | null>(null)
    const [scrollIsBottom, setScrollIsBottom] = useState<boolean>(false)
    const [filteredUsers, setFilteredUsers] = useState<IUser[]>([])

    const {data: listUsers, isLoading, isError} = useQuery(
        ["users_list_group", searchTerm, page],
        () => UsersService.getAllForAttachGroup(group.id, page, searchTerm),
        {
            keepPreviousData: true,
            onSuccess: (response) => {
                setValue("groupId", group.id)
                if(page === 1){
                    setFilteredUsers(response)
                } else {
                    response.map(user => {
                        setFilteredUsers(prev => [...prev, user])
                    })
                }
            }
        }
    )

    useEffect(() => {
        setPage(1)
    }, [searchTerm]);

    useEffect(() => {
        if (scrollIsBottom) setPage(prev => prev + 1)
    }, [scrollIsBottom]);

    const attachUser = useMutation(
        (data: IGroupUser) => GroupsService.attachUser(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["group", group.id])
                setShow(false)
                onClose()
            },
            onError: (error) => {
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const onSubmit: SubmitHandler<IGroupUser> = (data) => {
        setShow(true)
        attachUser.mutate(data)
    }

    if(isLoading) return <Loader/>
    if(isError) setError(400)
    if(!listUsers) setError(400)

    return (
        <Portal>
            <Modal>
                {show && <Loader/>}
                {error && <ErrorMessage numberError={error}/>}
                <form className="flex flex-col gap-[36px] h-full" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex justify-between items-center font-[500] text-[20px]">
                        Добавить пользователя
                        <CloseIcon className="cursor-pointer w-[24px] h-[24px]" onClick={onClose}/>
                    </div>
                    <Search setSearchTerm={setSearchTerm}/>
                    <input type="hidden" {...register("groupId", {required: true})}/>
                    <div className="h-[250px] max-tablet:h-[200px] relative">
                        <ScrollContainer setScrollIsBottom={setScrollIsBottom} searchTerm={searchTerm}>
                            {filteredUsers.map((user) => (
                                <div
                                    onClick={() => {
                                        setActiveUser(user.id)
                                        setValue("userId", user.id, {shouldValidate: true})
                                    }}
                                    key={user.id}
                                    className={`flex gap-[12px] py-[12px] mr-[20px] border-solid border-[#BDBDBD] border-b-[0.5px] border-opacity-[0.3] items-start last:border-none cursor-pointer ${activeUser === user.id ? "bg-[#F2F2F2]" : ""}`}>
                                    <UserIcon className="flex-shrink-0 fill-[#17AB57]"/>
                                    <div className="flex flex-col gap-[4px] text-[16px] font-[500] leading-[120%] text-[#292B2C]">
                                        <span>{user.lastName} {user.firstName} {user.fatherName}</span>
                                        <span className="font-[400]">{user.email}</span>
                                        <span className="font-[400] text-[#BDBDBD]">{user.role.name}</span>
                                    </div>
                                </div>
                            ))}
                        </ScrollContainer>
                        <input type="hidden" {...register("userId", {required: true})}/>
                    </div>
                    <div className="flex gap-[12px] w-full">
                        <button
                            className="font-[500] py-[16px] px-[20px] bg-[#BDBDBD4d] rounded-[12px] w-full"
                            onClick={onClose}>
                            Отмена
                        </button>
                        <button
                            type="submit"
                            disabled={!isValid}
                            className={`font-[500] py-[16px] px-[20px] rounded-[12px] w-full ${!isValid ? "bg-[#66C28E] text-[#ffffff66]" : "bg-[#17AB57] text-[#ffffff]"}`}>
                            Назначить
                        </button>
                    </div>
                </form>
            </Modal>
        </Portal>
    )
}

export default ModalAttachUserGroup