import {Link, NavLink} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../context/AuthContext";
import Cookies from "universal-cookie"
import {ReactComponent as CoursesIcon} from "../../../assets/images/menu/courses-icon.svg"
import {ReactComponent as ArchiveCourse} from "../../../assets/images/menu/archive-course.svg"
import {ReactComponent as LessonIcon} from "../../../assets/images/menu/lesson.svg"
import {ReactComponent as TestsIcon} from "../../../assets/images/menu/tests-icon.svg"
import {ReactComponent as UsersIcon} from "../../../assets/images/menu/users-icon.svg"
import {ReactComponent as GroupsIcon} from "../../../assets/images/menu/groups-icon.svg"
import {ReactComponent as EventIcon} from "../../../assets/images/menu/events.svg"
import {ReactComponent as ChatIcon} from "../../../assets/images/menu/chat.svg"
import {ReactComponent as CompanyIcon} from "../../../assets/images/menu/company.svg"
import {ReactComponent as Logout} from "../../../assets/images/menu/logout.svg"
import {Constants} from "../../../constans/constants";
import {useQuery} from "react-query";
import Select from "react-select";
import {logout} from "../../../helpers/WorkWithUser";
import {UsersService} from "../../../services/users.service";
import DropdownMenu from "../dropdown-menu/DropdownMenu";
import {IDropdownMenu} from "../../../models/dropdownmenu";
import {MobileMenuContext} from "../../../context/MobileMenuContext";
import ScrollContainer from "../scroll-container/ScrollContainer";

const Navigation = () => {

    const cookies = new Cookies()
    const {isMenuOpen, toggleMenu} = useContext(MobileMenuContext)
    const {authenticated, setAuthenticated, currentRoleId, currentUserId} = useContext(AuthContext)
    const [showSelectDomain, setShowSelectDomain] = useState<boolean>(false)
    const [avatarUser, setAvatarUser] = useState<string>("")
    const [listDropdownMenu, setListDropdownMenu] = useState<IDropdownMenu[]>([])

    const role = currentRoleId

    const handleLogout = () => {
        logout()
        toggleMenu()
        setAuthenticated(false)
    }

    const classNameLink = "relative group p-[16px] flex gap-[12px] items-center text-[#828282] hover:text-[#000] text-[18px] leading-[120%] max-tablet:text-[#292B2C] max-tablet:p-[12px]"
    const activeClassNameLink = " bg-[#17ab571a] tablet:!text-[#17ab57] max-tablet:!text-[#17ab57] hover:!text-[#17ab57] rounded-[8px]"
    const classSvg = "fill-[#828282] max-tablet:fill-[#292B2C] group-hover:fill-[#000000] flex-shrink-0"
    const activeClassSvg = "tablet:fill-[#17ab57] max-tablet:fill-[#17ab57] flex-shrink-0"

    const activeLink = ({isActive}: { isActive: boolean }): string => {
        return isActive ? (classNameLink + activeClassNameLink) : classNameLink
    }

    useEffect(() => {
        const domains = [
            ...Constants.LIST_LOCAL_DOMAIN,
            ...Constants.LIST_TEST_DOMAIN,
            ...Constants.LIST_APOK_DOMAIN
        ]

        domains.forEach((element) => {
            if (element.value.indexOf(window.location.host) !== -1) {
                setShowSelectDomain(true)
                setListDropdownMenu(prev => [
                    ...prev,
                    {
                        name: element.label,
                        link: "https://" + element.value,
                        color: "#292B2C"
                    }
                ])
            }
        })
    }, [window.location.host])

    const {data: currentUser, isLoading, isError} = useQuery(
        ["current_user", currentUserId],
        () => UsersService.getById(currentUserId),
        {
            refetchOnWindowFocus: true,
            enabled: !!cookies.get("token"),
            onSuccess: (response) => {
                if (response.avatar)
                    setAvatarUser(response.avatar)
            }
        }
    )

    if (isError) {
        logout()
        setAuthenticated(false)
    }

    return (
        <>
            <div
                className={`flex flex-col gap-[20px] py-[36px] px-[28px] h-full transition-all max-tablet:absolute max-tablet:top-[78px] max-tablet:left-0 max-tablet:flex max-tablet:w-full max-tablet:h-[calc(100%-78px)] max-tablet:bg-white max-tablet:p-[24px] ${isMenuOpen ? "max-tablet:opacity-100 max-tablet:z-[999]" : "max-tablet:opacity-0 max-tablet:z-[-100]"}`}>
                {authenticated && currentUser && (
                    <>
                        <Link
                            to={`/users/${currentUser.id}`}
                            onClick={toggleMenu}
                            className="flex gap-[8px] items-center px-[16px] max-laptop:px-[8px] max-tablet:items-start">
                            <div
                                className="w-[40px] h-[40px] rounded-[37px] overflow-hidden max-laptop:w-[44px] max-laptop:h-[44px] max-tablet:w-[24px] max-tablet:h-[24px]">
                                {avatarUser ? (
                                    <img src={avatarUser} className="w-full h-full object-cover" alt=""/>
                                ) : (
                                    <div
                                        className={`w-full h-full flex justify-center items-center text-white font-[500] text-[24px] ${Constants.LIST_COLORS[Number(currentUser.color)]}`}>
                                        {currentUser.lastName.trim()[0]}{currentUser.firstName.trim()[0]}
                                    </div>
                                )}
                            </div>
                            <div className="max-laptop:hidden max-tablet:block text-[18px]">
                                <div
                                    className={`text-[#292B2C] group-hover:text-[#17AB57] cursor-pointer`}>
                                    {currentUser.lastName} {currentUser.firstName[0]}.{currentUser.fatherName ? currentUser.fatherName[0] + "." : ""}
                                </div>
                                <div className="text-[#BDBDBD] text-[16px]">{currentUser.role.name}</div>
                            </div>
                        </Link>
                        <ScrollContainer>
                            <div className="flex flex-col gap-[4px]">
                                {Number(role) === Constants.STUDENT_ROLE && (
                                    <>
                                        <NavLink className={activeLink} to="/student-courses" onClick={toggleMenu}>
                                            {
                                                ({isActive}: { isActive: boolean }) => (
                                                    <>
                                                        <CoursesIcon
                                                            className={isActive ? activeClassSvg : classSvg}/>
                                                        <span
                                                            className="max-laptop:hidden max-tablet:block">Курсы</span>
                                                    </>
                                                )
                                            }
                                        </NavLink>
                                        {currentUser.chatId && (
                                            <NavLink className={activeLink}
                                                     to={`/messages/${currentUser.chatId}`} onClick={toggleMenu}>
                                                {
                                                    ({isActive}: { isActive: boolean }) => (
                                                        <>
                                                            <ChatIcon className={isActive ? activeClassSvg : classSvg}/>
                                                            <span
                                                                className="max-laptop:hidden max-tablet:block">Сообщения</span>
                                                            {currentUser.countMessages != null && currentUser.countMessages > 0 && (
                                                                <div className="tablet:max-laptop:absolute w-[32px] h-[32px] py-[2px] px-[4px] flex justify-center items-center bg-[#17AB57] rounded-[100px] text-white text-[12px] font-[700]
                                                                        max-laptop:text-[10px] tablet:max-laptop:w-[24px] tablet:max-laptop:h-[24px] tablet:max-laptop:py-[1.5px] tablet:max-laptop:px-[3px] tablet:max-laptop:top-[10%] tablet:max-laptop:right-[30%]">
                                                                    {currentUser.countMessages}
                                                                </div>
                                                            )}
                                                        </>
                                                    )
                                                }
                                            </NavLink>
                                        )}
                                    </>
                                )}
                                {Number(role) === Constants.SUPERVISOR_ROLE && (
                                    <>
                                        <NavLink className={activeLink} to="/student-courses" onClick={toggleMenu}>
                                            {
                                                ({isActive}: { isActive: boolean }) => (
                                                    <>
                                                        <CoursesIcon
                                                            className={isActive ? activeClassSvg : classSvg}/>
                                                        <span
                                                            className="max-laptop:hidden max-tablet:block">Курсы</span>
                                                    </>
                                                )
                                            }
                                        </NavLink>
                                        <NavLink className={activeLink} to="/supervisor-group" onClick={toggleMenu}>
                                            {
                                                ({isActive}: { isActive: boolean }) => (
                                                    <>
                                                        <GroupsIcon
                                                            className={isActive ? activeClassSvg : classSvg}/>
                                                        <span
                                                            className="max-laptop:hidden max-tablet:block">Моя команда</span>
                                                    </>
                                                )
                                            }
                                        </NavLink>
                                        {currentUser.chatId && (
                                            <NavLink className={activeLink} to={`/messages/${currentUser.chatId}`}
                                                     onClick={toggleMenu}>
                                                {
                                                    ({isActive}: { isActive: boolean }) => (
                                                        <>
                                                            <ChatIcon className={isActive ? activeClassSvg : classSvg}/>
                                                            <span
                                                                className="max-laptop:hidden max-tablet:block">Сообщения</span>
                                                            {currentUser.countMessages != null && currentUser.countMessages > 0 && (
                                                                <div className="tablet:max-laptop:absolute w-[32px] h-[32px] py-[2px] px-[4px] flex justify-center items-center bg-[#17AB57] rounded-[100px] text-white text-[12px] font-[700]
                                                                    max-laptop:text-[10px] tablet:max-laptop:w-[24px] tablet:max-laptop:h-[24px] tablet:max-laptop:py-[1.5px] tablet:max-laptop:px-[3px] tablet:max-laptop:top-[10%] tablet:max-laptop:right-[30%]">
                                                                    {currentUser.countMessages}
                                                                </div>
                                                            )}
                                                        </>
                                                    )
                                                }
                                            </NavLink>
                                        )}

                                    </>
                                )}
                                {Constants.LIST_ADMIN_ROLE.indexOf(Number(role)) !== -1 && (
                                    <>
                                        <NavLink className={activeLink} to="/folders" onClick={toggleMenu}>
                                            {
                                                ({isActive}: { isActive: boolean }) => (
                                                    <>
                                                        <CoursesIcon
                                                            className={isActive ? activeClassSvg : classSvg}/>
                                                        <span
                                                            className="max-laptop:hidden max-tablet:block">Курсы</span>
                                                    </>
                                                )
                                            }
                                        </NavLink>
                                        <NavLink className={activeLink} to="/courses-archive" onClick={toggleMenu}>
                                            {
                                                ({isActive}: { isActive: boolean }) => (
                                                    <>
                                                        <ArchiveCourse
                                                            className={isActive ? activeClassSvg : classSvg}/>
                                                        <span className="max-laptop:hidden max-tablet:block">Архив курсов</span>
                                                    </>
                                                )
                                            }
                                        </NavLink>
                                        <NavLink className={activeLink} to="/lessons" onClick={toggleMenu}>
                                            {
                                                ({isActive}: { isActive: boolean }) => (
                                                    <>
                                                        <LessonIcon
                                                            className={isActive ? activeClassSvg : classSvg}/>
                                                        <span
                                                            className="max-laptop:hidden max-tablet:block">Уроки</span>
                                                    </>
                                                )
                                            }
                                        </NavLink>
                                        <NavLink className={activeLink} to="/tests" onClick={toggleMenu}>
                                            {
                                                ({isActive}: { isActive: boolean }) => (
                                                    <>
                                                        <TestsIcon
                                                            className={isActive ? activeClassSvg : classSvg}/>
                                                        <span
                                                            className="max-laptop:hidden max-tablet:block">Тесты</span>
                                                    </>
                                                )
                                            }
                                        </NavLink>
                                        <NavLink className={activeLink} to="/users" onClick={toggleMenu}>
                                            {
                                                ({isActive}: { isActive: boolean }) => (
                                                    <>
                                                        <UsersIcon
                                                            className={isActive ? activeClassSvg : classSvg}/>
                                                        <span
                                                            className="max-laptop:hidden max-tablet:block">Пользователи</span>
                                                    </>
                                                )
                                            }
                                        </NavLink>
                                        <NavLink className={activeLink} to="/groups" onClick={toggleMenu}>
                                            {
                                                ({isActive}: { isActive: boolean }) => (
                                                    <>
                                                        <GroupsIcon
                                                            className={isActive ? activeClassSvg : classSvg}/>
                                                        <span
                                                            className="max-laptop:hidden max-tablet:block">Группы</span>
                                                    </>
                                                )
                                            }
                                        </NavLink>
                                        <NavLink className={activeLink} to="/events" onClick={toggleMenu}>
                                            {
                                                ({isActive}: { isActive: boolean }) => (
                                                    <>
                                                        <EventIcon className={isActive ? activeClassSvg : classSvg}/>
                                                        <span
                                                            className="max-laptop:hidden max-tablet:block">Уведомления</span>
                                                        {currentUser.countEvent != 0 && (
                                                            <div className="tablet:max-laptop:absolute w-[32px] h-[32px] py-[2px] px-[4px] flex justify-center items-center bg-[#17AB57] rounded-[100px] text-white text-[12px] font-[700]
                                                            max-laptop:text-[10px] tablet:max-laptop:w-[24px] tablet:max-laptop:h-[24px] tablet:max-laptop:py-[1.5px] tablet:max-laptop:px-[3px] tablet:max-laptop:top-[10%] tablet:max-laptop:right-[15%]">
                                                                {currentUser.countEvent}
                                                            </div>
                                                        )}
                                                    </>
                                                )
                                            }
                                        </NavLink>
                                        <NavLink className={activeLink} to="/messages" onClick={toggleMenu}>
                                            {
                                                ({isActive}: { isActive: boolean }) => (
                                                    <>
                                                        <ChatIcon className={isActive ? activeClassSvg : classSvg}/>
                                                        <span
                                                            className="max-laptop:hidden max-tablet:block">Сообщения</span>
                                                        {currentUser.countMessages != null && currentUser.countMessages > 0 && (
                                                            <div className="tablet:max-laptop:absolute w-[32px] h-[32px] py-[2px] px-[4px] flex justify-center items-center bg-[#17AB57] rounded-[100px] text-white text-[12px] font-[700]
                                                            max-laptop:text-[10px] tablet:max-laptop:w-[24px] tablet:max-laptop:h-[24px] tablet:max-laptop:py-[1.5px] tablet:max-laptop:px-[3px] tablet:max-laptop:top-[10%] tablet:max-laptop:right-[15%]">
                                                                {currentUser.countMessages}
                                                            </div>
                                                        )}
                                                    </>
                                                )
                                            }
                                        </NavLink>
                                    </>
                                )}
                                {Number(role) === Constants.ADMIN_ROLE && (
                                    <>
                                        <NavLink className={activeLink} to="/company" onClick={toggleMenu}>
                                            {
                                                ({isActive}: { isActive: boolean }) => (
                                                    <>
                                                        <CompanyIcon
                                                            className={isActive ? activeClassSvg : classSvg}/>
                                                        <span
                                                            className="max-laptop:hidden max-tablet:block">Компании</span>
                                                    </>
                                                )
                                            }
                                        </NavLink>
                                    </>
                                )}
                            </div>
                        </ScrollContainer>
                        <div className="text-[#828282] flex flex-col gap-[8px] max-laptop:gap-[4px]">
                            {showSelectDomain && Constants.LIST_ADMIN_ROLE.indexOf(Number(role)) !== -1 && (
                                <>
                                    <div className="p-[16px] items-center hidden max-laptop:flex max-tablet:hidden">
                                        <DropdownMenu isNavigation={true} list={listDropdownMenu}/>
                                    </div>
                                    <div
                                        className="flex flex-col gap-[4px] px-[16px] max-laptop:hidden max-tablet:flex">
                                        <label className="">Сменить домен</label>
                                        <Select
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 5,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: "#17AB57",
                                                    primary25: "#17AB571a"
                                                }
                                            })}
                                            placeholder="Выбрать домен"
                                            value={
                                                Constants.LIST_APOK_DOMAIN.filter(option =>
                                                    option.value === window.location.host)
                                            }
                                            onChange={
                                                (item) => {
                                                    if (item) window.open("https://" + item.value, '_blank')
                                                    toggleMenu()
                                                }
                                            }
                                            menuPlacement="top"
                                            options={Constants.LIST_APOK_DOMAIN}/>
                                    </div>
                                </>
                            )}
                            <div onClick={handleLogout} className={classNameLink + " cursor-pointer max-lg:hidden"}>
                                <Logout className="group-hover:fill-[#000000]"/>
                                <span className="max-laptop:hidden max-tablet:block">Выйти</span>
                            </div>
                        </div>
                    </>
                )}
                {/*{!authenticated && (*/}
                {/*    <div className="flex flex-col gap-[36px]">*/}
                {/*        <div*/}
                {/*            className="flex justify-around flex-col gap-[8px] max-lg:flex-row max-lg:gap-0">*/}
                {/*            <NavLink className={activeLink} to="/login" onClick={toggleMenu}>*/}
                {/*                {*/}
                {/*                    ({isActive}: { isActive: boolean }) => (*/}
                {/*                        <>*/}
                {/*                            <UsersIcon*/}
                {/*                                className={isActive ? activeClassSvg : classSvg}/>*/}
                {/*                            <span className="max-laptop:hidden max-tablet:block">Авторизация</span>*/}
                {/*                        </>*/}
                {/*                    )*/}
                {/*                }*/}
                {/*            </NavLink>*/}
                {/*            <NavLink className={activeLink} to="/document" onClick={toggleMenu}>*/}
                {/*                {*/}
                {/*                    ({isActive}: { isActive: boolean }) => (*/}
                {/*                        <>*/}
                {/*                            <TestsIcon*/}
                {/*                                className={isActive ? activeClassSvg : classSvg}/>*/}
                {/*                            <span className="max-laptop:hidden max-tablet:block">Документы</span>*/}
                {/*                        </>*/}
                {/*                    )*/}
                {/*                }*/}
                {/*            </NavLink>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>
        </>
    )
}

export default Navigation