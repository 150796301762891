import React, {ReactNode, useEffect, useRef, useState} from "react";
import Scrollbars, {positionValues} from "react-custom-scrollbars-2"

interface IScrollContainer {
    children: ReactNode
    onScroll?: () => void
    heightModal?: string
    heightBlock?: string
    chat?: boolean
    setScrollIsBottom?: (flag: boolean) => void,
    searchTerm?: string
    activeTab?: number | null
    sortDirection?: string
    sortField?: string
    setPositionScroll?: (values: positionValues) => void
    scrollToTop?: boolean
}

const ScrollContainer = (
    {
        children,
        onScroll,
        heightModal,
        heightBlock,
        chat,
        setScrollIsBottom,
        searchTerm,
        activeTab,
        sortField,
        sortDirection,
        setPositionScroll,
        scrollToTop
    }: IScrollContainer
) => {
    const refScrollbars = useRef<Scrollbars>(null);

    const handleScrollFrame = (values: positionValues) => {
        if(setPositionScroll) setPositionScroll(values)

        const {top} = values
        if (top > 0.9) {
            if (setScrollIsBottom) setScrollIsBottom(true)
        } else {
            if (setScrollIsBottom) setScrollIsBottom(false)
        }
    }

    useEffect(() => {
        if (refScrollbars.current) {
            refScrollbars.current.scrollToTop()
        }
    }, [searchTerm, activeTab, sortField, sortDirection, scrollToTop]);

    return (
        <Scrollbars
            ref={refScrollbars}
            renderTrackVertical={({style, ...props}) => (
                <div
                    {...props}
                    className="!w-[5px] absolute right-0 bottom-[2px] top-[2px] rounded-[3px]"
                />
            )}
            onScrollFrame={handleScrollFrame}
        >
            {children}
        </Scrollbars>
    )
}

export default ScrollContainer