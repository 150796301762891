import Modal from "../Modal";
import {ReactComponent as CloseIcon} from "../../../../assets/images/global/close.svg";
import Portal from "../portal/Portal";
import React from "react";

interface IFinishTest {
    onClose: () => void
    setFinishTest: (flag: boolean) => void,
    formSubmit: number[]
}

const FinishTest = (
    {
        onClose,
        setFinishTest,
        formSubmit
    }: IFinishTest
) => {

    const handleFinish = (flag: boolean) => {
        setFinishTest(flag)
        onClose()
    }

    return (
        <Portal>
            <Modal>
                <div className="flex flex-col gap-[24px] text-[16px]">
                    <div className="flex justify-between items-center font-bold text-[16px]">
                        Внимание
                        <CloseIcon className="cursor-pointer" onClick={onClose}/>
                    </div>
                    <div className="font-[400] text-[#292B2C] leading-[120%]">
                        {formSubmit.length > 0 ? (
                            <>
                                Вы не ответили на вопросы: {formSubmit.join(', ')}. Ответы не будут сохранены. Вы действительно хотите завершить?
                            </>
                        ) : (
                            <>
                                Вы действительно хотите завершить?
                            </>
                        )}
                    </div>
                    <div className="flex gap-[12px] justify-end">
                        <button
                            onClick={() => handleFinish(true)}
                            className="text-white font-medium py-[8px] px-[16px] rounded-[5px] bg-[#17AB57]"
                        >
                            Завершить
                        </button>
                        <button
                            className="font-medium py-2 px-4 rounded-[5px] border-[1px] border-solid border-[#17AB57]"
                            onClick={() => handleFinish(false)}>
                            Отмена
                        </button>
                    </div>
                </div>
            </Modal>
        </Portal>
    )
}

export default FinishTest