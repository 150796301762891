import {IFile} from "../../../../../../models/file";
import React, {useRef, useState} from "react";
import {useMutation} from "react-query";
import {LessonsService} from "../../../../../../services/lessons.service";
import {Link} from "react-router-dom";
import {ReactComponent as LinkIcon} from "../../../../../../assets/images/courses/lesson-link-file.svg";
import {ReactComponent as FileIcon} from "../../../../../../assets/images/courses/lesson-file-icon.svg";
import {ReactComponent as DownloadIcon} from "../../../../../../assets/images/global/download.svg";
import Loader from "../../../../../ui/loader/Loader";

interface IPreviewLessonAdditionalFiles {
    files: IFile[]
}

const PreviewLessonAdditionalFiles = (
    {
        files
    }: IPreviewLessonAdditionalFiles
) => {

    const refDownloadFile = useRef<HTMLAnchorElement>(null)
    const [show, setShow] = useState<boolean>(false)
    const [listDownloadFile, setListDownloadFile] = useState<number[]>([])

    const downloadFile = useMutation(
        (id: number) => LessonsService.downloadFile(id),
        {
            onSuccess: (response) => {
                if (refDownloadFile.current) {
                    refDownloadFile.current.href = response
                    refDownloadFile.current.click()
                }
                setShow(false)
            }
        }
    )

    const handleDownloadFile = (id: number) => {
        setShow(true)
        setListDownloadFile(prev => [...prev, id])
        downloadFile.mutate(id)
    }

    return (
        <>
            {show && <Loader/>}
            <a ref={refDownloadFile} href="#" className="hidden"></a>
            <div
                className="p-[36px] bg-white rounded-[20px] flex flex-col gap-[24px] max-tablet:p-[20px] ">
                <div className="text-[20px] text-[#292B2C] font-[500] leading-[120%]">
                    Материалы к уроку
                </div>
                <div className="flex flex-col w-full gap-[24px]">
                    {files.map((file) => (
                        <div key={file.id} className="flex w-full">
                            {(file.extention === ".youtu" || file.extention === ".link") ? (
                                <Link to={file.pathName} target="_blank" className="flex gap-[10px] items-center w-full">
                                    <div
                                        className="w-[36px] h-[36px] rounded-[4px] bg-[#2F80ED] flex items-center justify-center">
                                        <LinkIcon/>
                                    </div>
                                    <div className="flex w-full flex-col gap-[2px]">
                                        <div
                                            className="w-full break-all">{file.originalName}</div>
                                    </div>
                                </Link>
                            ) : (
                                <div className="flex gap-[10px] items-center cursor-pointer w-full"
                                     onClick={() => handleDownloadFile(file.id)}>
                                    <div className="flex gap-[10px] items-center w-full">
                                        <div
                                            className="w-[36px] h-[36px] rounded-[4px] bg-[#2F80ED] flex items-center justify-center">
                                            <FileIcon/>
                                        </div>
                                        <div className="flex w-full flex-col gap-[2px]">
                                            <div className="w-full break-all">{file.originalName}{file.extention}</div>
                                        </div>
                                    </div>
                                    <DownloadIcon
                                        className={`flex-shrink-0 cursor-pointer h-[24px] w-[24px] ${listDownloadFile.indexOf(file.id) !== -1 ? "fill-[#17AB57]" : "fill-[#BDBDBD]"}`}/>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default PreviewLessonAdditionalFiles