import {ReactComponent as ArrowBackIcon} from "../../../../../assets/images/global/arrow-back-icon.svg";
import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {IUserDetail, IUserMethodist} from "../../../../../models/users";
import {useMutation} from "react-query";
import {UsersService} from "../../../../../services/users.service";
import Loader from "../../../../ui/loader/Loader";
import {generatePassword} from "../../../../../helpers/WorkWithUser";
import ScrollContainer from "../../../../ui/scroll-container/ScrollContainer";
import MethodistSelect from "../../../../ui/user/methodist-select/MethodistSelect";
import RoleSelect from "../../../../ui/user/role-select/RoleSelect";
import ResetSubmit from "../../../../ui/reset-submit/ResetSubmit";
import {AxiosError} from "axios";
import ErrorMessage from "../../../../ui/error/ErrorMessage";
import useToggle from "../../../../../hooks/useToggle";
import UndoChanges from "../../../../ui/modal/undo-changes/UndoChanges";
import {IRole} from "../../../../../models/role";
import {ShowBx24Id} from "../../../../../helpers/WorkWithDomain";

const UsersAdd = () => {

    const methods = useForm<IUserDetail>()
    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors},
        reset
    } = methods

    const navigate = useNavigate()
    const [show, setShow] = useState<boolean>(false)
    const [error, setError] = useState<number | null>(null)
    const [isModalUndoChanges, setOnModalOpenUndoChanges, setOnModalCloseUndoChanges] = useToggle()
    const [defaultRole, setDefaultRole] = useState<IRole>({} as IRole)
    const [defaultMethodist, setDefaultMethodist] = useState<IUserMethodist>({} as IUserMethodist)

    const createUser = useMutation(
        (data: IUserDetail) => UsersService.create(data),
        {
            onSuccess: (response) => {
                navigate(`/users/${response.id}`)
            },
            onError: (errorCreate) => {
                const e = errorCreate as AxiosError
                setError(e.response ? e.response.status : 400)
                setShow(false)
            }
        }
    )

    const onSubmit: SubmitHandler<IUserDetail> = async (data) => {
        setShow(true)
        createUser.mutate(data)
    }

    const generatePasswordHandler = () => {
        setValue("password", generatePassword(), {shouldValidate: true})
    }

    const handleReset = () => {
        reset({
            role: defaultRole,
            methodist: defaultMethodist,
            firstName: "",
            lastName: "",
            fatherName: "",
            email: "",
            password: "",
            dealId: null,
            bx24Id: null,
        })
    }

    return (
        <>
            {error && <ErrorMessage numberError={error}/>}
            {show && <Loader/>}
            {isModalUndoChanges && (<UndoChanges onClose={setOnModalCloseUndoChanges} link="/users"/>)}
            <ScrollContainer>
                <FormProvider {...methods}>
                    <form className="flex flex-col gap-[32px] h-full max-tablet:gap-[28px]"
                          onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col gap-[16px] text-[#292B2C]">
                            <div className="inline-flex">
                                <div
                                    onClick={setOnModalOpenUndoChanges}
                                    className="flex gap-[10px] items-center px-[16px] py-[12px] text-[18px] max-tablet:p-0 cursor-pointer">
                                    <ArrowBackIcon className="flex-shrink-0"/>
                                    <span className="max-tablet:hidden">Назад</span>
                                    <span className="tablet:hidden text-[24px] font-[700] leading-[120%]">Добавить пользователя</span>
                                </div>
                            </div>
                            <div className="flex justify-between items-start max-tablet:hidden">
                                <div className="font-[700] text-[36px] leading-[120%] max-laptop:text-[28px]">
                                    Добавить пользователя
                                </div>
                                <div
                                    className="flex gap-[12px] text-[16px]">
                                    <ResetSubmit actionReset={handleReset}/>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-[20px] max-tablet:gap-[16px]">
                            <div className="flex gap-[20px] max-tablet:gap-[16px] max-tablet:flex-col">
                                <div
                                    className="bg-white rounded-[20px] p-[36px] flex flex-col gap-[20px] max-tablet:p-[24px] w-[calc(100%-10px)] max-tablet:w-full">
                                    <div className="text-[#292B2C] text-[20px] font-[500]">Персональные данные</div>
                                    <div className="flex flex-col gap-[12px] w-full">
                                        <div className="flex flex-col gap-[4px]">
                                            <div className="text-[16px] text-[#828282]">Фамилия</div>
                                            <input
                                                className="p-[16px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[12px]"
                                                placeholder="Введите фамилию"
                                                {...register("lastName", {required: true})}/>
                                            {errors.lastName && errors.lastName.type === "required" && (
                                                <p className="text-red-600">ФИО обязательно</p>
                                            )}
                                        </div>
                                        <div className="flex flex-col gap-[4px]">
                                            <div className="text-[16px] text-[#828282]">Имя</div>
                                            <input
                                                className="p-[16px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[12px]"
                                                placeholder="Введите имя"
                                                {...register("firstName", {required: true})}/>
                                            {errors.firstName && errors.firstName.type === "required" && (
                                                <p className="text-red-600">Имя обязательно</p>
                                            )}
                                        </div>
                                        <div className="flex flex-col gap-[4px]">
                                            <div className="text-[16px] text-[#828282]">Отчество</div>
                                            <input
                                                className="p-[16px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[12px]"
                                                placeholder="Введите отчество"
                                                {...register("fatherName")}/>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="bg-white rounded-[20px] p-[36px] flex flex-col gap-[20px] max-tablet:p-[24px] w-[calc(100%-10px)] max-tablet:w-full">
                                    <div className="text-[#292B2C] text-[20px] font-[500]">Дополнительно</div>
                                    <div className="flex flex-col gap-[12px] w-full">
                                        <div className="flex flex-col gap-[4px]">
                                            <div className="text-[16px] text-[#828282]">Сделка</div>
                                            <input
                                                type="number"
                                                className="p-[16px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[12px] h-[54px]"
                                                placeholder="Введите номер сделки"
                                                {...register("dealId", {valueAsNumber: true})}/>
                                        </div>
                                        <MethodistSelect setMethodist={setDefaultMethodist}/>
                                        <RoleSelect setRole={setDefaultRole}/>
                                        {ShowBx24Id() && (
                                            <div className="flex flex-col gap-[4px]">
                                                <div className="text-[16px] text-[#828282]">Bx24 ID</div>
                                                <input
                                                    type="number"
                                                    className="p-[16px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[12px] h-[54px]"
                                                    placeholder="Введите Id пользователя в Bx24"
                                                    {...register("bx24Id", {valueAsNumber: true})}/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="bg-white rounded-[20px] p-[36px] flex flex-col gap-[20px] max-tablet:p-[24px]">
                                <div className="text-[#292B2C] text-[20px] font-[500]">Данные аккаунта</div>
                                <div className="flex gap-[12px] max-tablet:flex-col">
                                    <div className="flex flex-col gap-[4px] w-full">
                                        <div className="text-[16px] text-[#828282]">Почта</div>
                                        <input
                                            type="email"
                                            className="p-[16px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[12px]"
                                            placeholder="Введите почту"
                                            {...register("email", {required: true})}/>
                                        {errors.email && errors.email.type === "required" && (
                                            <p className="text-red-600">Почта обязательна</p>
                                        )}
                                    </div>
                                    <div className="flex flex-col gap-[4px] w-full">
                                        <div className="text-[16px] text-[#828282]">Пароль</div>
                                        <div className="flex gap-[12px] items-start max-tablet:flex-col">
                                            <div className="w-full">
                                                <input
                                                    type="text"
                                                    className="p-[16px] border-[1px] border-solid border-[#BDBDBD] w-full rounded-[12px]"
                                                    placeholder="Введите пароль"
                                                    {...register("password", {required: true})}/>
                                                {errors.password && errors.password.type === "required" && (
                                                    <p className="text-red-600">Пароль обязателен</p>
                                                )}
                                            </div>
                                            <div
                                                onClick={generatePasswordHandler}
                                                className="px-[20px] py-[16px] text-[#17AB57] border-solid border-[1px] border-[#17AB57] rounded-[12px] bg-white cursor-pointer max-tablet:w-full max-tablet:flex items-center justify-center font-[500]">
                                                Сгенерировать
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="max-tablet:flex flex-col items-start tablet:hidden gap-[12px] text-[16px]">
                                <ResetSubmit actionReset={reset}/>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </ScrollContainer>
        </>
    )
}

export default UsersAdd