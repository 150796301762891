import {useMutation, useQuery} from "react-query";
import {Link, useNavigate, useParams} from "react-router-dom";
import {LessonsService} from "../../../../../services/lessons.service";
import Loader from "../../../../ui/loader/Loader";
import React, {useRef, useState} from "react";
import {IFile} from "../../../../../models/file";
import {ReactComponent as ArrowBackIcon} from "../../../../../assets/images/global/arrow-back-icon.svg";
import {ReactComponent as ArrowIcon} from "../../../../../assets/images/global/arrow.svg";
import PreviewLessonDocument from "../../../../ui/preview-lesson-document/PreviewLessonDocument";
import PreviewLessonAdditionalFiles from "./lesson-additional-files/PreviewLessonAdditionalFiles";
import ScrollContainer from "../../../../ui/scroll-container/ScrollContainer";
import ErrorMessage from "../../../../ui/error/ErrorMessage";

const PreviewLesson = () => {

    const navigate = useNavigate()
    const {lessonId} = useParams()

    const [lastLesson, setLastLesson] = useState<boolean>(false)
    const [urlPrev, setUrlPrev] = useState<string>("");
    const [urlNext, setUrlNext] = useState<string>("");
    const [mainFile, setMainFile] = useState<IFile>({} as IFile)
    const [additionalFiles, setAdditionalFiles] = useState<IFile[]>([])
    const refDownloadFile = useRef<HTMLAnchorElement>(null)

    const {data: lesson, isLoading, isError} = useQuery(
        ["lesson_preview", lessonId],
        () => LessonsService.preview(Number(lessonId)),
        {
            enabled: !!lessonId,
            onSuccess: (response) => {
                const arrAdditionalFiles: IFile[] = []
                if (response.files) {
                    response.files.map(lesson => {
                        if (lesson.isMain) {
                            setMainFile(lesson)
                        } else {
                            arrAdditionalFiles.push(lesson)
                        }
                    })
                    setAdditionalFiles(arrAdditionalFiles)
                }
                const currentIndex = response.course.lessons.findIndex(c => c.id === Number(lessonId))
                const currentLesson = response.course.lessons[currentIndex]
                if (currentIndex !== 0) {
                    const prevLesson = response.course.lessons[currentIndex - 1]
                    setUrlPrev(`/folders/preview-lesson/${prevLesson.id}`)
                }
                if (currentIndex === response.course.lessons.length - 1) {
                    setLastLesson(true)
                    setUrlNext(`/folders/courses/${currentLesson.courseId}?list-lesson=true`)
                } else {
                    const nextLesson = response.course.lessons[currentIndex + 1]
                    setUrlNext(`/folders/preview-lesson/${nextLesson.id}`)
                }
            }
        }
    )


    const downloadFile = useMutation(
        (id: number) => LessonsService.downloadFile(id),
        {
            onSuccess: (response) => {
                if (refDownloadFile.current) {
                    refDownloadFile.current.href = response
                    refDownloadFile.current.click()
                }
            }
        }
    )

    const handleDownloadFile = () => {
        downloadFile.mutate(mainFile.id)
    }

    if (isLoading) return <Loader/>
    if (isError) return <ErrorMessage numberError={400}/>
    if (!lesson) return <ErrorMessage numberError={400}/>

    const handlePrevLesson = () => {
        if(urlPrev) navigate(urlPrev)
    }

    const handleNextLesson = () => {
        navigate(urlNext)
    }

    return (
        <ScrollContainer>
            <a ref={refDownloadFile} href="#" className="hidden"></a>
            <div className="flex flex-col gap-[32px] max-laptop:gap-[24px]">
                <div className="flex flex-col gap-[16px] text-[#292B2C]">
                    <div className="inline-flex">
                        <Link to={`/folders/courses/${lesson.course.id}`}
                              className="flex gap-[10px] items-center px-[16px] py-[12px] text-[18px] max-tablet:p-0 cursor-pointer">
                            <ArrowBackIcon className="flex-shrink-0"/>
                            <span className="max-tablet:hidden">Назад</span>
                            <span
                                className="tablet:hidden text-[24px] font-[700] leading-[120%]">{lesson.course.name}</span>
                        </Link>
                    </div>
                    <div
                        className="max-tablet:hidden font-[700] text-[36px] leading-[120%] max-laptop:text-[28px]">
                        {lesson.course.name}
                    </div>
                </div>
                <div className="flex flex-col gap-[20px]">
                    <div className="p-[36px] flex flex-col gap-[24px] items-center bg-white rounded-[20px] max-tablet:p-[24px]">
                        <div
                            className="text-[#292B2C] text-[24px] font-[500] leading-[120%] max-tablet:text-[18px]">Урок {lesson.order}. {lesson.name}</div>
                        <div className="w-full flex gap-[20px] max-tablet:gap-[8px]">
                            <div
                                onClick={handlePrevLesson}
                                className={`w-full py-[16px] px-[20px] max-tablet:py-[8px] max-tablet:px-[16px] font-[500] flex justify-center items-center rounded-[12px] border gap-[12px] ${urlPrev ? `text-[#17AB57] border-[#17AB57] cursor-pointer` : `text-[#828282] border-[#828282]`}`}>
                                <ArrowIcon
                                    className={`flex-shrink-0 max-tablet:h-[15px] max-tablet:w-[15px] ${urlPrev ? `fill-[#17AB57]` : `fill-[#828282]`}`}/>
                                <span className="max-tablet:hidden">К предыдущему уроку</span>
                            </div>
                            <div onClick={handleDownloadFile}
                                 className="w-full py-[16px] px-[20px] max-tablet:py-[12px] max-tablet:px-[16px] flex justify-center rounded-[12px] bg-[#17AB57] text-white cursor-pointer items-center whitespace-nowrap">
                                Скачать файл урока
                            </div>
                            <div
                                onClick={handleNextLesson}
                                className={`w-full py-[16px] px-[20px] max-tablet:py-[8px] max-tablet:px-[16px] font-[500] flex justify-center items-center rounded-[12px] border gap-[12px] ${urlNext ? `text-[#17AB57] border-[#17AB57] cursor-pointer` : `text-[#828282] border-[#828282]`}`}>
                                <span className="max-tablet:hidden">{lastLesson ? "Назад в список уроков" : "К следующему уроку"}</span>
                                <ArrowIcon
                                    className={`flex-shrink-0 rotate-180 max-tablet:h-[15px] max-tablet:w-[15px] ${urlNext ? `fill-[#17AB57]` : `fill-[#828282]`}`}/>
                            </div>
                        </div>
                    </div>
                    <PreviewLessonDocument file={mainFile}/>
                    {additionalFiles && additionalFiles.length > 0 && (
                        <PreviewLessonAdditionalFiles files={additionalFiles}/>
                    )}
                </div>
            </div>
        </ScrollContainer>
    )
}

export default PreviewLesson