import {ReactComponent as CoursesIcon} from "../../../../../assets/images/courses/courses-icon.svg";
import {Link} from "react-router-dom";
import React from "react";
import {IFolderSearchResult} from "../../../../../models/folders";

interface ISearchCoursesItem {
    course: IFolderSearchResult
    setShowSearchResult: (show: boolean) => void
    onClearInput: () => void
    setSearchTerm: (name: string) => void
}

const SearchCoursesItem = (
    {
        course,
        setShowSearchResult,
        onClearInput,
        setSearchTerm
    }: ISearchCoursesItem
) => {

    const handleClick = () => {
        setShowSearchResult(false)
        onClearInput()
        setSearchTerm("")
    }

    return (
        <Link to={`/folders/courses/${course.id}`}
              onClick={handleClick}
              className="px-[18px] py-[20px] hover:bg-[#17AB571a] flex text-[16px] text-[#292B2C] leading-[120%] font-medium">
            <div className="w-[50%] flex gap-[12px] items-center max-lg:w-full">
                <CoursesIcon fill={course.isMain ? "#2F80ED" : "#BDBDBD"} className="flex-shrink-0"/>
                {course.name}
            </div>
            <div className="w-[50%] max-lg:hidden">{course.path}</div>
        </Link>
    )
}

export default SearchCoursesItem