import Modal from "../Modal";
import Loader from "../../loader/Loader";
import ErrorMessage from "../../error/ErrorMessage";
import {ReactComponent as CloseIcon} from "../../../../assets/images/global/close.svg";
import Portal from "../portal/Portal";
import React, {useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {ICompany, ICompanyDetail} from "../../../../models/company";
import {useMutation} from "react-query";
import {CompanyService} from "../../../../services/company.service";
import {AxiosError} from "axios";

interface IModalAddCompany {
    onClose: () => void
    setModalCompany: (company: ICompany) => void
}

const ModalAddCompany = (
    {
        onClose,
        setModalCompany
    }: IModalAddCompany
) => {

    const [error, setError] = useState<number | null>(null)

    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm<ICompanyDetail>()

    const addCompany = useMutation(
        (data: ICompanyDetail) => CompanyService.create(data),
        {
            onSuccess: (response) => {
                setModalCompany(response)
                onClose()
            },
            onError: (error) => {
                const e = error as AxiosError
                setError(e.response ? e.response.status : 400)
            }
        }
    )

    const onSubmit: SubmitHandler<ICompanyDetail> = async(data) => {
        addCompany.mutate(data)
    }

    return (
        <Portal>
            <Modal>
                {addCompany.isLoading && <Loader/>}
                {error && <ErrorMessage numberError={error}/>}
                <form className="flex flex-col gap-[24px] text-[16px]" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex justify-between items-center font-bold text-[16px]">
                        Создание новой компании
                        <CloseIcon className="cursor-pointer" onClick={onClose}/>
                    </div>
                    <div>
                        <input
                            className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                            type="text"
                            placeholder="Введите название компании"
                            {...register("name", {required: true})}/>
                        {errors.name && errors.name.type === "required" && (
                            <p className="text-red-600">Название обязательно</p>
                        )}
                    </div>
                    <div>
                        <input
                            className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                            type="text"
                            placeholder="Введите домен"
                            {...register("domain", {required: true})}/>
                        {errors.domain && errors.domain.type === "required" && (
                            <p className="text-red-600">Домен обязателен</p>
                        )}
                    </div>
                    <div>
                        <input
                            className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                            type="number"
                            placeholder="Введите порт почты"
                            {...register("port", {required: true})}/>
                        {errors.port && errors.port.type === "required" && (
                            <p className="text-red-600">Порт обязателен</p>
                        )}
                    </div>
                    <div>
                        <input
                            className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                            type="text"
                            placeholder="Введите сервер почты"
                            {...register("server", {required: true})}/>
                        {errors.server && errors.server.type === "required" && (
                            <p className="text-red-600">Сервер обязателен</p>
                        )}
                    </div>
                    <div>
                        <input
                            className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                            type="text"
                            placeholder="Введите почту"
                            {...register("mail", {required: true})}/>
                        {errors.mail && errors.mail.type === "required" && (
                            <p className="text-red-600">Почта обязательна</p>
                        )}
                    </div>
                    <div>
                        <input
                            className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                            type="text"
                            placeholder="Введите пароль от почты"
                            {...register("password", {required: true})}/>
                        {errors.password && errors.password.type === "required" && (
                            <p className="text-red-600">Пароль обязателен</p>
                        )}
                    </div>
                    <div>
                        <input
                            className="border-[1px] border-solid border-[#BDBDBD] font-medium py-[8px] pl-[10px] pr-[12px] focus:outline-none focus:border-[#17AB57] w-full rounded-[5px]"
                            type="text"
                            placeholder="Введите название бакета"
                            {...register("bucketName", {required: true})}/>
                        {errors.bucketName && errors.bucketName.type === "required" && (
                            <p className="text-red-600">Название бакета обязательно</p>
                        )}
                    </div>
                    <div className="flex gap-[12px] justify-end">
                        <button
                            type="submit"
                            className="text-white font-medium py-[8px] px-[16px] rounded-[5px] bg-[#17AB57]"
                        >
                            Создать
                        </button>
                        <button
                            className="font-medium py-2 px-4 rounded-[5px] border-[1px] border-solid border-[#17AB57]"
                            onClick={onClose}>Отмена
                        </button>
                    </div>
                </form>
            </Modal>
        </Portal>
    )
}

export default ModalAddCompany