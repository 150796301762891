import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as SearchIcon} from "../../../assets/images/global/search-icon.svg";
import {ReactComponent as CloseIcon} from "../../../assets/images/global/close.svg";

interface ISearch {
    setSearchTerm: (search: string) => void
    isHeader?: boolean
}

const Search = (
    {
        setSearchTerm,
        isHeader = false,
    }: ISearch
) => {

    const refSearch = useRef<HTMLInputElement>(null)
    const [search, setSearch] = useState<string>("")
    const [fullWidthSearch, setFullWidthSearch] = useState<boolean>(false)
    const [showClearSearch, setShowClearSearch] = useState<boolean>(false)

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
        e.target.value.length > 0 ? setShowClearSearch(true) : setShowClearSearch(false)
    }

    const handleClearSearch = () => {
        if(refSearch.current) {
            refSearch.current.value = ""
            setSearch("")
            refSearch.current.focus()
        }
    }

    useEffect(() => {
        setSearchTerm(search)
    }, [search]);

    const handleFocus = () => {
        if(refSearch.current && isHeader) {
            setFullWidthSearch(true)
        }
    }

    const handleBlur = () => {
        if(refSearch.current) {
            if(refSearch.current.value.length > 0) {
                setShowClearSearch(true)
            } else {
                setShowClearSearch(false)
                if(isHeader) setFullWidthSearch(false)
            }
        }
    }

    return (
        <div className={`relative transition-all duration-500 ${!isHeader ? 'w-full' : `${fullWidthSearch ? 'w-full' : 'w-[400px]'}`} `}>
            <SearchIcon className="absolute top-[16px] left-[16px]"/>
            <input
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={search}
                ref={refSearch}
                onChange={handleSearch}
                type="text"
                placeholder="Поиск"
                className={`py-[16px] pl-[40px] pr-[16px] border-solid border-[1px] border-[#BDBDBD] rounded-[12px] focus:outline-none w-full`}/>
            {showClearSearch && <CloseIcon className="absolute top-[16px] right-[16px] cursor-pointer" onClick={handleClearSearch}/>}
        </div>
    )
}

export default Search