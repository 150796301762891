import {createContext, ReactNode, useState} from 'react'
import Cookies from "universal-cookie"
import {jwtDecode, JwtPayload} from "jwt-decode";

interface Props {
    children?: ReactNode
}

interface IAuthContext {
    authenticated: boolean
    setAuthenticated: (state: boolean) => void
    currentRoleId: string
    setRoleId: (state: string) => void
    currentUserId: string
    setUserId: (state: string) => void
}

interface TokenAuth {
    role_id: string
    user_id: string
}

const initialValue = {
    authenticated: !!localStorage.getItem("token"),
    setAuthenticated: () => {
    },
    currentRoleId: "",
    setRoleId: () => {
    },
    currentUserId: "",
    setUserId: () => {
    },
}

const AuthContext = createContext<IAuthContext>(initialValue)


const AuthProvider = ({children}: Props) => {

    const cookies = new Cookies()
    let issetToken: boolean = false

    if (cookies.get("token") && cookies.get("refreshToken")) {
        issetToken = true
    }

    const [authenticated, setAuthenticated] = useState(issetToken)

    let initialRoleId: string = ""
    let initialUserId: string = ""

    const token = cookies.get("token")
    if(token) {
        const decoded = jwtDecode<TokenAuth>(token)
        initialRoleId = decoded.role_id
        initialUserId = decoded.user_id
    }

    const [currentRoleId, setRoleId] = useState(initialRoleId)
    const [currentUserId, setUserId] = useState(initialUserId)

    return (
        <AuthContext.Provider value={{authenticated, setAuthenticated, currentRoleId, setRoleId, currentUserId, setUserId}}>
            {children}
        </AuthContext.Provider>
    )
}

export {AuthContext, AuthProvider}