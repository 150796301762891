import {useState} from "react";
import {ITab} from "../../../models/tabs";

interface ITabs {
    setActiveTab: (tab: number) => void;
    list: ITab[]
}

const Tabs = (
    {
        list,
        setActiveTab
    }: ITabs
) => {

    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const handleClickTab = (tab: number) => {
        setActiveTabIndex(tab)
        setActiveTab(tab)
    }

    return (
        <div className="flex items-center max-laptop:overflow-x-auto flex-shrink-0">
            {list.map((tab) => (
                <div
                    key={tab.id}
                    onClick={() => handleClickTab(tab.id)}
                    className={`px-[16px] py-[12px] text-[18px] leading-[120%] cursor-pointer ${activeTabIndex === tab.id ? "rounded-[8px] text-[#17AB57] bg-[#17AB571a]" : "text-[#292B2C]"} whitespace-nowrap`}>
                    {tab.name} {tab.count && (`(${tab.count})`)}
                </div>
            ))}
        </div>
    )
}

export default Tabs